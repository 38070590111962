<script setup>
import { computed } from 'vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Panel from '@/components/Elements/Panel.vue'
import AmountInput from '@/components/Inputs/AmountInput.vue'

import { lessQm as lessQmInfo } from '@/data/infos/room'
import Square from '@/components/Icons/RoomSizes/Square.vue'
import Less from '@/components/Icons/RoomSizes/Less.vue'
import Right from '@/components/Icons/RoomSizes/Right.vue'
import Corner from '@/components/Icons/RoomSizes/Corner.vue'
import RoomSizeConfiguration from './RoomSizeConfiguration.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentRoom = computed({
  get: () => {
    const s = props.modelValue.step.split('.')[1]

    return props.modelValue.rooms[s]
  },
  set: (v) => {
    const s = props.modelValue.step.split('.')[1]

    model.value.rooms[s] = v
  }
})

const currentQmWallCeiling = computed(() => {
  return (
    (
      currentRoom.value.width * currentRoom.value['length'] +
      currentRoom.value.width * currentRoom.value.height * 2 +
      currentRoom.value['length'] * currentRoom.value.height * 2
    )
      .toFixed(2)
      .replace('.', ',') + ' m²'
  )
})

const currentQmFloor = computed(() => {
  return (
    (currentRoom.value.width * currentRoom.value['length']).toFixed(2).replace('.', ',') + ' m²'
  )
})
</script>

<template>
  <div v-if="currentRoom">
    <Panel :title="currentRoom.name || 'Zimmer'">
      <FormGroup label="Raumform">
        <div class="flex gap-5 text-white">
          <Square
            class="w-full hover:cursor-pointer hover:text-action"
            :class="{ 'text-action': currentRoom.shape === 'square' }"
            @click="currentRoom.shape = 'square'"
          />
          <Less
            class="w-full hover:cursor-pointer hover:text-action"
            :class="{ 'text-action': currentRoom.shape === 'less' }"
            @click="currentRoom.shape = 'less'"
          />
          <Right
            class="w-full hover:cursor-pointer hover:text-action"
            :class="{ 'text-action': currentRoom.shape === 'right' }"
            @click="currentRoom.shape = 'right'"
          />
          <Corner
            class="w-full hover:cursor-pointer hover:text-action"
            :class="{ 'text-action': currentRoom.shape === 'corner' }"
            @click="currentRoom.shape = 'corner'"
          />
        </div>
      </FormGroup>

      <RoomSizeConfiguration v-model="currentRoom" />

      <FormGroup
        label="Wie viel Quadratmeter sollen als Fenster- und Türflächen abgezogen werden?"
        class="mt-5"
        :info="lessQmInfo"
      >
        <template #right>
          <div class="flex flex-row items-center">
            <AmountInput v-model="currentRoom.less_qm" class="w-16 px-2" />
            <span class="ml-2 whitespace-nowrap dark:text-white">in m<sup>2</sup></span>
          </div>
        </template>
      </FormGroup>
    </Panel>
  </div>
</template>
