<script setup>
import { computed, onMounted } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Quality from '@/components/Inputs/Quality.vue'
import Headline from '../Elements/Headline.vue'

import RoomsTabs from '../Inputs/RoomsTabs.vue'
import {
  configTemplate,
  floorTypeOptions,
  sinkOptions,
  tubOptions,
  handleOptions,
  bathtubOptions,
  toiletOptions,
  bathHandleOptions,
  sinkTubOptions,
  sinkHandleOptions
} from '@/data/options/baths'
import ImageOptionsSelection from '../Inputs/ImageOptionsSelection.vue'
import AmountInput from '../Inputs/AmountInput.vue'
import Radio from '../Inputs/Radio.vue'
import createRoom from '@/utils/room'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const json = (v) => JSON.parse(JSON.stringify(v))

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentRoom = computed({
  get: () => {
    if (!model.value.change.currentRoom) {
      return model.value.change.config
    }
    return model.value.change.roomConfigs[model.value.change.currentRoom]
  },
  set: (v) => {
    if (!model.value.change.currentRoom) {
    } else {
      model.value.change.roomConfigs[model.value.change.currentRoom] = v
    }
  }
})

const changeRooms = computed(() => {
  return props.form.rooms
    .filter((i) => i.enabled)
    .filter((room) => room.type === 'bathroom' || room.type === 'guest_wc')
    .map((room) => {
      const autoValue = model.value.change.config.quality ? 'autoset' : 'unset'

      return {
        ...room,
        indicator: model.value.change.roomConfigs[room.id]?.quality ? 'set' : autoValue
      }
    })
})

const onCreateAction = () => {
  const rooms = Object.values(props.form.rooms)
  const bath = rooms.find((room) => room.type === 'bathroom' || room.type === 'guest_wc')
  const bathIndex = rooms.indexOf(bath)

  const totalBaths = rooms.filter(
    (room) => room.type === 'bathroom' || room.type === 'guest_wc'
  ).length

  if (bath && !bath.enabled) {
    bath.enabled = true
    props.form.rooms[bathIndex] = bath
    props.form.step = 'rooms.' + bathIndex
  } else {
    const newRoom = createRoom('Badezimmer ' + (totalBaths + 1), 'bathroom', false, true)

    props.form.rooms.push(newRoom)
    props.form.step = 'rooms.' + rooms.length
  }
}

onMounted(() => {
  props.form.rooms
    .filter((i) => i.enabled)
    .filter((room) => room.type === 'bathroom' || room.type === 'guest_wc')
    .forEach((room) => {
      if (!model.value.change.roomConfigs[room.id]) {
        model.value.change.roomConfigs[room.id] = json(configTemplate)
      }
    })
})
</script>

<template>
  <div>
    <Headline title="Räume"></Headline>
    <RoomsTabs
      class="mt-4"
      v-model="model.change.currentRoom"
      :rooms="changeRooms"
      :onCreateAction="onCreateAction"
    />

    <template v-if="model.change.currentRoom">
      <fieldset :name="'roomConfig.' + model.change.currentRoom" class="mt-10">
        <FormGroup info-title="Böden" label="Soll neuer Boden verlegt werden?" name="floor">
          <template #right>
            <Switch v-model="currentRoom.floor.enabled" />
          </template>

          <ImageOptionsSelection
            cols="md:grid-cols-3"
            v-if="currentRoom.floor.enabled"
            v-model="currentRoom.floor.quality"
            :options="floorTypeOptions"
          />
        </FormGroup>

        <FormGroup info-title="Wände" label="Sollen Teile der Wand gefliest werden?" name="walls">
          <template #right>
            <Switch v-model="currentRoom.walls.enabled" />
          </template>

          <template v-if="currentRoom.walls.enabled">
            <ImageOptionsSelection
              cols="md:grid-cols-3"
              v-model="currentRoom.walls.quality"
              :options="floorTypeOptions"
            />

            <FormGroup label="Wie viel Quadratmeter Wand sollen gefliest werden?" class="mt-5">
              <template #right>
                <div class="flex flex-nowrap items-center">
                  <AmountInput v-model="currentRoom.walls.size" class="w-16 px-2" />
                  <span class="ml-2 whitespace-nowrap dark:text-white">in m<sup>2</sup></span>
                </div>
              </template>
            </FormGroup>
          </template>
        </FormGroup>
        <Headline title="Ausstattungen"></Headline>

        <FormGroup label="Dusche" label-class="font-bold" name="shower" class="!mb-0">
          <template #right>
            <Switch v-model="currentRoom.shower.enabled" />
          </template>

          <template v-if="currentRoom.shower.enabled">
            <FormGroup label="Wanne" name="tub" class="!mb-0">
              <ImageOptionsSelection
                cols="md:grid-cols-3"
                v-model="currentRoom.shower.tub.quality"
                :options="tubOptions"
                can-clear
              />
            </FormGroup>

            <FormGroup label="Duscharmatur" name="handle" class="!mb-0 mt-10">
              <ImageOptionsSelection
                cols="md:grid-cols-3"
                v-model="currentRoom.shower.handle.quality"
                :options="handleOptions"
                can-clear
              />
            </FormGroup>

            <div class="mb-7">
              <FormGroup
                label="mit fester Duschtrennwand ohne Tür"
                name="partitionNoDoor"
                :disabled="
                  currentRoom.shower.modification.partitionWithDoor ||
                  currentRoom.shower.modification.onlyDoor
                "
                class="!mb-0 mt-4"
              >
                <template #right>
                  <Switch v-model="currentRoom.shower.modification.partitionNoDoor" />
                </template>
              </FormGroup>
              <FormGroup
                class="!mb-0"
                label="mit fester Duschtrennwand mit Tür"
                :disabled="
                  currentRoom.shower.modification.partitionNoDoor ||
                  currentRoom.shower.modification.onlyDoor
                "
                name="partitionWithDoor"
              >
                <template #right>
                  <Switch v-model="currentRoom.shower.modification.partitionWithDoor" />
                </template>
              </FormGroup>

              <FormGroup
                :disabled="
                  currentRoom.shower.modification.partitionNoDoor ||
                  currentRoom.shower.modification.partitionWithDoor
                "
                class="!mb-0"
                label="nur Tür"
                name="onlyDoor"
              >
                <template #right>
                  <Switch v-model="currentRoom.shower.modification.onlyDoor" />
                </template>
              </FormGroup>

              <FormGroup class="!mb-0" label="Duschfach mit Beleuchtung" name="compartment">
                <template #right>
                  <Switch v-model="currentRoom.shower.modification.compartment" />
                </template>
              </FormGroup>
            </div>
          </template>
        </FormGroup>

        <FormGroup label="Badewanne" label-class="font-bold" name="bathtub" class="!mb-0">
          <template #right>
            <Switch v-model="currentRoom.bathtub.enabled" />
          </template>

          <template v-if="currentRoom.bathtub.enabled">
            <FormGroup label="Wanne" name="tub" class="!mb-0">
              <ImageOptionsSelection
                cols="md:grid-cols-3"
                v-model="currentRoom.bathtub.tub.quality"
                :options="bathtubOptions"
                can-clear
              />
            </FormGroup>

            <FormGroup label="Badewannenarmatur" name="handle" class="mt-10">
              <ImageOptionsSelection
                cols="md:grid-cols-3"
                v-model="currentRoom.bathtub.handle.quality"
                :options="bathHandleOptions"
                can-clear
              />
            </FormGroup>
          </template>

          <div v-if="currentRoom.bathtub.enabled">
            <FormGroup label="mit Glastrennwand" name="glasPartition" class="mt-4">
              <template #right>
                <Switch v-model="currentRoom.bathtub.glasPartition" />
              </template>
            </FormGroup>
          </div>
        </FormGroup>

        <FormGroup label="Toilette" label-class="font-bold" name="toilet" class="!mb-0">
          <template #right>
            <Switch v-model="currentRoom.toilet.enabled" />
          </template>

          <ImageOptionsSelection
            v-if="currentRoom.toilet.enabled"
            cols="md:grid-cols-3"
            class="mb-10"
            v-model="currentRoom.toilet.quality"
            :options="toiletOptions"
          />
        </FormGroup>

        <FormGroup label="Waschbecken" label-class="font-bold" name="sink" class="!mb-0">
          <template #right>
            <Switch v-model="currentRoom.sink.enabled" />
          </template>

          <div v-if="currentRoom.sink.enabled">
            <Radio :options="sinkOptions" class="mb-5" v-model="currentRoom.sink.type" />

            <FormGroup label="Becken" name="tub" class="!mb-0">
              <ImageOptionsSelection
                cols="md:grid-cols-3"
                v-model="currentRoom.sink.tub.quality"
                :options="sinkTubOptions[currentRoom.sink.type]"
                can-clear
              />
            </FormGroup>

            <FormGroup label="Armatur" name="handle" class="mt-10">
              <ImageOptionsSelection
                cols="md:grid-cols-3"
                v-model="currentRoom.sink.handle.quality"
                :options="sinkHandleOptions[currentRoom.sink.type]"
                can-clear
              />
            </FormGroup>

            <FormGroup label="mit Unterschrank" name="baseCabinet" class="!mb-0 mt-4">
              <template #right>
                <Switch v-model="currentRoom.sink.baseCabinet" />
              </template>
            </FormGroup>
          </div>
        </FormGroup>
      </fieldset>
    </template>
  </div>
</template>
