<script setup>
import { computed, defineEmits, defineProps } from 'vue'
import RoomsCheckbox from './RoomsCheckbox.vue'
import useEmitter from '@/composables/useEmitter.js'

const emitter = useEmitter()
const $emit = defineEmits(['update:modelValue'])

const props = defineProps({
  rooms: Array,
  modelValue: Array,
  all: {
    type: Boolean,
    default: true
  }
})

const currentRooms = computed(() => {
  const options = props.rooms
    .filter((i) => i.enabled)
    .map((room) => {
      return {
        label: room.name,
        value: room.id,
        width: room.width,
        length: room['length']
      }
    })

  if (props.all) {
    options.unshift({
      label: 'Alle Zimmer',
      value: 'all',
      width: 1,
      length: 1
    })
  }

  return options
})

const setModel = (value) => {
  const lastValue = props.modelValue
  const currentValue = value

  if (props.all) {
    if (lastValue.includes('all') && !currentValue.includes('all')) {
      value = []
    } else if (
      currentValue.filter((i) => i !== 'all').length ===
      currentRooms.value.filter((i) => i.value !== 'all').length
    ) {
      value.push('all')
    } else if (!lastValue.includes('all') && currentValue.includes('all')) {
      value = currentRooms.value.map((i) => i.value)
    } else if (currentValue.includes('all') && currentValue.length !== currentRooms.value.length) {
      value = value.filter((i) => i !== 'all')
    }
  }

  $emit('update:modelValue', value)
}

const addNewRoom = () => {
  emitter.emit('calc:add-room')
}
</script>
<template>
  <RoomsCheckbox
    name="rooms"
    :model-value="modelValue"
    @update:model-value="setModel"
    :options="currentRooms"
  >
    <template #after>
      <button type="button" class="flex flex-row items-center dark:text-white" @click="addNewRoom">
        <span
          class="mr-3 flex h-4 w-4 items-center justify-center rounded-full bg-action p-0 not-italic text-black"
          >+</span
        >
        <span>Zimmer hinzufügen</span>
      </button>
    </template>
  </RoomsCheckbox>
</template>
