<template>
  <nav class="fixed left-1/2 top-[64px] z-[20] -translate-x-1/2">
    <ul
      class="flex flex-row rounded border border-action bg-primary-550/80 px-2 text-white backdrop-blur-md"
    >
      <li v-for="i in menu">
        <a
          class="flex flex-col px-3 py-1.5"
          @click.prevent="i.onclick"
          :class="{
            active: model.step.startsWith(i.step)
          }"
        >
          <component
            :is="i.icon"
            class="mb-1 h-7 transition-all"
            :class="{
              'scale-[110%] fill-action': model.step.startsWith(i.step),
              'fill-gray-400': !model.step.startsWith(i.step),
              '!fill-action': i.activeWhen.some((e) => model.step.startsWith(e))
            }"
          />
          <span
            :class="{
              'font-semibold text-action': model.step.startsWith(i.step),
              'text-gray-400': !model.step.startsWith(i.step),
              '!text-action': i.activeWhen.some((e) => model.step.startsWith(e))
            }"
            >{{ i.title }}</span
          >
        </a>
      </li>
    </ul>
  </nav>
</template>
<script setup>
import { computed, ref } from 'vue'
import ObjectIcon from '@/components/Icons/Object.vue'
import RoomsIcon from '@/components/Icons/Rooms.vue'
import ServicesIcon from '@/components/Icons/Services.vue'
import ContactIcon from '@/components/Icons/Contact.vue'

import { scrollToSelector } from '@/utils/scroll'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const menu = [
  {
    step: 'base',
    title: 'Objekt',
    activeWhen: ['rooms', 'services', 'contact'],
    icon: ObjectIcon,
    onclick: () => {
      model.value.step = 'base'
      scrollToSelector('#grid')
    }
  },
  {
    step: 'rooms',
    title: 'Räume',
    activeWhen: ['services', 'contact'],
    icon: RoomsIcon,
    onclick: () => {
      model.value.step = 'rooms'
      scrollToSelector('#grid')
    }
  },
  {
    step: 'services',
    title: 'Handwerk',
    activeWhen: ['contact'],
    icon: ServicesIcon,
    onclick: () => {
      model.value.step = 'services'
      scrollToSelector('#grid')
    }
  },
  {
    step: 'contact',
    title: 'Ergebnis',
    activeWhen: [],
    icon: ContactIcon,
    onclick: () => {
      model.value.step = 'contact'
      scrollToSelector('#grid')
    }
  }
]
</script>
<style scoped lang="pcss">
nav {
  li {
    position: relative;
  }

  li::after {
    position: absolute;
    content: '';
    display: block;
    right: -13px;
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    width: 25px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25px' height='25px' viewBox='0 0 24 24'%3E%3Cpath fill='%23fff' d='m13.292 12l-4.6-4.6l.708-.708L14.708 12L9.4 17.308l-.708-.708z'/%3E%3C/svg%3E");
  }

  li:last-child::after {
    display: none;
  }

  li.active + li.active {
    a svg {
      fill: #fff;
    }
  }
}
</style>
