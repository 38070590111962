<template>
  <RadioGroup :name="name" v-model="model" class="flex flex-wrap justify-items-stretch gap-4">
    <RadioGroupOption
      :value="fullOption ? o : o.value"
      v-for="o in options"
      as="template"
      v-slot="{ checked }"
    >
      <button
        type="button"
        class="radio-item"
        :class="{
          'border-primary bg-primary font-bold text-white dark:border-white dark:bg-white dark:text-black':
            checked
        }"
      >
        <span>{{ o.label }}</span>
      </button>
    </RadioGroupOption>
  </RadioGroup>
</template>

<script setup>
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import { computed } from 'vue'

const props = defineProps({
  modelValue: {},
  name: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  },
  fullOption: {
    type: Boolean,
    default: false
  }
})

const $emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    $emit('update:modelValue', value)
  }
})
</script>
