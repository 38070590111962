<template>
  <Switch
    :name="name"
    v-model="enabled"
    :class="enabled ? 'border-action bg-action' : 'border-action bg-gray-200'"
    class="relative top-0.5 inline-flex h-4 w-8 shrink-0 items-center rounded-full border"
  >
    <span class="sr-only">{{ label }}</span>
    <span
      :class="enabled ? 'translate-x-[14px]' : 'translate-x-[0px]'"
      class="inline-block h-[15px] w-4 transform rounded-full bg-primary transition"
    />
  </Switch>
</template>

<script setup>
import { computed, defineEmits } from 'vue'
import { Switch } from '@headlessui/vue'

const props = defineProps({
  name: {
    type: String,
    default: 'enabled'
  },
  label: String,
  modelValue: Boolean
})

const emit = defineEmits(['update:modelValue'])

const enabled = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})
</script>
