<template>
  <Panel title="Grunddaten">
    <dl class="dark:text-white">
      <dt>Objekttyp</dt>
      <dd>{{ baseType }}</dd>

      <dt>Postleitzahl</dt>
      <dd>{{ modelValue.base.postal_code || '-' }}</dd>

      <template v-if="modelValue.base.type === 'apartment'">
        <dt>Wohnungsgröße</dt>
        <dd>{{ modelValue.base.size }}m²</dd>

        <dt>Etage</dt>
        <dd>{{ baseFloor }}</dd>

        <dt>Baujahr</dt>
        <dd>{{ baseBuilt || '-' }}</dd>
      </template>
      <template v-else>
        <dt>Wohnfläche</dt>
        <dd>{{ modelValue.base.usage ? modelValue.base.usage + 'm²' : '-' }}</dd>

        <dt>Nutzfläche</dt>
        <dd>{{ modelValue.base.cellar ? modelValue.base.cellar + 'm²' : '-' }}</dd>

        <dt>Stockwerke</dt>
        <dd>{{ baseFloors || '-' }}</dd>

        <dt>Hausart</dt>
        <dd>{{ baseHouseType || '-' }}</dd>

        <dt>Bauart</dt>
        <dd>{{ baseBuiltType || '-' }}</dd>

        <dt>Ursprungsbaujahr</dt>
        <dd>{{ modelValue.base.built || '-' }}</dd>
      </template>
    </dl>
  </Panel>
</template>
<script setup>
import { computed, defineProps } from 'vue'
import Panel from '@/components/Elements/Panel.vue'

import {
  baseTypeOptions,
  baseFloorOptions,
  baseBuiltOptions,
  baseFloorsOptions,
  baseHouseTypeOptions,
  baseBuiltTypeOptions
} from '@/data/options/base'

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  }
})

const baseType = computed(() => {
  return baseTypeOptions.find((option) => option.value === props.modelValue.base.type).label
})

const baseFloor = computed(() => {
  return baseFloorOptions.find((option) => option.value === props.modelValue.base.floor).label
})

const baseBuilt = computed(() => {
  return baseBuiltOptions.find((option) => option.value === props.modelValue.base.built)?.label
})

const baseFloors = computed(() => {
  return props.modelValue.base.floors
    .map((floor) => {
      return baseFloorsOptions.find((option) => option.value === floor).label
    })
    .join(', ')
})

const baseHouseType = computed(() => {
  return baseHouseTypeOptions.find((option) => option.value === props.modelValue.base.house_type)
    ?.label
})

const baseBuiltType = computed(() => {
  return baseBuiltTypeOptions.find((option) => option.value === props.modelValue.base.built_type)
    ?.label
})
</script>
