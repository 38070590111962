<template>
  <div class="dark:prose-dark prose dark:text-white">
    <p>
      Sie haben sich für eine Sanierung mit einem Generalunternehmen entschieden. Hier die
      wichtigsten Informationen:
    </p>
    <p>
      Ein Generalunternehmer koordiniert sämtliche anfallenden Arbeiten, wie beispielsweise
      Elektrik, Sanitär, Malerarbeiten oder den Innenausbau. Dadurch erspart der Bauherr sich den
      organisatorischen Aufwand, verschiedene Handwerker einzeln zu beauftragen und zu koordinieren.
      Zudem kann ein Generalunternehmen oft günstigere Konditionen für Materialien und
      Dienstleistungen aushandeln, da es über ein größeres Netzwerk verfügt.
    </p>
    <p>
      Allerdings ist zu beachten, dass die Sanierung mit einem Generalunternehmen in der Regel etwa
      15% teurer sein kann als die Koordination der Gewerke in Eigenregie. Diese zusätzlichen Kosten
      können durch die verminderte Belastung des Bauherrn und die höhere Effizienz der Baumaßnahmen
      jedoch gerechtfertigt sein. Zudem bietet ein Generalunternehmer auch eine Gewährleistung für
      die gesamte Sanierung, was einem zusätzlichen Sicherheitsfaktor für den Bauherrn darstellt.
    </p>
    <p>
      Insgesamt kann die Entscheidung für die Sanierung mit einem Generalunternehmen dazu beitragen,
      dass der Bauablauf reibungsloser verläuft und die Qualität der Arbeit gewährleistet ist. Der
      zusätzliche finanzielle Aufwand sollte deshalb als Investition in eine professionelle und
      stressfreie Sanierung betrachtet werden.
    </p>
  </div>
</template>
