import { getImageUrl } from '@/utils/image'

export const floorTypeOptions = [
  {
    label: 'Parket',
    image: getImageUrl('/images/calc/floors/parkett.jpg'),
    value: 'parkett',
    description:
      'Parkett ist ein hochwertiger Bodenbelag, der in vielen verschiedenen Holzarten erhältlich ist.'
  },
  {
    label: 'Diele',
    image: getImageUrl('/images/calc/floors/diele.jpg'),
    value: 'diele',
    description:
      'Diele ist ein natürlicher Bodenbelag, der in vielen verschiedenen Holzarten erhältlich ist.'
  },
  {
    label: 'Vinyl',
    image: getImageUrl('/images/calc/floors/vinyl.jpg'),
    value: 'vinyl',
    description:
      'Vinyl ist ein pflegeleichter und strapazierfähiger Bodenbelag, der in vielen verschiedenen Farben und Mustern erhältlich ist.'
  },
  {
    label: 'Laminat',
    image: getImageUrl('/images/calc/floors/laminat.jpg'),
    value: 'laminat',
    description:
      'Laminat ist ein vielseitiger Bodenbelag, der in vielen verschiedenen Farben und Mustern erhältlich ist. Er ist leicht zu reinigen und zu pflegen.'
  },
  {
    label: 'Teppich',
    image: getImageUrl('/images/calc/floors/teppich.svg'),
    value: 'teppich',
    description:
      'Teppich ist ein weicher und warmer Bodenbelag, der in vielen verschiedenen Farben und Mustern erhältlich ist.'
  },
  {
    label: 'Fliesen',
    image: getImageUrl('/images/calc/floors/fliessen.svg'),
    value: 'fliesen',
    description:
      'Fliesen sind ein langlebiger und pflegeleichter Bodenbelag, der in vielen verschiedenen Farben und Mustern erhältlich ist.'
  }
]
