<template>
  <div class="flex items-center justify-center">
    <div class="max-w-3xl text-center">
      <div class="text-3xl lg:text-5xl">
        <template v-for="service in totals.services" :key="service.name">
          <template v-if="service.name === getCurrentStepTotalsKey()">
            <div class="flex flex-col justify-center gap-6 sm:gap-9">
              <p class="text-2xl font-semibold sm:text-3xl">
                Großartig!<br />
                Ihre handwerkliche Dienstleistung beläuft sich auf:
              </p>
              <span class="text-2xl font-bold underline sm:text-3xl">
                {{ getServiceLabelByTotalKey(service.name) }}
              </span>

              <AnimatedNumber
                class="text-4xl font-semibold sm:text-4xl"
                :model-value="service.total.value"
              />
              <div class="w-full">
                <FinalStepButton
                  v-model="props.formData"
                  :enabledServices="enabledServices"
                  :isLv="isLv"
                />
              </div>
              <button class="text-xl underline" @click="resetService">Berechnung anpassen</button>
            </div>
          </template>
        </template>
        <template v-if="!isCurrentStepInTotals">
          <div class="flex flex-col justify-center gap-8">
            <h2 class="text-2xl text-primary">
              Es scheint, dass Sie keine Optionen ausgewählt haben, sodass wir die Berechnung
              Berechnung nicht durchführen können. Bitte überprüfen Sie Ihre Eingaben oder entfernen
              Sie diese Dienstleistung aus der Liste.
            </h2>
            <div class="w-full">
              <button class="btn btn--light px-10 py-3 text-xl" @click="resetService">
                Zurück
              </button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import FinalStepButton from '@/components/Elements/FinalStepButton.vue'
import AnimatedNumber from '@/components/Inputs/AnimatedNumber.vue'

const props = defineProps({
  formData: {
    type: Object,
    required: true
  },
  totals: {
    type: Object,
    required: true
  },
  getCurrentStepTotalsKey: {
    type: Function,
    required: true
  },
  isCurrentStepInTotals: {
    type: Boolean,
    required: true
  },
  enabledServices: {
    type: Array,
    required: true
  },
  availableSteps: {
    type: Array,
    required: true
  },
  isLv: Boolean
})

const emit = defineEmits(['resetService'])

const getServiceTotal = computed(() => {
  const key = props.getCurrentStepTotalsKey()
  const service = props.totals.services.find((s) => s.name === key)
  return service ? service.total : 0
})

const getServiceLabelByTotalKey = (totals_key) => {
  if (totals_key) {
    const service = props.availableSteps.find((service) => service.totals_key === totals_key)
    return service ? service.label : ''
  }
  console.error('No totals_key provided')
}

const resetService = () => {
  emit('resetService')
}
</script>

<style scoped>
.final-step {
  @apply rounded-lg bg-white p-6 shadow-md;
}
</style>
<script setup lang="ts"></script>
