import { ElectricalSchema } from '@/data/validations/electrical'
import { HeatingSchema } from '@/data/validations/heating'
import { WallsCeilingsSchema } from '@/data/validations/wallsCeilings'
import { FloorsSchema } from '@/data/validations/floors'
import { PipesSchema } from '@/data/validations/pipes'
import StairsSchema from '@/data/validations/stairs.js'
import BlueprintSchema from '@/data/validations/blueprint.js'
import FacadeSchema from '@/data/validations/facade.js'
import WindowsSchema from '@/data/validations/windows.js'
import DoorsSchema from '@/data/validations/doors.js'
import RoofSchema from '@/data/validations/roof.js'
import BathsSchema from '@/data/validations/baths.js'

export default {
  electrical: ElectricalSchema,
  heating: HeatingSchema,
  wallsCeilings: WallsCeilingsSchema,
  floors: FloorsSchema,
  pipes: PipesSchema,
  stairs: StairsSchema,
  blueprint: BlueprintSchema,
  facade: FacadeSchema,
  windows: WindowsSchema,
  doors: DoorsSchema,
  baths: BathsSchema,
  roof: RoofSchema
}
