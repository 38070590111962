export const renew = `<p>Steigleitungen sind vertikal verlaufende Rohrleitungen in einem Geb&auml;ude, die daf&uuml;r sorgen, dass Wasser, Gas, Heizungsw&auml;rme oder Strom von der Versorgungsebene in die einzelnen Stockwerke und Wohnbereiche transportiert werden. In einem Einfamilienhaus mit mehreren Stockwerken sind sie essenziell f&uuml;r die zentrale Versorgung aller Ebenen mit den notwendigen Medien.</p>
<p>Steigleitungen verlaufen meist in Sch&auml;chten oder Installationsschlitzen, die eine nachtr&auml;gliche Wartung oder Erneuerung erm&ouml;glichen. Diese versteckte Installation bewahrt die Wohn&auml;sthetik und sorgt gleichzeitig daf&uuml;r, dass die Leitungen vor Besch&auml;digungen gesch&uuml;tzt sind.</p>
<p>Die Erneuerung von Steigleitungen in einem solchen Haus erfordert eine sorgf&auml;ltige Planung und Ausf&uuml;hrung aus mehreren Gr&uuml;nden:</p>
<ol>
<li>Komplexit&auml;t: Steigleitungen sind zentrale Elemente der Hausinfrastruktur. Sie beeinflussen andere Systeme und Komponenten, wie die Heizungsanlage, Sanit&auml;rausstattung und Elektrik. Ihre Erneuerung kann umfangreiche Arbeiten nach sich ziehen.</li>
<li>Zug&auml;nglichkeit: Um die Steigleitungen zu erreichen, m&uuml;ssen eventuell Verkleidungen entfernt und Sch&auml;chte ge&ouml;ffnet werden. In bewohnten H&auml;usern muss darauf geachtet werden, den Alltagsbetrieb der Einwohner so wenig wie m&ouml;glich zu st&ouml;ren.</li>
<li>Normen und Vorschriften: Bei der Erneuerung m&uuml;ssen aktuelle technische Normen, baurechtliche Vorgaben und Sicherheitsstandards eingehalten werden. Dazu kann auch eine Abstimmung mit lokalen Versorgungsunternehmen und Beh&ouml;rden geh&ouml;ren.</li>
<li>Material und Technik: Heutzutage werden meist Korrosionsbest&auml;ndige Materialien wie Kupfer oder Kunststoff eingesetzt. Die Wahl des Materials und der Verbindungstechnik muss auf die jeweilige Nutzung und die Wasserqualit&auml;t abgestimmt sein.</li>
</ol>
<p>Was muss bei einer Erneuerung gemacht werden?</p>
<ul>
<li>Bestandsanalyse: Vor Beginn der Arbeiten muss der Zustand der bestehenden Steigleitungen erfasst werden. Dazu geh&ouml;rt eine Pr&uuml;fung auf Korrosion, Ablagerungen und Leckagen.</li>
</ul>
<ul>
<li>Planung: Basierend auf der Bestandsanalyse wird ein Austauschkonzept erstellt. Dies inkludiert die Auswahl der Materialien, die Planung der Leitungsf&uuml;hrung und die Koordination der einzelnen Gewerke.</li>
</ul>
<ul>
<li>Absprache: Abstimmung mit den Bewohnern und den zust&auml;ndigen Versorgungsunternehmen (Wasserwerk, Energieversorger) &uuml;ber den Zeitplan der Ma&szlig;nahmen.</li>
</ul>
<ul>
<li>Vorbereitung: Freimachen der Zug&auml;nge, Einrichtung einer provisorischen Versorgung falls notwendig, Abdeckung zum Schutz vor Staub und Schmutz.</li>
</ul>
<ul>
<li>Ausf&uuml;hrung: Entfernen der alten Steigleitungen, Installation der neuen Leitungen, Anschluss an das Versorgungsnetz und an die Verbrauchsstellen (Heizk&ouml;rper, Armaturen, Steckdosen).</li>
</ul>
<ul>
<li>&Uuml;berpr&uuml;fung: Nach Abschluss der Arbeiten m&uuml;ssen die neuen Steigleitungen auf ihre Funktion sowie auf Dichtigkeit und Isolation &uuml;berpr&uuml;ft werden.</li>
</ul>
<ul>
<li>Dokumentation: Eine genaue Dokumentation der erneuerten Installationen mit Pl&auml;nen und Materialliste ist f&uuml;r zuk&uuml;nftige Wartungen und Reparaturen wichtig.</li>
</ul>
<ul>
<li>Inbetriebnahme: Abschlie&szlig;end wird das System wieder in Betrieb genommen und den Bewohnern zur Verf&uuml;gung gestellt.</li>
</ul>
<p>Abschlie&szlig;end ist zu bemerken, dass die Erneuerung von Steigleitungen nicht nur fachliche Kompetenz verlangt, sondern ebenso eine enge Kommunikation mit allen Beteiligten. Diese Ma&szlig;nahmen k&ouml;nnen eine erhebliche Investition darstellen, tragen jedoch langfristig zur Werterhaltung des Hauses und zum Wohnkomfort bei.</p>`

export default {
  renew
}
