<script setup>
import { computed, onMounted, ref } from 'vue'
import Panel from '@/components/Elements/Panel.vue'
import RoomViewItem from '@/components/Navigation/Middle/Rooms/RoomViewItem.vue'
import { scrollToSelector } from '@/utils/scroll'
import { rooms as info } from '@/data/infos/panels'
import RoomType from '@/components/Modals/RoomType.vue'
import createRoom from '@/utils/room'
import track from '@/utils/tracking'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
})

const rooms = ref(null)

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const addRoom = (name, type, edit = false, enabled = false, length = 0, width = 0) => {
  model.value.rooms.push(createRoom(name, type, edit, enabled, length, width))
}

const addRoomModal = ref(false)

const addNewRoom = () => {
  addRoomModal.value = true
}

const removeRoom = (index) => {
  model.value.rooms.splice(index, 1)
}

const setCurrentRoom = (index) => {
  let timeout = model.value.step == 'rooms' ? 0 : 200

  model.value.step = 'rooms'
  model.value.rooms[index].enabled = true

  setTimeout(() => {
    model.value.step = 'rooms.' + index

    if (window.innerWidth < 1024) {
      scrollToSelector('#right')
    }
  }, timeout)
}

const unsetCurrentRoom = (index) => {
  model.value.step = 'rooms'
}

const createNewRoom = ($event) => {
  addRoom($event.label, $event.value, false, true)
  setCurrentRoom(model.value.rooms.length - 1)
}

onMounted(() => {
  track.yandex(97558574, 'reachGoal', 'rooms')
  track.yandex(97558944, 'reachGoal', 'rooms')

  if (model.value.rooms.length === 0) {
    addRoom('Wohnzimmer', 'living_room', false, false, 4, 5)
    addRoom('Schlafzimmer', 'living_room', false, false, 3, 5)
    addRoom('Kinderzimmer', 'living_room', false, false, 3, 4)
    addRoom('Badezimmer', 'bathroom', false, false, 2, 3)
    addRoom('Küche', 'kitchen', false, false, 2, 5)
    addRoom('Flur', 'living_room', false, false, 2, 4)
  }
})
</script>

<template>
  <Panel
    title="Räume"
    subtitle="In welchen Räumen wollen Sie etwas sanieren? Oder wollen Sie etwas Allgemeines sanieren?"
    :info="info"
  >
    <ul ref="rooms">
      <RoomViewItem
        v-for="(room, k) in model.rooms"
        :index="k"
        v-model="model.rooms[k]"
        :form="modelValue"
        @deselect="() => unsetCurrentRoom(k)"
        @remove="() => removeRoom(k)"
        @select="() => setCurrentRoom(k)"
      >
      </RoomViewItem>
    </ul>
    <div
      class="flex flex-row items-center justify-items-stretch border-t p-3 pl-7 dark:border-white"
    >
      <button type="button" class="flex flex-row items-center dark:text-white" @click="addNewRoom">
        <i class="mr-3 rounded-full bg-action px-1 not-italic leading-[100%] text-black">+</i>
        <span>Zimmer hinzufügen</span>
      </button>
    </div>

    <RoomType v-model="addRoomModal" :type="addRoomModal" @select="createNewRoom" />
  </Panel>
</template>
