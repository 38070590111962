<script setup>
import { computed } from 'vue'
import InfoPanelIcon from '../Elements/InfoPanelIcon.vue'

const $emit = defineEmits(['update:modelValue'])

const props = defineProps({
  label: String,
  placeholder: String,
  name: String,
  labelClass: String,
  infoTitle: String,
  info: String,
  modelValue: String,
  disabled: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'text'
  }
})

const modalTitle = computed(() => {
  return props.infoTitle || props.label
})
</script>
<template>
  <fieldset class="mb-5" :name="name" :disabled="disabled">
    <div class="mb-2 flex flex-row items-center justify-between gap-x-10">
      <div class="mb-1 flex flex-row">
        <label class="block dark:text-white" :class="labelClass" v-html="label"></label>
        <InfoPanelIcon v-if="info" :content="info" :title="modalTitle" class="ml-2 mt-0.5">
          <slot name="info" />
        </InfoPanelIcon>
      </div>

      <slot name="right" />
    </div>

    <slot />
  </fieldset>
</template>
