<template>
  <div ref="wrapper" class="range-wrapper" :class="{ 'mb-32': helperText }">
    <div class="flex">
      <input
        ref="inputRef"
        v-model="model"
        :max="max"
        :min="min"
        :step="step"
        class="crange"
        type="range"
        @keyup.enter="toggleInput"
      />
      <div class="relative">
        <NumberInput
          v-if="showInput"
          v-model="model"
          class="!w-[140px] shrink-0 grow-0 !bg-white text-xl"
          @update:modelValue="afterInput"
          :max="max"
        />
        <div
          v-else
          class="ml-2 !w-[140px] shrink-0 grow-0 rounded-lg border border-primary bg-white py-1.5 text-center text-xl"
          @click="toggleInput"
        >
          {{ parsedModel }} <span v-if="!formatter" v-html="suffix || 'm<sup>2</sup>'" />
        </div>
        <p v-if="helperText" class="absolute left-2 top-full mt-2 text-sm text-gray-600">
          {{ helperText }} m<sup>2</sup>
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import NumberInput from '@/components/Inputs/NumberInput.vue'

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 100
  },
  min: {
    type: Number,
    default: 0
  },
  step: {
    type: Number,
    default: 1
  },
  suffix: {
    type: String,
    default: 'm<sup>2</sup>'
  },
  formatter: {
    type: Function,
    default: null
  },
  label: {
    type: String,
    default: ''
  },
  helperText: {
    type: String,
    default: ''
  }
})

const inputRef = ref(null)

const $emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    $emit('update:modelValue', parseFloat(value))
  }
})

const parsedModel = computed(() => {
  const x = parseFloat(model.value)

  if (props.formatter) {
    return props.formatter(x)
  }

  return x.toFixed(1).toString().replace('.', ',')
})

const showInput = ref(false)

const toggleInput = (e) => {
  const parent = e.target.closest('.range-wrapper')

  showInput.value = !showInput.value

  if (showInput.value) {
    nextTick(() => {
      const input = parent.querySelector('input.num')

      if (input) {
        input.focus()
        input.select()
      }
    })
  }
}

const afterInput = (v) => {
  if (!v || v < props.min) {
    model.value = props.min
  }

  nextTick(() => {
    showInput.value = false
  })
}

const preventEnterKeyDefault = (event) => {
  if (
    event.key === 'Enter' &&
    event.target.tagName === 'INPUT' &&
    !event.target.classList.contains('allow-enter')
  ) {
    event.preventDefault()
  }
}

onMounted(() => {
  document.addEventListener('keydown', preventEnterKeyDefault)
})
</script>

<style lang="pcss">
.range-wrapper {
  position: relative;
}

input[type='range'].crange {
  -webkit-appearance: none;
  width: 100%;
  background-color: transparent;
}

input[type='range'].crange::-webkit-slider-runnable-track {
  height: 13px;
  width: 100%;
  cursor: pointer;
  background-color: white;
  border-radius: 9999px;
  border: 1px solid #001932;
}

input[type='range'].crange:focus {
  outline: none;
}

input[type='range'].crange::-webkit-slider-runnable-track {
  height: 10px;
  @apply w-full cursor-pointer rounded-full bg-white dark:bg-white;
}

input[type='range'].crange::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: -7px;
  box-shadow: -2px 3px 3px #00000032;
  @apply size-6 cursor-pointer rounded-full border-0 bg-primary text-white;
}

input[type='range'].crange::-moz-range-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 10px;
  @apply w-full rounded-full bg-gray-500 dark:bg-white;
}

input[type='range'].crange::-moz-range-thumb {
  width: 20px;
  height: 20px;
  box-shadow: -2px 3px 3px #00000032;
  @apply cursor-pointer rounded-full border-0 bg-action text-white;
}

input[type='range'].crange::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 13px 0;
  color: transparent;
  width: 100%;
  height: 15px;
  cursor: pointer;
}

input[type='range'].crange::-ms-fill-lower {
  background: #9284a7;
  border: 1px solid #562425;
  border-radius: 26.4px;
}

input[type='range'].crange::-ms-fill-upper {
  background: #9284a7;
  border: 1px solid #562425;
  border-radius: 26.4px;
}

input[type='range'].crange::-ms-thumb {
  width: 19px;
  height: 39px;
  background: #6b1817;
  border-radius: 2px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}

input[type='range'].crange:focus::-ms-fill-lower {
  background: #9284a7;
}

input[type='range'].crange:focus::-ms-fill-upper {
  background: #9284a7;
}
</style>
