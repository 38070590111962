<template>
  <RadioGroup v-model="model" class="mb-5 grid grid-cols-3 gap-4" name="quality">
    <RadioGroupOption :value="o.value" v-for="o in options" v-slot="{ checked }">
      <button
        type="button"
        class="block w-full rounded-lg border py-3 leading-5"
        :class="{
          'border-primary bg-primary font-bold text-white dark:border-white dark:bg-white dark:text-black':
            checked,
          'border-primary dark:border-action dark:text-white': !checked
        }"
      >
        <img
          :src="o.image"
          alt="Quality"
          class="mx-auto mb-3 w-20"
          :class="{
            'grayscale invert filter': checked
          }"
        />
        <span class="block">{{ o.label }}</span>
        <span class="block">Qualität</span>
      </button>

      <p v-if="o.description" class="mt-3 text-sm dark:text-gray-200" v-html="o.description"></p>
    </RadioGroupOption>
  </RadioGroup>
</template>

<script setup>
import { getImageUrl } from '@/utils/image'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    default: 0
  },
  label: {
    type: String,
    default: ''
  },
  budget: {
    type: Boolean,
    default: true
  },
  standard: {
    type: Boolean,
    default: true
  },
  premium: {
    type: Boolean,
    default: true
  }
})

const $emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    $emit('update:modelValue', value)
  }
})

const options = computed(() => {
  let x = [
    {
      label: 'Budget',
      value: 'budget',
      image: getImageUrl('/images/calc/quality/1.png')
    },
    {
      label: 'Standard',
      value: 'standard',
      image: getImageUrl('/images/calc/quality/2.png')
    },
    {
      label: 'Premium',
      value: 'premium',
      image: getImageUrl('/images/calc/quality/3.png')
    }
  ]

  if (!props.budget) {
    x = x.filter((o) => o.value !== 'budget')
  }

  if (!props.standard) {
    x = x.filter((o) => o.value !== 'standard')
  }

  if (!props.premium) {
    x = x.filter((o) => o.value !== 'premium')
  }

  return x
})
</script>
