<template>
  <Menu as="div" class="relative inline-block text-left" ref="root">
    <MenuButton class="px-0 text-white">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6" viewBox="0 0 24 24">
        <path
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M12 5.92A.96.96 0 1 0 12 4a.96.96 0 0 0 0 1.92m0 7.04a.96.96 0 1 0 0-1.92a.96.96 0 0 0 0 1.92M12 20a.96.96 0 1 0 0-1.92a.96.96 0 0 0 0 1.92"
        />
      </svg>
    </MenuButton>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems
        class="absolute right-0 mt-2 w-40 origin-top-right divide-y divide-gray-100 rounded-md bg-action shadow-lg ring-1 ring-black/5 focus:outline-none"
      >
        <div class="px-1 py-1">
          <MenuItem v-slot="{ active }">
            <button
              type="button"
              @click="() => emit('rename')"
              :class="[
                active ? 'bg-primary text-white' : 'text-gray-900',
                'group flex w-full items-center rounded-md px-2 py-2'
              ]"
            >
              umbenennen
            </button>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <button
              type="button"
              @click="() => emit('remove')"
              :class="[
                active ? 'bg-primary text-white' : 'text-gray-900',
                'group flex w-full items-center rounded-md px-2 py-2'
              ]"
            >
              löschen
            </button>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ref } from 'vue'

const props = defineProps({
  modelValue: Object
})

const emit = defineEmits(['update:modelValue', 'remove', 'rename'])

const root = ref(null)
</script>
