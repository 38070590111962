<script setup>
import { computed, ref } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Radio from '@/components/Inputs/Radio.vue'
import AmountInput from '@/components/Inputs/AmountInput.vue'

import { blueprintOpenTypeOptions } from '@/data/options/blueprint'
import infos from '@/data/infos/services/blueprint'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})
</script>

<template>
  <div>
    <FormGroup
      info-title="Wände öffnen"
      :info="infos.open"
      label="Sollen Wände geöffnet werden?"
      name="open"
    >
      <template #right>
        <Switch v-model="model.open.enabled" />
      </template>
      <template v-if="model.open.enabled">
        <FormGroup label="Wie viel Quadratmeter Wand soll geöffnet werden?" class="mt-5">
          <template #right>
            <div class="flex flex-nowrap items-center">
              <AmountInput v-model="model.open.size" class="w-16 px-2" />
              <span class="ml-2 whitespace-nowrap dark:text-white">in m<sup>2</sup></span>
            </div>
          </template>
        </FormGroup>
        <FormGroup label="Um welche Art Wand handelt es sich?">
          <Radio v-model="model.open.type" name="type" :options="blueprintOpenTypeOptions" />
        </FormGroup>
      </template>
    </FormGroup>

    <FormGroup label="Sollen Wände geschlossen werden?" name="close">
      <template #right>
        <Switch v-model="model.close.enabled" />
      </template>
      <template v-if="model.close.enabled">
        <FormGroup label="Wie viel Quadratmeter Wand soll geschlossen werden?" class="mt-5">
          <template #right>
            <div class="flex flex-nowrap items-center">
              <AmountInput v-model="model.close.size" class="w-16 px-2" />
              <span class="ml-2 whitespace-nowrap dark:text-white">in m<sup>2</sup></span>
            </div>
          </template>
        </FormGroup>
        <FormGroup label="Wie soll die Wand geschlossen werden?">
          <Radio v-model="model.close.type" name="type" :options="blueprintOpenTypeOptions" />
        </FormGroup>
      </template>
    </FormGroup>

    <FormGroup
      label="Sollen die Öffnungen und Schließungen nachbearbeitet werden?"
      name="postProcessing"
    >
      <template #right>
        <Switch v-model="model.postProcessing.enabled" />
      </template>
    </FormGroup>
  </div>
</template>
