<script setup>
import { computed } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Quality from '@/components/Inputs/Quality.vue'
import Headline from '@/components/Elements/Headline.vue'
import RoomsSelection from '../Inputs/RoomsSelection.vue'

import infos from '@/data/infos/services/electrical'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})
</script>

<template>
  <div>
    <FormGroup
      label="Soll ein neuer Sicherungskasten eingebaut werden?"
      :info="infos.securityBox"
      name="security_box"
    >
      <template #right>
        <Switch v-model="model.security_box.enabled" />
      </template>
    </FormGroup>

    <FormGroup
      info-title="Datenschrank"
      :info="infos.dataCabinet"
      label="Soll ein Datenschrank eingebaut werden?"
      name="data_cabinet"
    >
      <template #right>
        <Switch v-model="model.data_cabinet.enabled" />
      </template>
    </FormGroup>

    <FormGroup
      name="heat_pump"
      v-if="form.base.type.includes('house')"
      :info="infos.heatPump"
      info-title="Wärmepumpe"
      label="Ist eine Wärmepumpe vorhanden oder soll eine verbaut werden?"
    >
      <template #right>
        <Switch v-model="model.heat_pump.enabled" />
      </template>
    </FormGroup>

    <FormGroup
      info-title="Elektrischen Leitungen"
      :info="infos.electricalWiring"
      label="Sollen die elektrischen Leitungen erneuert werden?"
      name="electrical_wiring"
    >
      <template #right>
        <Switch v-model="model.electrical_wiring.enabled" />
      </template>
    </FormGroup>

    <FormGroup
      v-if="model.electrical_wiring.enabled"
      :info="infos.electricalWiringRooms"
      info-title="Elektrischen Leitungen"
      label="In welchen Räumen sollen die Leitungen erneuert werden?"
    >
      <RoomsSelection class="mt-4" v-model="model.electrical_wiring.rooms" :rooms="form.rooms" />
    </FormGroup>

    <template v-if="model.electrical_wiring.enabled">
      <Headline
        title="Schalterprogramm"
        subtitle="Welche Qualität soll das Schalterprogramm haben?"
      />

      <FormGroup name="switch_programm">
        <Quality v-model="model.switch_programm.quality" />
      </FormGroup>
    </template>

    <FormGroup
      :info="infos.ceilingBurningPoint"
      info-title="Deckenbrennstelle"
      label="Soll eine Deckenbrennstelle versetzt oder erweitert werden?"
      name="ceiling_burning_point_extended"
    >
      <template #right>
        <Switch v-model="model.ceiling_burning_point_extended.enabled" />
      </template>
      <RoomsSelection
        class="mt-4"
        v-if="model.ceiling_burning_point_extended.enabled"
        v-model="model.ceiling_burning_point_extended.rooms"
        :rooms="form.rooms"
      />
    </FormGroup>

    <FormGroup
      :info="infos.ceilingBurningPoint"
      info-title="Deckenbrennstelle"
      label="Soll eine Deckenbrennstelle angelegt werden?"
      name="ceiling_burning_point_created"
    >
      <template #right>
        <Switch v-model="model.ceiling_burning_point_created.enabled" />
      </template>
      <RoomsSelection
        class="mt-4"
        v-if="model.ceiling_burning_point_created.enabled"
        v-model="model.ceiling_burning_point_created.rooms"
        :rooms="form.rooms"
      />
    </FormGroup>

    <FormGroup
      :info="infos.dimmer"
      info-title="Dimmer"
      label="Soll ein Dimmer eingebaut werden?"
      name="dimmer"
    >
      <template #right>
        <Switch v-model="model.dimmer.enabled" />
      </template>
      <RoomsSelection
        class="mt-4"
        v-if="model.dimmer.enabled"
        v-model="model.dimmer.rooms"
        :rooms="form.rooms"
      />
    </FormGroup>
  </div>
</template>
