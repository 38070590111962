<template>
  <div ref="wrapper" class="range-wrapper flex">
    <input
      ref="inputRef"
      v-model="model"
      id="range-input"
      :max="max"
      :min="min"
      :step="step"
      class="crange"
      type="range"
    />
    <NumberInput
      v-if="showInput"
      v-model="model"
      class="num ml-2 w-[150px]"
      @update:modelValue="afterInput"
      :max="max"
    />
    <div v-else class="input ml-2 w-[150px] py-1.5 text-center" @click="toggleInput">
      {{ parsedModel }} <span v-if="!formatter" v-html="suffix || 'm<sup>2</sup>'" />
    </div>
  </div>
</template>
<script setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue'
import bus from '@/utils/eventBus'
import NumberInput from '@/components/Inputs/NumberInput.vue'

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 100
  },
  min: {
    type: Number,
    default: 0
  },
  step: {
    type: Number,
    default: 1
  },
  suffix: {
    type: String,
    default: 'm<sup>2</sup>'
  },
  formatter: {
    type: Function,
    default: null
  },
  label: {
    type: String,
    default: ''
  }
})

const inputRef = ref(null)

const $emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    $emit('update:modelValue', parseFloat(value))
  }
})

const parsedModel = computed(() => {
  const x = parseFloat(model.value)

  if (props.formatter) {
    return props.formatter(x)
  }

  return x.toFixed(1).toString().replace('.', ',')
})

const showInput = ref(false)

const toggleInput = (e) => {
  const parent = e.target.closest('.range-wrapper')

  showInput.value = !showInput.value

  if (showInput.value) {
    nextTick(() => {
      const input = parent.querySelector('input.num')

      if (input) {
        input.focus()
        input.select()
      }
    })
  }
}

const afterInput = (v) => {
  if (!v || v < props.min) {
    model.value = props.min
  }

  nextTick(() => {
    showInput.value = false
  })
}
</script>
<style>
.range-wrapper {
  position: relative;
}

input[type='range'].crange {
  -webkit-appearance: none;
  width: 100%;
  background-color: transparent;
}

input[type='range'].crange:focus {
  outline: none;
}

input[type='range'].crange::-webkit-slider-runnable-track {
  height: 1px;
  @apply w-full cursor-pointer rounded-full bg-gray-300 dark:bg-white;
}

input[type='range'].crange::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: -9px;
  width: 20px;
  height: 20px;
  box-shadow: -2px 3px 3px #00000032;
  @apply cursor-pointer rounded-full border-0 bg-action text-white;
}

input[type='range'].crange::-moz-range-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  height: 1px;
  @apply w-full rounded-full bg-gray-500 dark:bg-white;
}

input[type='range'].crange::-moz-range-thumb {
  width: 20px;
  height: 20px;
  box-shadow: -2px 3px 3px #00000032;
  @apply cursor-pointer rounded-full border-0 bg-action text-white;
}

input[type='range'].crange::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 13px 0;
  color: transparent;
  width: 100%;
  height: 15px;
  cursor: pointer;
}

input[type='range'].crange::-ms-fill-lower {
  background: #9284a7;
  border: 1px solid #562425;
  border-radius: 26.4px;
}

input[type='range'].crange::-ms-fill-upper {
  background: #9284a7;
  border: 1px solid #562425;
  border-radius: 26.4px;
}

input[type='range'].crange::-ms-thumb {
  width: 19px;
  height: 39px;
  background: #6b1817;
  border-radius: 2px;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}

input[type='range'].crange:focus::-ms-fill-lower {
  background: #9284a7;
}

input[type='range'].crange:focus::-ms-fill-upper {
  background: #9284a7;
}

/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type='range'].crange {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
</style>
