<
<script setup>
import { computed, ref } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import EditRoomDropDown from '@/components/Navigation/Middle/Rooms/EditRoomDropDown.vue'

const emit = defineEmits(['update:modelValue', 'remove', 'select', 'deselect'])

const props = defineProps({
  form: Object,
  index: Number,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const setActive = (value) => {
  if (value) {
    emit('select')
  } else {
    emit('deselect')
  }
}

const rename = () => {
  model.value.edit = true
}

const finished = () => {
  model.value.edit = false

  if (model.value.name === '') {
    model.value.name = 'Zimmer'
  }
}
</script>

<template>
  <li class="room-item flex flex-row items-center border-b border-white py-3">
    <div class="mr-5 block shrink-0">
      <Switch v-model="model.enabled" @update:model-value="setActive" />
    </div>
    <h4 class="flex-grow text-xl font-normal dark:text-white">
      <input
        class="w-full cursor-pointer border-0 bg-transparent p-0 focus:border-b-2 focus:border-b-action focus:outline-0"
        :class="{
          'text-action': form.step === 'rooms.' + index
        }"
        v-model="model.name"
        placeholder="Zimmer"
        v-focus
        @keyup.enter="finished"
        @blur="finished"
        v-if="model.edit"
      />
      <span
        :class="{
          'font-semibold text-action': form.step === 'rooms.' + index
        }"
        @dblclick="model.edit = true"
        class="block w-full cursor-pointer"
        @click="() => emit('select')"
        v-else
        >{{ model.name }}</span
      >
    </h4>
    <EditRoomDropDown
      class="ml-5"
      v-model="model"
      @remove="() => emit('remove')"
      @rename="rename"
    />
  </li>
</template>
