export const heatingSystem = `<p>Eine Heizungsanlage hat die Aufgabe, ein Geb&auml;ude zu beheizen, indem sie W&auml;rme erzeugt und &uuml;ber das Heizungssystem verteilt. Es gibt verschiedene Arten von Heizungsanlagen, die sich in ihrer Funktion, Energieeffizienz und den eingesetzten Brennstoffen oder Energiequellen unterscheiden. Im Folgenden werden f&uuml;nf verschiedene Arten von Heizungsanlagen vorgestellt.</p>
<ol>
<li><strong>Gas-Etagenheizung</strong> Die Gas-Etagenheizung ist eine dezentrale Heizungsanlage, die sich in der Regel in der zu beheizenden Wohnung befindet. Hierbei hat jede Wohnung ihre eigene Heizungsanlage, die unabh&auml;ngig von den anderen Einheiten im Geb&auml;ude agiert. Die Gas-Etagenheizung wird direkt mit Erdgas betrieben und ist oft in Form eines Gaskombiger&auml;ts installiert, das sowohl f&uuml;r die Raumheizung als auch f&uuml;r die Warmwasserbereitung zust&auml;ndig ist. Sie bietet den Vorteil individueller Abrechnung und Steuerung, f&uuml;hrt jedoch zu mehreren Abgaswegen im Geb&auml;ude, was bei der Schornsteinwartung ber&uuml;cksichtigt werden muss.</li>
</ol>
<ol>
<li><strong>Pellet-Heizung</strong> Eine Pellet-Heizung ist eine Form der Biomasseheizung und nutzt gepresste Holzpellets als Brennstoff. Diese Heizungsart gilt als besonders umweltfreundlich, denn Holzpellets werden aus Holzresten hergestellt und emittieren bei der Verbrennung nur so viel CO2, wie der Baum w&auml;hrend seines Wachstums aufgenommen hat. Pellet-Heizungen ben&ouml;tigen einen Lagerraum f&uuml;r die Pellets und eine automatische Zuf&uuml;hrvorrichtung, die die Pellets in den Brennraum transportiert. Moderne Pellet-Anlagen weisen oft hohe Wirkungsgrade auf und k&ouml;nnen auch f&uuml;r die Kombination mit solarthermischen Anlagen ausgelegt sein.</li>
</ol>
<ol>
<li><strong>Gas-Brennwerttherme</strong> Die Gas-Brennwerttherme ist eine Weiterentwicklung der konventionellen Gasheizung und nutzt zus&auml;tzlich die W&auml;rme, die in den Abgasen enthalten ist. Diese W&auml;rme wird zur&uuml;ckgewonnen, indem die Abgase abgek&uuml;hlt und der dabei entstehende Wasserdampf kondensiert wird. Dadurch wird ein sehr hoher Wirkungsgrad erreicht, was eine Gas-Brennwerttherme besonders energiesparend und umweltfreundlich macht. Sie erfordert allerdings passende Abgasanlagen und eine gute W&auml;rmed&auml;mmung des Geb&auml;udes.</li>
</ol>
<ol>
<li><strong>Luftw&auml;rmepumpe innenliegend</strong> Eine innenliegende Luftw&auml;rmepumpe nutzt die in der Au&szlig;enluft enthaltene W&auml;rme, um das Geb&auml;ude zu beheizen. Der Verdampfer, der die W&auml;rme aus der Luft aufnimmt, ist dabei im Inneren des Geb&auml;udes installiert. Die Luft wird &uuml;ber Kan&auml;le zur W&auml;rmepumpe geleitet und nach der W&auml;rmeabgabe wieder nach au&szlig;en gef&uuml;hrt. Innenliegende Luftw&auml;rmepumpen haben den Vorteil, dass sie vor Witterungseinfl&uuml;ssen gesch&uuml;tzt sind, ben&ouml;tigen jedoch ein System zur Luftf&uuml;hrung und gen&uuml;gend Platz innerhalb des Geb&auml;udes.</li>
</ol>
<ol>
<li><strong>Luftw&auml;rmepumpe au&szlig;enliegend</strong> Eine au&szlig;enliegende Luftw&auml;rmepumpe befindet sich im Gegensatz zur innenliegenden Variante au&szlig;erhalb des Geb&auml;udes. Sie entzieht die W&auml;rme direkt der Au&szlig;enluft und f&uuml;hrt sie &uuml;ber einen K&uuml;hlkreislauf ins Innere des Hauses, wo sie mittels eines W&auml;rmetauschers an das Heizsystem abgegeben wird. Au&szlig;enliegende Luftw&auml;rmepumpen m&uuml;ssen wetterfest sein und sollten so aufgestellt werden, dass die Schallabstrahlung keine Beeintr&auml;chtigung f&uuml;r Bewohner oder Nachbarn darstellt. Sie sind einfacher zu installieren als innenliegende Pumpen und k&ouml;nnen platzsparend au&szlig;erhalb des Hauses angebracht werden.</li>
</ol>
<p>Bei der Wahl der richtigen Heizungsanlage sollten Aspekte wie Wirtschaftlichkeit, Umweltvertr&auml;glichkeit, vorhandener Platz und pers&ouml;nliche Pr&auml;ferenzen ber&uuml;cksichtigt werden. Zudem spielen regionale Gegebenheiten und vorhandene Infrastrukturen eine wichtige Rolle bei der Entscheidungsfindung.</p>`

export const heatingRebuild = `<p>Eine &Ouml;lheizung diente in der Vergangenheit vielen Haushalten zuverl&auml;ssig als W&auml;rmequelle. Aufgrund von Umweltauflagen, Wirtschaftlichkeit oder dem Wunsch nach einer moderneren Heizungsanlage wird mitunter der R&uuml;ckbau einer &Ouml;lheizung notwendig. Der R&uuml;ckbau einer &Ouml;lheizung ist ein komplexer Prozess, der in mehrere Schritte unterteilt werden kann. Hier wird erl&auml;utert, wie eine solche R&uuml;ckbauaktion &uuml;blicherweise vonstattengeht.</p>
<p><strong>Planung und Vorbereitung</strong></p>
<p>Bevor mit dem eigentlichen R&uuml;ckbau begonnen werden kann, ist eine umfassende Planung erforderlich. Dies beinhaltet in der Regel das Einholen notwendiger Genehmigungen und das Engagement von Fachfirmen. Ebenfalls ist es entscheidend, die Entsorgung des Heiz&ouml;ls und anderer Komponenten der Anlage im Vorfeld zu planen.</p>
<p><strong>Abschaltung der Energiezufuhr</strong></p>
<p>Als Sicherheitsma&szlig;nahme ist das erste, was man beim R&uuml;ckbau tun muss, die Abschaltung des Stroms und die Sicherstellung, dass die &Ouml;lversorgung gestoppt ist. Dabei sollte auch die Brennstoffzufuhr sicher unterbrochen und die &Ouml;lleitungen entleert werden.</p>
<p><strong>Entleerung des &Ouml;ltanks</strong></p>
<p>Der &Ouml;ltank muss von Rest&ouml;l befreit werden. Das kann in manchen F&auml;llen bedeuten, dass noch verwendbares Heiz&ouml;l umgepumpt und anderweitig gelagert oder verkauft wird. Verschmutztes &Ouml;l und &Ouml;lschlamm m&uuml;ssen fachgerecht entsorgt werden.</p>
<p><strong>Reinigung</strong></p>
<p>Nach der Entleerung muss der Tank gereinigt werden, um &uuml;brig gebliebene &Ouml;lreste und D&auml;mpfe zu entfernen. Dies ist oft ein Job f&uuml;r Spezialfirmen, da das Innere eines &Ouml;ltanks betrachtliche Gefahrenquellen (Gase, D&auml;mpfe) bergen kann.</p>
<p><strong>Demontage</strong></p>
<p>Wenn der Tank gereinigt ist, kann mit der vorsichtigen Demontage der Heizanlage begonnen werden. Dies umfasst die Trennung und Entfernung von Leitungen, Brenner, Kessel und weiteren Komponenten. Diese Teile m&uuml;ssen teilweise zerlegt, ausgebaut und sachgerecht entsorgt werden.</p>
<p><strong>Entsorgung</strong></p>
<p>Die Entsorgung der alten Heizungsanlage und des &Ouml;ltanks muss den gesetzlichen Bestimmungen entsprechen, da insbesondere &Ouml;ltanks als Gefahrgut gelten. Materialien wie Metalle k&ouml;nnen recycelt werden, w&auml;hrend Teile, die mit &Ouml;l in Ber&uuml;hrung gekommen sind, als Sonderm&uuml;ll behandelt werden.</p>
<p><strong>Kontamination und Sanierung</strong></p>
<p>Sollte es zu Boden- oder Grundwasserkontamination durch austretendes Heiz&ouml;l gekommen sein, muss eine Umweltsanierung erfolgen. Dazu z&auml;hlt die Beseitigung kontaminierter Erde sowie die Reinigung betroffener Bereiche nach umwelttechnischen Standards.</p>
<p><strong>Abschlussarbeiten</strong></p>
<p>Nachdem der R&uuml;ckbau abgeschlossen ist, muss das Gel&auml;nde wiederhergestellt werden. Dies kann eine Bodenauff&uuml;llung, die Beseitigung von Fundamenten oder die Vorbereitung f&uuml;r eine neue Heizungsanlage beinhalten.</p>
<p><strong>Hinweis zur Nachfolgeanlage</strong></p>
<p>Beim R&uuml;ckbau einer &Ouml;lheizung sollte auch die Installation eines neuen, umweltfreundlicheren Heizungssystems in Betracht gezogen werden. M&ouml;gliche Alternativen sind Gasheizungen, Pelletheizungen, W&auml;rmepumpen oder Solarthermieanlagen, je nach individuellen Gegebenheiten und Pr&auml;ferenzen.</p>
<p>Der R&uuml;ckbau einer &Ouml;lheizung sollte stets unter Einhaltung aller Sicherheits- und Umweltstandards durch spezielle Fachfirmen erfolgen, um eine korrekte und gesetzeskonforme Durchf&uuml;hrung zu garantieren. Eine sorgf&auml;ltige Planung und Koordination des R&uuml;ckbaus sind entscheidend f&uuml;r einen reibungslosen Prozess.</p>`

export const geyser = `<p>Ein Durchlauferhitzer ist ein Ger&auml;t zur Warmwasserbereitung, das dazu dient, Wasser zu erw&auml;rmen, w&auml;hrend es durch das Ger&auml;t hindurchflie&szlig;t. Dieses Prinzip unterscheidet sich von sogenannten Speicherheizungen, bei denen eine bestimmte Menge Wasser st&auml;ndig in einem Tank vorgehalten und beheizt wird. Durchlauferhitzer bieten somit Warmwasser "auf Abruf" und sind deshalb besonders energieeffizient.</p>
<p>Das Funktionsprinzip von Durchlauferhitzern ist relativ einfach. Wenn ein Wasserhahn oder eine andere Entnahmestelle f&uuml;r Warmwasser ge&ouml;ffnet wird, flie&szlig;t kaltes Wasser in das Ger&auml;t ein. In dem Durchlauferhitzer befinden sich Heizelemente (zum Beispiel elektrische Heizst&auml;be oder ein Gasbrenner), die durch das flie&szlig;ende Wasser aktiviert werden und es in Echtzeit auf die gew&uuml;nschte Temperatur erw&auml;rmen. Nachdem das Wasser die Heizelemente durchlaufen hat, wird es direkt zum Zapfhahn weitergeleitet.</p>
<p>Es gibt verschiedene Arten von Durchlauferhitzern:</p>
<ol>
<li>Elektrische Durchlauferhitzer: Diese sind weit verbreitet und nutzen elektrischen Strom, um das Wasser zu erw&auml;rmen. Sie kommen am h&auml;ufigsten in Haushalten vor, bei denen keine zentrale Warmwasserversorgung vorhanden ist. Die Installation ist vergleichsweise einfach, allerdings k&ouml;nnen die Betriebskosten aufgrund der hohen Energiepreise f&uuml;r Strom relativ hoch sein.</li>
</ol>
<ol>
<li>Gas-Durchlauferhitzer: Diese verwenden Gas (Erdgas oder Fl&uuml;ssiggas), um das Wasser zu erw&auml;rmen. Sie sind tendenziell effizienter als elektrische Modelle und haben niedrigere Betriebskosten, falls Gas g&uuml;nstiger als Strom ist. Die Installation kann allerdings aufwendiger sein, da eine Gasleitung ben&ouml;tigt wird und zus&auml;tzlich eine Abgasf&uuml;hrung notwendig ist.</li>
</ol>
<p>Durchlauferhitzer haben mehrere Vorteile gegen&uuml;ber traditionellen Warmwasserspeichern:</p>
<ul>
<li>Sie liefern sofort warmes Wasser und haben keine Vorlaufzeit.</li>
</ul>
<ul>
<li>Es gibt keine Standby-Verluste, da das Wasser nur bei Bedarf erw&auml;rmt wird.</li>
</ul>
<ul>
<li>Sie beanspruchen weniger Platz, weil kein gro&szlig;er Speichertank installiert werden muss.</li>
</ul>
<ul>
<li>Sie bieten eine hygienische L&ouml;sung, da kein Wasser l&auml;nger im Tank steht und damit die Legionellenbildung vermieden wird.</li>
</ul>
<p>Allerdings haben Durchlauferhitzer auch einige Nachteile. Der Hauptnachteil ist, dass sie in der Regel eine relativ hohe Anschlussleistung ben&ouml;tigen. Das kann dazu f&uuml;hren, dass die elektrische Hausinstallation entsprechend angepasst werden muss, was mit zus&auml;tzlichen Kosten verbunden sein kann. Zudem ist die Temperatur des erw&auml;rmten Wassers direkt von der Durchflussmenge abh&auml;ngig &ndash; bei einer zu hohen Entnahmerate kann das Wasser eventuell nicht ausreichend erw&auml;rmt werden.</p>
<p>Zusammenfassend ist ein Durchlauferhitzer eine praktische und effiziente M&ouml;glichkeit, jederzeit Warmwasser zur Verf&uuml;gung zu stellen, wobei die spezifischen Gegebenheiten vor Ort und die individuellen Bed&uuml;rfnisse ber&uuml;cksichtigt werden sollten.</p>`

export const radiator = `<p><strong> Flachheizk&ouml;rper Kompakt</strong></p>
<p>Flachheizk&ouml;rper, h&auml;ufig auch als Plattenheizk&ouml;rper bezeichnet, sind ein Klassiker unter den Heizk&ouml;rpertypen. Kompakte Flachheizk&ouml;rper sind effiziente W&auml;rmeabgeber, die durch ihre relativ flache Bauart und gerippte Oberfl&auml;chenstruktur gekennzeichnet sind. Der Aufbau besteht aus mehreren hintereinander angeordneten Metallplatten &ndash; sogenannten Heizplatten &ndash;, zwischen denen Konvektionslamellen eingef&uuml;gt sind. Diese Lamellen erh&ouml;hen die Oberfl&auml;che des Heizk&ouml;rpers und somit die W&auml;rmeabgabe an den Raum. Kompakte Flachheizk&ouml;rper besitzen in der Regel eine sichtbare Vor- und R&uuml;cklaufverrohrung. Ihre Vorteile liegen in der schnellen W&auml;rmeabgabe und der hohen Effizienz, allerdings sammelt sich Staub leicht zwischen den Lamellen an, was die Reinigung erschwert.</p>
<p><strong> Flachheizk&ouml;rper Plan</strong></p>
<p>Im Gegensatz zum kompakten Flachheizk&ouml;rper weist der Flachheizk&ouml;rper mit der Bezeichnung "Plan" eine glatte Front auf. Diese Heizk&ouml;rpervariante verf&uuml;gt &uuml;ber eine flache, meistens pulverbeschichtete Metallfront, die auf elegante Weise in moderne Wohnr&auml;ume integriert werden kann. Die plane Oberfl&auml;che erleichtert zudem die Reinigung, da sich weniger Staub und Schmutz ablagern kann. Trotz der ebenen Frontseite bieten Planheizk&ouml;rper eine effiziente W&auml;rmeabgabe, da hinter der Front versteckte Heizplatten und Konvektionselemente f&uuml;r eine gute W&auml;rmezirkulation sorgen. Sie gelten als stilvolle und designorientierte L&ouml;sung f&uuml;r eine behagliche Raumtemperatur.</p>
<p><strong> Handtuchheizk&ouml;rper</strong></p>
<p>Handtuchheizk&ouml;rper, oft auch als Badheizk&ouml;rper bezeichnet, dienen nicht nur der Raumtemperierung, sondern auch dem Trocknen von Handt&uuml;chern und anderen Textilien. Sie sind meistens aus metallischen Rohren gefertigt, die in vertikaler Richtung verlaufen und an denen die Handt&uuml;cher aufgeh&auml;ngt werden k&ouml;nnen. Handtuchheizk&ouml;rper gibt es in verschiedenen Designs &ndash; von einfachen, geraden Stangen bis zu ausgefallenen, kreativen Formen. Sie k&ouml;nnen an das zentrale Heizungssystem angeschlossen oder elektrisch betrieben werden. Besonders in Badezimmern sind sie eine funktionale und &auml;sthetische Erg&auml;nzung.</p>
<p><strong> Wandkonvektor</strong></p>
<p>Wandkonvektoren sind Heizger&auml;te, die prim&auml;r f&uuml;r eine konvektive W&auml;rmeabgabe konzipiert sind. Sie bestehen in der Regel aus einem Heizelement und einem Gebl&auml;se, das die erw&auml;rmte Luft schnell im Raum verteilt. Das erm&ouml;glicht eine schnelle und gleichm&auml;&szlig;ige Erw&auml;rmung der Umgebung. Wandkonvektoren sind oft flacher als herk&ouml;mmliche Flachheizk&ouml;rper und lassen sich leicht montieren. Sie werden h&auml;ufig als Zusatzheizung oder in R&auml;umen verwendet, in denen eine schnelle Beheizung erforderlich ist. Wandkonvektoren k&ouml;nnen ganzj&auml;hrig als W&auml;rmequelle genutzt werden oder als &Uuml;bergangsl&ouml;sung dienen, bevor die Zentralheizung in Betrieb genommen wird.</p>`

export const underfloorHeating = `<p>Fu&szlig;bodenheizungen sind eine beliebte Art der Raumheizung, da sie f&uuml;r eine gleichm&auml;&szlig;ige W&auml;rme im Raum sorgen und die W&auml;rme angenehm von den F&uuml;&szlig;en nach oben steigt. Es gibt zwei Haupttypen von Fu&szlig;bodenheizungen: elektrische Fu&szlig;bodenheizungen und wassergef&uuml;hrte (hydraulische) Fu&szlig;bodenheizungen. Beide Systeme haben ihre spezifischen Eigenschaften und Anforderungen f&uuml;r den Einbau.</p>
<p><strong>Elektrische Fu&szlig;bodenheizung</strong>: Eine elektrische Fu&szlig;bodenheizung arbeitet mit elektrischen Heizelementen, meistens Heizmatten oder Heizkabel, die direkt unter dem Bodenbelag installiert werden. Diese Heizelemente werden mit dem Stromnetz verbunden und erw&auml;rmen den Boden durch elektrischen Strom.</p>
<p>Vorteile der elektrischen Fu&szlig;bodenheizung sind unter anderem die einfache Installation, die keine umfangreichen Bauarbeiten erfordert, und die M&ouml;glichkeit, sie in einzelnen R&auml;umen nachzur&uuml;sten. Dar&uuml;ber hinaus reagiert die elektrische Fu&szlig;bodenheizung sehr schnell, was sie f&uuml;r B&auml;der oder andere R&auml;ume sinnvoll macht, die nicht kontinuierlich beheizt werden m&uuml;ssen.</p>
<p>Ein Nachteil ist der meist h&ouml;here Energieverbrauch im Vergleich zu wassergef&uuml;hrten Systemen, was auf Dauer zu h&ouml;heren Betriebskosten f&uuml;hren kann. Bei der Planung sollten auch die notwendigen elektrischen Anschl&uuml;sse und Sicherheitsanforderungen ber&uuml;cksichtigt werden.</p>
<p><strong>Wassergef&uuml;hrte Fu&szlig;bodenheizung</strong>: Eine wassergef&uuml;hrte Fu&szlig;bodenheizung besteht aus einem Netz von Rohren, durch die warmes Wasser flie&szlig;t. Dieses System wird in der Regel in den Estrich des Bodens integriert und ist mit der zentralen Heizanlage des Hauses verbunden. Das warme Wasser gibt seine W&auml;rme an den Boden ab und sorgt so f&uuml;r eine gleichm&auml;&szlig;ige Erw&auml;rmung der R&auml;ume.</p>
<p>Die wassergef&uuml;hrte Fu&szlig;bodenheizung ist energieeffizient und kann mit verschiedenen W&auml;rmequellen, wie Gas-Brennwertkesseln, W&auml;rmepumpen oder Solarthermie, kombiniert werden. Dies macht sie zu einer guten Wahl f&uuml;r Neubauten oder umfassende Renovierungsprojekte, bei denen Umweltfreundlichkeit und langfristige Einsparungen im Vordergrund stehen.</p>
<p>Ein wesentlicher Nachteil ist der h&ouml;here Installationsaufwand, da in den meisten F&auml;llen ein neuer Estrich ben&ouml;tigt wird, in den die Rohre integriert werden. Diese Art der Fu&szlig;bodenheizung eignet sich daher weniger f&uuml;r eine Nachr&uuml;stung im Rahmen kleinerer Renovierungen.</p>
<p>Voraussetzungen f&uuml;r den Einbau: Beim Einbau einer Fu&szlig;bodenheizung m&uuml;ssen verschiedene Voraussetzungen beachtet werden.</p>
<p>F&uuml;r die elektrische Variante sind folgende Punkte wichtig:</p>
<ul>
<li>Die Tragf&auml;higkeit und Beschaffenheit des Untergrundes muss die zus&auml;tzliche Last tragen k&ouml;nnen.</li>
</ul>
<ul>
<li>Der Bodenbelag muss f&uuml;r die Nutzung mit Fu&szlig;bodenheizung geeignet sein.</li>
</ul>
<ul>
<li>Es m&uuml;ssen ausreichende elektrische Anschl&uuml;sse und ein Sicherungskonzept vorhanden sein.</li>
</ul>
<p>F&uuml;r die wassergef&uuml;hrte Fu&szlig;bodenheizung sind diese Aspekte von Bedeutung:</p>
<ul>
<li>Vor allem bei Neubauten oder gr&ouml;&szlig;eren Renovierungen realisierbar.</li>
</ul>
<ul>
<li>Der Estrich muss f&uuml;r die Integration der Heizrohre geeignet sein.</li>
</ul>
<ul>
<li>Das Heizsystem muss an die zentrale Heizungsanlage angeschlossen werden k&ouml;nnen.</li>
</ul>
<ul>
<li>Die Raumh&ouml;he muss die zus&auml;tzliche H&ouml;he des Bodenaufbaus ber&uuml;cksichtigen.</li>
</ul>
<p>Zusammenfassend l&auml;sst sich sagen, dass die Wahl zwischen elektrischer und wassergef&uuml;hrter Fu&szlig;bodenheizung von verschiedenen Faktoren wie Budget, Renovierungsumfang, Energieeffizienz und pers&ouml;nlichen Vorlieben abh&auml;ngt. In jedem Fall ist es ratsam, vor der Entscheidung eine fachm&auml;nnische Beratung in Anspruch zu nehmen, um das f&uuml;r das jeweilige Projekt passende Heizsystem zu finden.</p>`

export const stove = `<p>Der Unterschied zwischen einem Ofen und einem Kamin liegt sowohl in der Bauweise und Funktionsweise als auch im Verwendungszweck und den Einbaum&ouml;glichkeiten.</p>
<p>Ein Ofen, oft auch als Heizofen oder Schwedenofen bezeichnet, ist ein freistehendes Heizger&auml;t, das mit festen Brennstoffen wie Holz, Pellets oder Kohle betrieben wird. &Ouml;fen bestehen &uuml;blicherweise aus Gusseisen oder Stahl und haben eine geschlossene Brennkammer. Die W&auml;rme wird haupts&auml;chlich durch Strahlung und Konvektion an den Raum abgegeben. Viele moderne &Ouml;fen verf&uuml;gen &uuml;ber eine Verglasung, durch die das Feuer sichtbar ist, was f&uuml;r eine gem&uuml;tliche Atmosph&auml;re sorgt.</p>
<p>&Ouml;fen sind aufgrund ihrer kompakten Gr&ouml;&szlig;e und der M&ouml;glichkeit, sie nahezu &uuml;berall aufzustellen, sofern ein Rauchabzug vorhanden ist, besonders flexibel in der Platzierung. Damit eignen sie sich sowohl f&uuml;r H&auml;user als auch f&uuml;r Wohnungen, vorausgesetzt, der Anschluss an einen Schornstein ist gegeben und von einem Schornsteinfeger abgenommen. Bei Mietwohnungen muss der Einbau des Ofens mit dem Vermieter abgesprochen werden.</p>
<p>Ein Kamin, oft auch als offener Kamin bezeichnet, ist eine Feuerstelle, die fest in das Geb&auml;ude eingebaut wird. Kamine sind meist direkt in die Wand integriert und bieten einen offenen Blick auf das Feuer. Ihr Hauptzweck liegt weniger in der effizienten Beheizung eines Raumes als vielmehr in der Schaffung einer behaglichen Atmosph&auml;re. Die W&auml;rme wird haupts&auml;chlich durch Strahlung abgegeben, und der Wirkungsgrad eines offenen Kamins ist im Vergleich zu modernen &Ouml;fen eher gering. Offene Kamine ben&ouml;tigen einen gut dimensionierten Schornstein, um den Rauch abzuf&uuml;hren.</p>
<p>Kamine werden in der Regel in Einfamilienh&auml;usern eingebaut, da ein nachtr&auml;glicher Einbau in Wohnungen aufgrund des Bedarfs an umfangreichen Bauma&szlig;nahmen selten und sehr aufwendig ist. Zudem ist die Genehmigung in Mehrfamilienh&auml;usern schwieriger zu erhalten, da hier die Brandschutzauflagen und der Schornsteinzug anderer Parteien betroffen sein k&ouml;nnen.</p>
<p>In Wohngeb&auml;uden wird oft eine moderne Variante des Kamins vorgezogen: der Kaminofen. Kamin&ouml;fen vereinen die Vorteile des traditionellen Ofens hinsichtlich Effizienz und Leistung mit der Optik eines offenen Kamins. Im Gegensatz zu einem offenen Kamin sind sie jedoch geschlossen und haben eine verglaste T&uuml;r, durch die das Feuer sichtbar ist, was f&uuml;r Sicherheit und h&ouml;heren Wirkungsgrad sorgt.</p>
<p>In jedem Fall ist f&uuml;r die Installation sowohl von &Ouml;fen als auch Kaminen die Beratung und Genehmigung durch einen Fachmann und den Schornsteinfeger erforderlich, um die Einhaltung von Brandschutzvorschriften und geltenden Normen sicherzustellen.</p>`

export default {
  heatingSystem,
  heatingRebuild,
  geyser,
  radiator,
  underfloorHeating,
  stove
}
