import { z } from 'zod'
import services from '@/data/services'
import { watch } from 'vue'
import { debounce } from 'lodash'
import { getImageUrl } from '@/utils/image.js'

const API_URL = import.meta.env.VITE_API_URL

export const version = '0.2.3'

export const initials = {
  version,
  type: 'sanitation',
  step: 'base',
  base: {
    type: '',
    postal_code: '',
    city: '',
    address: '',
    size: 70,
    usable_size: 280,
    cellar: 0,
    floor: 'apartment',
    floors: ['ground'],
    built: '1941_1980',
    house_type: 'bungalow',
    design_type: 'massive'
  },
  services: services,
  rooms: [],
  contact: {
    fullName: '',
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    postal_code: '',
    offers: true,
    terms: true
  }
}

export const totalInitials = {
  total: {
    value: 0,
    amount: 0
  },
  rooms: []
}

export const registerWatcher = (formData, totals) => {
  watch(
    formData,
    debounce((value) => {
      console.log('Sending request ', value)
      const submitValue = Object.assign({}, value)

      delete submitValue.contact

      fetch(API_URL + '/api/forms/sc', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        body: JSON.stringify(submitValue)
      })
        .then((response) => response.json())
        .then(({ data }) => {
          totals.value = data
        })
    }, 500),
    { deep: true }
  )
}

export const registerShortcuts = (formData, totals, reset) => {
  window.addEventListener('keydown', (e) => {
    if (e.key === 'c' && e.ctrlKey) {
      navigator.clipboard.writeText(JSON.stringify(formData.value))
      alert('copied request')
    }

    if (e.key === 'd' && e.ctrlKey) {
      navigator.clipboard.writeText(totals.value.debugId)
      alert('copied debugId. Use it to generate calculation report!')
    }
  })
}

export const availableSteps = [
  {
    title: 'services_electrical_start',
    label: 'Elektronik',
    enabled: false,
    value: 'electrical',
    totals_key: 'Elektronik',
    completed: false,
    forTypes: ['house', 'apartment']
  },
  {
    title: 'services_heating_start',
    label: 'Heizungstechnik',
    enabled: false,
    value: 'heating',
    totals_key: 'Heizungstechnik',
    completed: false,
    forTypes: ['house', 'apartment']
  },
  {
    title: 'services_wallsCeilings_start',
    label: 'Wände & Decken',
    enabled: false,
    value: 'wallsCeilings',
    totals_key: 'Wände',
    completed: false,
    forTypes: ['house', 'apartment']
  },
  {
    title: 'services_floors_start',
    label: 'Böden & Fussleisten',
    enabled: false,
    value: 'floors',
    totals_key: 'Böden',
    completed: false,
    forTypes: ['house', 'apartment']
  },
  {
    title: 'services_windows_start',
    label: 'Fenster',
    enabled: false,
    value: 'windows',
    totals_key: 'Fenster',
    completed: false,
    forTypes: ['house', 'apartment']
  },
  {
    title: 'services_doors_start',
    label: 'Türen',
    enabled: false,
    value: 'doors',
    totals_key: 'Türen',
    completed: false,
    forTypes: ['house', 'apartment']
  },
  {
    title: 'services_baths_start',
    label: 'Bäder & Gäste WCs',
    enabled: false,
    value: 'baths',
    totals_key: 'Bad und WC',
    completed: false,
    forTypes: ['house', 'apartment']
  },
  {
    title: 'services_roof_start',
    label: 'Dach & Dämmung',
    enabled: false,
    value: 'roof',
    totals_key: 'Dach',
    completed: false,
    forTypes: ['house']
  },
  {
    title: 'services_facade_start',
    label: 'Fassade & Dämmung',
    enabled: false,
    value: 'facade',
    totals_key: 'Fassade',
    completed: false,
    forTypes: ['house']
  },
  {
    title: 'services_blueprint_start',
    label: 'Grundrissveränderung',
    enabled: false,
    value: 'blueprint',
    totals_key: 'Grundrissveränderungen',
    completed: false,
    forTypes: ['apartment', 'house']
  },
  {
    title: 'services_stairs_start',
    label: 'Treppe',
    enabled: false,
    value: 'stairs',
    totals_key: 'Treppe',
    completed: false,
    forTypes: ['apartment', 'house']
  },
  {
    title: 'services_pipes_start',
    label: 'Versorgungsleitungen',
    enabled: false,
    value: 'pipes',
    totals_key: 'Versorgungsleitungen',
    completed: false,
    forTypes: ['house']
  }
]
