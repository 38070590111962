import * as base from './options/base'
import * as facade from './options/facade'

export const {
  baseTypeOptions,
  baseFloorOptions,
  baseBuiltOptions,
  baseFloorsOptions,
  baseHouseTypeOptions,
  baseBuiltTypeOptions
} = base

export const { facadeTypeOptions } = facade
