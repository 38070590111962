<script setup>
import { computed } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Quality from '../Inputs/Quality.vue'
import AmountInput from '../Inputs/AmountInput.vue'
import Headline from '../Elements/Headline.vue'
import { getImageUrl } from '@/utils/image'
import infos from '@/data/infos/services/stairs'
import ImageOptionsSelection from '../Inputs/ImageOptionsSelection.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const options = [
  { label: 'Gerade', value: 'straight', image: getImageUrl('/images/calc/stairs/straight.png') },
  {
    label: 'Viertelgewendelt',
    value: 'quarter_turned',
    image: getImageUrl('/images/calc/stairs/quarter_turned.png')
  },
  {
    label: 'Halbgewendelt',
    value: 'half_turned',
    image: getImageUrl('/images/calc/stairs/half_turned.png')
  },
  {
    label: 'zweimal viertelgewendelt',
    value: 'twice_quarter_turned',
    image: getImageUrl('/images/calc/stairs/twice_quarter_turned.png')
  }
]
</script>

<template>
  <div>
    <FormGroup
      info-title="Treppe erneuern"
      :info="infos.renew"
      label="Soll eine neue Treppe verbaut werden?"
      name="rebuild"
    >
      <template #right>
        <Switch v-model="model.rebuild.enabled" />
      </template>
      <template v-if="model.rebuild.enabled">
        <ImageOptionsSelection
          class="mt-10"
          name="type"
          v-model="model.rebuild.type"
          :options="options"
        />
        <Headline title="Qualität"></Headline>
        <Quality class="mt-4" v-model="model.rebuild.quality" />
      </template>
    </FormGroup>

    <FormGroup label="Soll eine bestehende Treppe aufbereiten werden?" name="renew">
      <template #right>
        <Switch v-model="model.renew.enabled" />
      </template>
      <template v-if="model.renew.enabled">
        <FormGroup label="Soll eine Treppe lackiert werden?" name="lack" class="mt-4">
          <template #right>
            <Switch v-model="model.renew.lack.enabled" />
          </template>
        </FormGroup>

        <FormGroup
          label="Soll eine Betontreppe mit massiven Holzstufen belegt werden?"
          name="addWoodSteps"
          class="mt-4"
        >
          <template #right>
            <Switch v-model="model.renew.addWoodSteps.enabled" />
          </template>
          <template v-if="model.renew.addWoodSteps.enabled">
            <FormGroup label="Um wie viele Stufen handelt es sich?" class="mt-4">
              <template #right>
                <AmountInput
                  name="amount"
                  v-model="model.renew.addWoodSteps.amount"
                  class="w-16 px-2"
                />
              </template>
            </FormGroup>
          </template>
        </FormGroup>
      </template>
    </FormGroup>
  </div>
</template>
