export const walls = `<p>Wenn eine Renovierung durchgef&uuml;hrt wird, bietet sich oftmals auch die Gelegenheit, die W&auml;nde auf Vordermann zu bringen. Eine Wand neu zu spachteln, zu schleifen und zu streichen ist dann sinnvoll, wenn:</p>
<ol>
<li>Die vorhandene Wandfarbe abbl&auml;ttert oder Risse aufweist.</li>
</ol>
<ol>
<li>Die Wand Unebenheiten, L&ouml;cher oder Dellen von Bildern&auml;geln oder Schrauben hat.</li>
</ol>
<ol>
<li>Flecken oder Verf&auml;rbungen auf der Wand sind, die mit einem einfachen Anstrich nicht zu &uuml;berdecken w&auml;ren.</li>
</ol>
<ol>
<li>Die Wand eine vollkommen neue Optik oder einen frischen Farbton erhalten soll.</li>
</ol>
<ol>
<li>Der Untergrund f&uuml;r das Auftragen neuer Farben oder Tapeten vorbereitet werden soll.</li>
</ol>
<p>Der Ablauf sieht dabei in der Regel folgenderma&szlig;en aus:</p>
<ol>
<li>Spachteln: Mit Spachtelmasse werden L&ouml;cher, Risse und andere kleine Besch&auml;digungen der Wand sorgf&auml;ltig gef&uuml;llt. Dabei ist es wichtig, die Spachtelmasse glatt und b&uuml;ndig mit der Wand zu verschleifen, damit sp&auml;ter keine Unebenheiten sichtbar sind.</li>
</ol>
<ol>
<li>Schleifen: Nachdem die Spachtelmasse getrocknet ist, wird die Wand abgeschliffen, um eine m&ouml;glichst ebene Oberfl&auml;che zu erzielen und eventuelle Spachtelreste zu entfernen. Dies ist notwendig, um eine optimale Grundlage f&uuml;r den neuen Anstrich zu schaffen.</li>
</ol>
<ol>
<li>Streichen: Die fertig vorbereitete Wand kann nun gestrichen werden. Vorher sollte jedoch noch eine Grundierung aufgetragen werden, um die Haftung der Farbe zu verbessern und einen gleichm&auml;&szlig;igen Farbauftrag zu gew&auml;hrleisten.</li>
</ol>
<p>Beim Kauf der Wandfarbe gibt es verschiedene Qualit&auml;tsstufen, die sich in der Zusammensetzung, der Langlebigkeit und der Deckkraft unterscheiden, was sich auch im Preis widerspiegelt.</p>
<ul>
<li>Budgetfarben: Diese sind in der Regel die preisg&uuml;nstigsten Optionen und haben eine geringere Pigmentdichte. Sie k&ouml;nnen eine niedrigere Deckkraft aufweisen, was dazu f&uuml;hrt, dass man m&ouml;glicherweise mehrere Anstriche ben&ouml;tigt. Sie sind h&auml;ufig weniger strapazierf&auml;hig und k&ouml;nnen schneller verblassen oder durch Abrieb besch&auml;digt werden.</li>
</ul>
<ul>
<li>Standardfarben: Ein Mittelweg zwischen Budget- und Premiumoptionen. Sie haben meist eine bessere Deckkraft und sind langlebiger als Budgetfarben. Standardfarben eignen sich f&uuml;r die meisten Wohnbereiche und sind in einer guten Auswahl an Farben erh&auml;ltlich.</li>
</ul>
<ul>
<li>Premiumfarben: Diese Farben haben die h&ouml;chste Qualit&auml;t. Sie enthalten mehr Pigmente und Bindemittel, bieten eine ausgezeichnete Deckkraft und sind sehr ergiebig. Sie sind haltbarer, waschbar und oft auch schmutzabweisend. Premiumfarben k&ouml;nnen spezielle Eigenschaften haben, wie Feuchtigkeitsresistenz oder die F&auml;higkeit, kleinere Risse zu &uuml;berbr&uuml;cken. Sie sind in der Regel die kostenintensivste Wahl, k&ouml;nnen sich aber langfristig durch ihre Langlebigkeit und das bessere Finish auszahlen.</li>
</ul>
<p>Es ist wichtig, die r&auml;umlichen Anforderungen und pers&ouml;nlichen Pr&auml;ferenzen zu ber&uuml;cksichtigen, um die richtige Farbqualit&auml;t f&uuml;r Ihr Renovierungsprojekt auszuw&auml;hlen.</p>`

export const ceilings = `<p>Eine abgeh&auml;ngte Decke bezieht sich auf eine zweite Decke, die unterhalb der eigentlichen, fest verbauten Deckenstruktur installiert wird. Diese Konstruktion wird h&auml;ufig aus &auml;sthetischen Gr&uuml;nden, zur Verbesserung des Raumklangs, zur Installation von Beleuchtung, zur Verdeckung von Installationen wie Rohren oder Kabeln oder zur Energieeinsparung durch kleinere zu beheizende Volumina eingesetzt. Eine abgeh&auml;ngte Decke bietet zugleich M&ouml;glichkeiten f&uuml;r eine verbesserte W&auml;rme- und Schalld&auml;mmung.</p>
<p>Hier sind einige wichtige Aspekte, die bei der Planung und beim Abh&auml;ngen einer Decke beachtet werden m&uuml;ssen:</p>
<ol>
<li>Statik: Die Unterkonstruktion muss ausreichend belastbar sein, um die abgeh&auml;ngte Decke sicher zu tragen, insbesondere wenn schwere Materialien wie Gipskartonplatten verwendet werden oder wenn zus&auml;tzliche Installationen wie Beleuchtungsk&ouml;rper vorgesehen sind.</li>
<li>Auswahl des Materials: Es gibt verschiedene Materialien f&uuml;r abgeh&auml;ngte Decken, wie Metallrasterdecken, Holzdecken, Gipskartonplatten oder Mineralfaserplatten. Die Wahl des Materials h&auml;ngt von den Anforderungen hinsichtlich &Auml;sthetik, Akustik, Brandschutz und Lastenaufnahme ab.</li>
<li>H&ouml;he des Raums: Abgeh&auml;ngte Decken reduzieren die H&ouml;he des Raums, was sowohl optisch als auch funktional ber&uuml;cksichtigt werden muss. Es ist wichtig, sicherzustellen, dass der verbleibende Raum nicht zu niedrig und somit beengend wirkt.</li>
<li>Zug&auml;nglichkeit: Oft m&uuml;ssen abgeh&auml;ngte Decken so konstruiert werden, dass sie teilweise abnehmbar oder verschiebbar sind, um Zugang zu dar&uuml;berliegenden Installationen wie Elektrik, L&uuml;ftung oder Rohrleitungen zu erm&ouml;glichen.</li>
<li>Beleuchtung: Die Integration von Beleuchtung in eine abgeh&auml;ngte Decke bedarf sorgf&auml;ltiger Planung. Eine M&ouml;glichkeit besteht darin, Einbauleuchten zu verwenden, die direkt in die Deckenplatten integriert werden.</li>
<li>Brandschutz: Die verwendeten Materialien und die Konstruktion m&uuml;ssen den &ouml;rtlichen Brandschutzbestimmungen entsprechen. Nichtbrennbare Materialien und spezielle Konstruktionen k&ouml;nnen erforderlich sein.</li>
<li>Schallisolierung: Eine abgeh&auml;ngte Decke kann dazu beitragen, die Raumakustik zu verbessern, indem sie den Schall absorbiert oder reflektiert. Akustikplatten oder schalld&auml;mmende D&auml;mmstoffe k&ouml;nnen hierbei eingesetzt werden.</li>
<li>Building Codes und Genehmigungen: Es muss sichergestellt werden, dass die Installation der abgeh&auml;ngten Decke den geltenden Bauvorschriften entspricht, und es kann erforderlich sein, eine Baugenehmigung einzuholen.</li>
</ol>
<p>Die Installation einer abgeh&auml;ngten Decke erfordert sorgf&auml;ltige Planung und in vielen F&auml;llen die Kenntnisse eines Fachmanns, um sicherzustellen, dass die Konstruktion sicher, komfortabel und konform mit den lokalen Baubestimmungen ist.</p>`

export const ceilingSpots = `<p>Deckenspots sind moderne Lichtquellen, die in die Decke eingelassen werden und die h&auml;ufig in K&uuml;chen, B&auml;dern, Wohnzimmern oder B&uuml;ros verwendet werden. Sie sind bei der Raumgestaltung beliebt, weil sie gezielte Beleuchtung bieten, ohne den Platz einzunehmen, den herk&ouml;mmliche Lampen oder Leuchten ben&ouml;tigen.</p>
<p><strong>Wichtige Eigenschaften von Deckenspots:</strong></p>
<ol>
<li><strong>Design:</strong> Deckenspots sind in verschiedenen Designs verf&uuml;gbar, von schlicht und unauff&auml;llig bis hin zu stilvollen und dekorativen Modellen. Sie k&ouml;nnen einzeln oder in Gruppen angeordnet sein.</li>
<li><strong>Lichtquelle:</strong> Sie k&ouml;nnen mit verschiedenen Arten von Gl&uuml;hbirnen, wie Halogen, LED oder Energiesparlampen, best&uuml;ckt werden. LEDs sind aufgrund ihrer Langlebigkeit und Energieeffizienz besonders beliebt.</li>
<li><strong>Richtbarkeit:</strong> Viele Deckenspots sind schwenkbar, sodass man die Beleuchtung nach Bedarf ausrichten kann.</li>
<li><strong>Abdeckung:</strong> Manche Modelle sind mit einer festen Abdeckung versehen, w&auml;hrend andere offen sind. Die Wahl h&auml;ngt vom gew&uuml;nschten Lichteffekt ab.</li>
<li><strong>Dimmbarkeit:</strong> Viele moderne Deckenspots sind dimmbar, was es erm&ouml;glicht, die Helligkeit je nach Stimmung oder Bedarf einzustellen.</li>
</ol>
<p><strong>Einbau von Deckenspots:</strong></p>
<p>Der Einbau von Deckenspots erfordert eine sorgf&auml;ltige Planung und Vorbereitung. Hier sind die wichtigsten Schritte:</p>
<ol>
<li><strong>Stromversorgung sicherstellen:</strong> Vor Beginn der Installationsarbeiten sollte die Stromversorgung im betreffenden Bereich sicherheitshalber abgeschaltet werden.</li>
<li><strong>Planung:</strong> Legen Sie fest, wo die Spots angebracht werden sollen. Hierf&uuml;r ist es wichtig, den Verlauf von Stromleitungen und Tragebalken zu kennen.</li>
<li><strong>Bohrung:</strong> F&uuml;r die Installation der Deckenspots m&uuml;ssen L&ouml;cher in die Decke gebohrt werden, die den Durchmesser der Spotgeh&auml;use haben. Die genaue Gr&ouml;&szlig;e und Tiefe der L&ouml;cher wird durch die Einbauanleitung der Spots vorgegeben.</li>
<li><strong>Verkabelung:</strong> Ziehen Sie die entsprechenden Elektrokabel durch die Bohrungen. Beachten Sie dabei die geltenden Elektroinstallationsvorschriften.</li>
<li><strong>Installation der Spots:</strong> Schlie&szlig;en Sie die elektrischen Anschl&uuml;sse gem&auml;&szlig; den Anleitungen an und setzen Sie die Spots in die vorgesehenen L&ouml;cher ein. Viele Spots haben Federklammern oder Schraubverschl&uuml;sse, die sie sicher in der Decke befestigen.</li>
<li><strong>Testlauf:</strong> Nachdem alle Spots eingebaut sind, schalten Sie die Stromversorgung wieder ein und testen Sie die Funktion der Beleuchtung. &Uuml;berpr&uuml;fen Sie auch, ob alle Spots richtig ausgerichtet sind.</li>
</ol>
<p><strong>Hinweise:</strong></p>
<ul>
<li>Beim Einbau von Deckenspots sollte immer auf die Einbautiefe geachtet werden, damit die Spots ausreichend gek&uuml;hlt werden und die W&auml;rme richtig abgegeben wird.</li>
</ul>
<ul>
<li>Eine dimmbare Installation erfordert kompatible Leuchtmittel und Dimmer.</li>
</ul>
<ul>
<li>Bedenken Sie beim Einbau von Deckenspots in Nassr&auml;umen die Schutzart (IP-Klassifizierung), welche die Eignung von Leuchten f&uuml;r diese Umgebung angibt.</li>
</ul>
<ul>
<li>Der Einbau von Elektroinstallationen sollte stets von fachkundigen Personen durchgef&uuml;hrt werden, um Gefahren und Sch&auml;den zu vermeiden.</li>
</ul>
<p>Deckenspots sind eine stilvolle und praktische Beleuchtungsl&ouml;sung, die Flexibilit&auml;t und Atmosph&auml;re in einem Raum bieten k&ouml;nnen. Mit der richtigen Planung und Installation kann diese Art der Beleuchtung die Wohnqualit&auml;t deutlich erh&ouml;hen.</p>`

export const improveStucco = `<p>Stuck ist eine traditionelle Form der Wand- und Deckenverzierung, die besonders in Altbauten h&auml;ufig anzutreffen ist. Er besteht meist aus Gips, Kalk, Zement oder einer Mischung aus diesen Materialien und wird von Fachleuten mit speziellen Techniken geformt. &Uuml;ber die Jahre kann Stuck durch &auml;u&szlig;ere Einfl&uuml;sse wie Feuchtigkeit, Temperaturschwankungen oder mechanische Besch&auml;digungen Risse erhalten oder Teile davon k&ouml;nnen sich l&ouml;sen. Hier erfahren Sie, wie man Stuck ausbessert, ausw&auml;scht oder neuen Stuck anbringt.</p>
<h3>Stuck ausbessern oder auswaschen</h3>
<p><strong>Diagnose und Vorbereitung</strong> Bevor man mit der Ausbesserung beginnt, sollte man den Zustand des Stucks genau pr&uuml;fen. Kleinere Risse und L&ouml;cher k&ouml;nnen selbst ausgebessert werden, bei gr&ouml;&szlig;eren Sch&auml;den oder wenn tragende Strukturen betroffen sind, sollte man jedoch einen Fachmann hinzuziehen.</p>
<p><strong>Material und Werkzeug</strong> F&uuml;r die Ausbesserung ben&ouml;tigt man nebeneinander Stuckm&ouml;rtel, der sich aus Gips oder Kalk zusammensetzt. Zur Grundausstattung an Werkzeug geh&ouml;ren au&szlig;erdem eine Kelle, ein Spachtel, eventuell eine Stuckateurkelle sowie Schleifpapier.</p>
<p><strong>Auswaschen</strong> Das Auswaschen bezeichnet das Reinigen des Stucks. Dabei wird lockeres Material entfernt und die Oberfl&auml;che f&uuml;r die Reparatur vorbereitet. Benutzen Sie eine B&uuml;rste oder ein angefeuchtetes Tuch, um Staub und Schmutz vom Stuck zu entfernen. Bei Schimmelbefall muss zus&auml;tzlich mit einem anti-schimmel Mittel vorgegangen werden.</p>
<p><strong>Ausf&uuml;llen von Rissen und L&ouml;chern</strong> Kleinere Risse werden gereinigt und anschlie&szlig;end mit Stuckm&ouml;rtel gef&uuml;llt. Hierbei den M&ouml;rtel fest in den Riss dr&uuml;cken, um Hohlr&auml;ume zu vermeiden. Nachdem der M&ouml;rtel angetrocknet ist, wird er mit einem feuchten Schwamm gegl&auml;ttet.</p>
<p><strong>Reparatur von gr&ouml;&szlig;eren Sch&auml;den</strong> Bei gr&ouml;&szlig;eren Sch&auml;den muss unter Umst&auml;nden ein St&uuml;ck Stuck neu modelliert oder es m&uuml;ssen Formst&uuml;cke hergestellt werden. Ist das Originalst&uuml;ck noch vorhanden, kann es als Vorlage f&uuml;r eine neue Form dienen. Der frische Stuck muss dann exakt an der Schadensstelle befestigt und in Form gebracht werden.</p>
<p><strong>Feinarbeiten und Anstrich</strong> Nachdem der Stuckm&ouml;rtel getrocknet ist, erfolgen die Feinarbeiten. Der Stuck wird geschliffen und bei Bedarf gegl&auml;ttet. Erst nach abschlie&szlig;endem Trocknen kann der Stuck neu gestrichen oder bei Bedarf mit speziellen Techniken patiniert werden.</p>`

export const newStucco = `<p>Stuck ist eine traditionelle Form der Wand- und Deckenverzierung, die besonders in Altbauten h&auml;ufig anzutreffen ist. Er besteht meist aus Gips, Kalk, Zement oder einer Mischung aus diesen Materialien und wird von Fachleuten mit speziellen Techniken geformt. &Uuml;ber die Jahre kann Stuck durch &auml;u&szlig;ere Einfl&uuml;sse wie Feuchtigkeit, Temperaturschwankungen oder mechanische Besch&auml;digungen Risse erhalten oder Teile davon k&ouml;nnen sich l&ouml;sen. Hier erfahren Sie, wie man Stuck ausbessert, ausw&auml;scht oder neuen Stuck anbringt.</p>
<p><strong>Planung</strong> Zun&auml;chst sollte der Raum vermessen und eine Skizze angefertigt werden, um zu entscheiden, wie und wo der Stuck angebracht werden soll. Es gibt diverse Gips- oder Styropor-Stuckelemente zu kaufen, die sich f&uuml;r die DIY-Installation eignen.</p>
<p><strong>Material und Werkzeug</strong> F&uuml;r die Anbringung von Stuckelementen ben&ouml;tigt man neben den eigentlichen Stuckelementen einen geeigneten Kleber, in der Regel Stuckkleber oder Montagekleber, und Werkzeuge wie Zollstock, Stuckateurs&auml;ge, Kelle, Zahnspachtel und gegebenenfalls eine Wasserwaage.</p>
<p><strong>Vorbereitung der Untergr&uuml;nde</strong> Der Untergrund muss tragf&auml;hig, sauber, trocken und frei von Materialien sein, die die Haftung beeintr&auml;chtigen k&ouml;nnten. Bei Bedarf muss der Untergrund mit einer Tiefengrundierung vorbehandelt werden.</p>
<p><strong>Anbringen des Stucks</strong> Stuckelemente werden mit Kleber versehen und an die gew&uuml;nschte Stelle an die Wand oder Decke gedr&uuml;ckt. Dabei wird das Element vorsichtig angedr&uuml;ckt und ausgerichtet. Es ist darauf zu achten, dass der Kleber f&uuml;r eine feste und dauerhafte Verbindung sorgt.</p>
<p><strong>Nachbearbeitung</strong> Nach dem Anbringen k&ouml;nnen die Stuckelemente bei Bedarf noch gek&uuml;rzt oder angepasst werden. Sobald der Kleber ausgeh&auml;rtet ist, k&ouml;nnen die St&ouml;&szlig;e zwischen den Stuckelementen verputzt und geschliffen werden, um eine einheitliche Oberfl&auml;che zu erzielen.</p>
<p>Stuck zu bearbeiten erfordert Sorgfalt und Pr&auml;zision. Bei gr&ouml;&szlig;eren Projekten oder Unsicherheiten ist es ratsam, einen Fachmann zurate zu ziehen.</p>`

export default {
  walls,
  ceilings,
  ceilingSpots,
  improveStucco,
  newStucco
}
