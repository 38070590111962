<script setup>
import { onMounted, ref } from 'vue'
import { computed } from 'vue'
import L from 'leaflet'
import drawLocales from 'leaflet-draw-locales'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet-draw'
import 'leaflet.gridlayer.googlemutant'
import { geocodeByPlaceId, getLatLng } from 'vue-use-places-autocomplete'

import PlacesAutocomplete from '@/components/Elements/PlacesAutocomplete.vue'

// Automatically defines Leaflet.draw to the specified language
drawLocales('de')

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const mapContainer = ref(null)
const map = ref(null)
const drawControl = ref(null)
const address = ref('')

const enableDrawing = () => {
  const polygonDrawer = new L.Draw.Polygon(map.value, drawControl.value.options.draw.polygon)
  polygonDrawer.enable()
}

const setLatLng = async (place) => {
  const results = await geocodeByPlaceId(place.place_id)
  const latLng = await getLatLng(results[0])

  console.log(results, latLng)

  map.value.setView([latLng.lat, latLng.lng], 18)
}

function haversineDistance(lat1, lon1, lat2, lon2) {
  var R = 6371000 // Erdradius in Metern
  var dLat = ((lat2 - lat1) * Math.PI) / 180
  var dLon = ((lon2 - lon1) * Math.PI) / 180
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c
  return d
}

onMounted(() => {
  map.value = L.map(mapContainer.value, {
    scrollWheelZoom: false
  }).setView([53.6164796, 10.0319377], 18)

  L.gridLayer
    .googleMutant({
      type: 'satellite' // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
    })
    .addTo(map.value)

  // Layer für Zeichnungen
  const drawnItems = new L.FeatureGroup()
  map.value.addLayer(drawnItems)

  // Zeichenoptionen hinzufügen
  drawControl.value = new L.Control.Draw({
    edit: {
      featureGroup: drawnItems,
      edit: false
    },
    draw: {
      polygon: {
        shapeOptions: {
          color: '#C8FF32', // Rahmenfarbe
          fillColor: '#C8FF32', // Füllfarbe
          fillOpacity: 0.8 // Transparenz der Füllung
        }
      },
      polyline: false,
      rectangle: false,
      circle: false,
      marker: false,
      circlemarker: false
    }
  })
  map.value.addControl(drawControl.value)

  enableDrawing()

  map.value.on(L.Draw.Event.CREATED, function (e) {
    const type = e.layerType,
      layer = e.layer

    if (type === 'polygon') {
      // Flächenberechnung für das Polygon
      const area = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])

      // Koordinaten des Polygons extrahieren
      var coords = layer.getLatLngs()[0]
      var lengths = []

      for (var i = 0; i < coords.length; i++) {
        var start = coords[i]
        var end = coords[(i + 1) % coords.length] // loop back to first for last segment
        var distance = haversineDistance(start.lat, start.lng, end.lat, end.lng)
        lengths.push(distance)
      }

      var longest = Math.max(...lengths)
      var shortest = Math.min(...lengths)

      model.value = {
        ...model.value,
        size: area.toFixed(2),
        width: shortest.toFixed(2),
        length: longest.toFixed(2)
      }
    }

    layer.on('click', function () {
      map.value.removeLayer(layer)
      model.value = {
        ...model.value,
        size: 0,
        width: 0,
        length: 0
      }
      enableDrawing()
    })

    drawnItems.addLayer(layer)
  })
})
</script>

<template>
  <section>
    <PlacesAutocomplete v-model="address" @update:model-value="setLatLng" />

    <div ref="mapContainer" style="height: 400px" class="rounded-lg"></div>
  </section>
</template>
