<script setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: {},
  type: {
    type: String,
    default: 'number'
  },
  placeholder: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})
</script>

<template>
  <input v-model="model" :placeholder="placeholder" :type="type" class="input no-spinners" />
</template>
