<script setup>
import { computed, ref } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Radio from '@/components/Inputs/Radio.vue'
import Quality from '@/components/Inputs/Quality.vue'
import AmountInput from '@/components/Inputs/AmountInput.vue'

import { facadeTypeOptions } from '@/data/options/facade'
import infos from '@/data/infos/services/facade'
import ImageOptionsSelection from '../Inputs/ImageOptionsSelection.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})
</script>

<template>
  <div>
    <FormGroup
      info-title="Fassade erneuern"
      :info="infos.renew"
      label="Soll die Fassade erneuert werden?"
      name="renew"
    >
      <template #right>
        <Switch v-model="model.renew.enabled" />
      </template>

      <FormGroup
        label="Geben Sie die Fläche Ihrere Fassade ein"
        class="mt-5"
        v-if="model.renew.enabled"
      >
        <template #right>
          <div>
            <AmountInput v-model="model.renew.size" class="w-16 px-2" />
            <span class="ml-2 dark:text-white">in m<sup>2</sup></span>
          </div>
        </template>
      </FormGroup>

      <FormGroup label="Welche Fassade wünschen Sie sich?" class="mt-5" v-if="model.renew.enabled">
        <ImageOptionsSelection
          v-model="model.renew.type"
          name="type"
          :options="facadeTypeOptions"
        />
      </FormGroup>
    </FormGroup>
  </div>
</template>
