<template>
  <div class="flex flex-row items-center">
    <NumberInput
      v-if="showInput"
      v-model="model"
      :placeholder="placeholder"
      class="num py-1 text-center"
      :class="inputClass"
      :tabIndex="tabIndex"
      @update:modelValue="afterInput"
    />
    <div v-else :class="inputClass" class="input py-1 text-center" @click="toggleInput">
      {{ parsedModel }} <span v-if="!formatter" v-html="suffix || 'm<sup>2</sup>'" />
    </div>
    <span class="ml-2 dark:text-white">{{ label }}</span>
  </div>
</template>
<script setup>
import { computed, nextTick, ref, watch } from 'vue'
import NumberInput from '@/components/Inputs/NumberInput.vue'

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  },
  tabIndex: {
    type: Number,
    default: 0
  },
  suffix: {
    type: String,
    default: 'm'
  },
  formatter: {
    type: Function,
    default: null
  },
  label: {
    type: String,
    default: ''
  },
  inputClass: {
    type: String,
    default: ''
  },
  placeholder: {
    default: ''
  }
})

const inputRef = ref(null)

const $emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    $emit('update:modelValue', parseFloat(value))
  }
})

const parsedModel = computed(() => {
  const x = parseFloat(model.value || 0)

  if (props.formatter) {
    return props.formatter(x)
  }

  return x.toFixed(1).toString().replace('.', ',')
})

const showInput = ref(false)

const toggleInput = (e) => {
  const parent = e.target.closest('.flex')

  showInput.value = !showInput.value

  if (showInput.value) {
    nextTick(() => {
      const input = parent.querySelector('input.num')

      if (input) {
        input.focus()
        input.select()
      }
    })
  }
}

const afterInput = (v) => {
  if (!v) {
    model.value = 0
  }

  nextTick(() => {
    showInput.value = false
  })
}
</script>
