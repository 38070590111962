<script setup>
import { computed } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Quality from '@/components/Inputs/Quality.vue'
import Radio from '@/components/Inputs/Radio.vue'
import OptionsAmountSelection from '@/components/Inputs/OptionsAmountSelection.vue'
import RoomsSelection from '../Inputs/RoomsSelection.vue'
import { getImageUrl } from '@/utils/image'

import infos from '@/data/infos/services/heating'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const systemOptions = computed(() => {
  if (props.form.base.type === 'apartment') {
    return [{ label: 'Gas-Etagenheizung', value: 'gas' }]
  }

  return [
    { label: 'Pellet-Heizung', value: 'pellet' },
    { label: 'Gas-Brennwerttherme', value: 'gas-thermal' },
    { label: 'Luftwärmepumpe außenliegend', value: 'air-outer' }
  ]
})

const rebuildOptions = [
  { label: 'Heizungsanlage', value: 'gas' },
  { label: 'Öltank Kunststoff', value: 'oil-plastic' },
  { label: 'Öltank Stahl', value: 'oil-steel' },
  { label: 'Ol Erdtank', value: 'oil-ground' }
]

const geyserAmounts = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 }
]

const radiatorOptions = [
  {
    label: 'Flachheizkörper kompakt',
    value: 'compact_flat_radiator',
    amount: 0,
    id: 1,
    image: getImageUrl('/images/calc/heating/kompakt.png')
  },
  {
    label: 'Flachheizkörper Plan',
    value: 'flat_radiator_plan',
    amount: 0,
    id: 2,
    image: getImageUrl('/images/calc/heating/plan.png')
  },
  {
    label: 'Handtuchheizkörper kompakt',
    value: 'towel_radiator_compact',
    amount: 0,
    id: 3,
    image: getImageUrl('/images/calc/heating/towel_kompakt.png')
  },
  {
    label: 'Wandkonvektor',
    value: 'wall_convector',
    amount: 0,
    id: 4,
    image: getImageUrl('/images/calc/heating/converter.png')
  }
]
</script>

<template>
  <div>
    <FormGroup
      info-title="Heizungsanlage"
      :info="infos.heatingSystem"
      label="Soll eine neue Heizungsanlage verbaut werden?"
      name="heating_system"
    >
      <template #right>
        <Switch v-model="model.heating_system.enabled" />
      </template>

      <Radio
        class="mt-4"
        v-if="model.heating_system.enabled"
        v-model="model.heating_system.type"
        name="type"
        :options="systemOptions"
      />
    </FormGroup>

    <FormGroup
      info-title="Heizungsanlage"
      :info="infos.heatingRebuild"
      label="Soll eine bestehende Heizungsanlage zurückgebaut werden?"
      name="heating_rebuild"
    >
      <template #right>
        <Switch v-model="model.heating_rebuild.enabled" />
      </template>

      <Radio
        class="mt-4"
        v-if="model.heating_rebuild.enabled"
        name="type"
        v-model="model.heating_rebuild.type"
        :options="rebuildOptions"
      />
    </FormGroup>

    <FormGroup
      info-title="Durchlauferhitzer"
      :info="infos.geyser"
      label="Brauchen Sie einen Durchlauferhitzer?"
      name="geyser"
    >
      <template #right>
        <Switch v-model="model.geyser.enabled" />
      </template>

      <Radio
        class="mt-4 max-w-[300px]"
        v-if="model.geyser.enabled"
        name="amount"
        v-model="model.geyser.amount"
        :options="geyserAmounts"
      />
    </FormGroup>

    <FormGroup
      info-title="Heizkörpertypen im Vergleich"
      :info="infos.radiator"
      label="Sollen neue Heizkörper verbaut werden?"
      name="radiator"
    >
      <template #right>
        <Switch v-model="model.radiator.enabled" />
      </template>

      <OptionsAmountSelection
        v-if="model.radiator.enabled"
        class="mt-4"
        name="items"
        v-model="model.radiator.items"
        :options="radiatorOptions"
      />
    </FormGroup>

    <FormGroup
      v-if="form.base.type === 'house'"
      info-title="Fußbodenheizung"
      :info="infos.underfloorHeating"
      label="Soll eine Fußbodenheizung verbaut werden?"
      name="underfloor_heating"
    >
      <template #right>
        <Switch v-model="model.underfloor_heating.enabled" />
      </template>

      <RoomsSelection
        class="mt-4"
        v-if="model.underfloor_heating.enabled"
        v-model="model.underfloor_heating.rooms"
        :rooms="form.rooms"
      />
    </FormGroup>

    <FormGroup
      info-title="Ofen"
      :info="infos.stove"
      label="Soll ein Ofen eingebaut werden?"
      name="stove"
    >
      <template #right>
        <Switch v-model="model.stove.enabled" />
      </template>

      <Quality
        :budget="false"
        class="mt-4"
        v-if="model.stove.enabled"
        v-model="model.stove.quality"
      />

      <FormGroup
        label="Soll ein Außenabzug verbaut werden?"
        name="exhaust"
        v-if="model.stove.enabled && form.base.type === 'house'"
      >
        <template #right>
          <Switch v-model="model.stove.exhaust.enabled" />
        </template>
      </FormGroup>
    </FormGroup>

    <FormGroup
      info-title="Kamin"
      :info="infos.stove"
      label="Soll ein Kamin eingebaut werden?"
      name="chimney"
    >
      <template #right>
        <Switch v-model="model.chimney.enabled" />
      </template>

      <Quality
        class="mt-4"
        :budget="false"
        v-if="model.chimney.enabled"
        v-model="model.chimney.quality"
      />

      <FormGroup
        label="Soll ein Außenabzug verbaut werden?"
        name="exhaust"
        v-if="model.chimney.enabled && form.base.type === 'house'"
      >
        <template #right>
          <Switch v-model="model.chimney.exhaust.enabled" />
        </template>
      </FormGroup>
    </FormGroup>
  </div>
</template>
