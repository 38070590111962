<template>
  <div class="mb-4">
    <Combobox v-model="model">
      <div class="relative">
        <div class="relative w-full cursor-default overflow-hidden rounded-lg text-left text-white">
          <ComboboxInput
            placeholder="Ihre Adresse"
            class="w-full rounded-lg border-2 border-action bg-transparent py-2 pl-3 pr-10 leading-5 text-white focus:ring-0 focus:ring-action"
            :displayValue="(place) => place?.description"
            @change="query = $event.target.value"
          />
          <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2 opacity-0">
            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
          </ComboboxButton>
        </div>
        <TransitionRoot
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          @after-leave="query = ''"
        >
          <ComboboxOptions
            class="absolute z-[1000] mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
          >
            <div
              v-if="suggestions.length === 0 && query !== ''"
              class="relative cursor-default select-none px-4 py-2 text-gray-700"
            >
              {{
                query.length < 3
                  ? 'Bitte geben Sie mindestens 3 Zeichen ein.'
                  : 'Keine Ergebnisse gefunden.'
              }}
            </div>

            <ComboboxOption
              v-for="place in suggestions"
              as="template"
              :key="place.place_id"
              :value="place"
              v-slot="{ selected, active }"
            >
              <li
                class="relative cursor-pointer select-none px-4 py-2"
                :class="{
                  'bg-action': active,
                  'text-gray-900': !active
                }"
              >
                <span
                  class="block truncate"
                  :class="{ 'font-medium': selected, 'font-normal': !selected }"
                >
                  {{ place.description }}
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import { usePlacesAutocomplete } from 'vue-use-places-autocomplete'

const query = ref('')

const { suggestions } = usePlacesAutocomplete(query, {
  debounce: 500,
  minLengthAutocomplete: 3
})

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {}
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})
</script>
