<script setup>
import { computed, ref, onMounted, onUnmounted } from 'vue'
import GLightbox from 'glightbox'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {},
  name: {
    type: String,
    default: ''
  },
  canClear: {
    type: Boolean,
    default: false
  },
  cols: {
    type: String,
    default: 'md:grid-cols-2'
  },
  lightbox: {
    type: Boolean,
    default: false
  },
  options: Array
})

const gallery = ref(null)

const setActive = (option) => {
  if (model.value === option.value && props.canClear) {
    model.value = ''
  } else {
    model.value = option.value
  }
}

const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})

const openLightbox = (option) => {
  gallery.value.setElements([
    {
      href: option.image,
      alt: option.label
    }
  ])

  gallery.value.open()
}

onMounted(() => {
  gallery.value = GLightbox({
    selector: null,
    touchNavigation: true,
    loop: true,
    autoplayVideos: true
  })
})

onUnmounted(() => {
  gallery.value.destroy()
})
</script>
<template>
  <fieldset class="grid gap-4" :name="name" :class="cols">
    <div v-for="(option, k) in options" :key="k" class="relative flex flex-col items-center">
      <button
        class="absolute right-2 top-2 z-10 rounded-lg bg-white/75 p-1 backdrop-blur-sm"
        v-if="lightbox"
        type="button"
        @click="() => openLightbox(option)"
      >
        <svg class="h-6 text-primary" viewBox="0 0 21 21">
          <path
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M18.5 7.5v-5h-5m5 0l-6 5.929M7.5 18.5l-5 .023V13.5m6-1l-6 6"
          />
        </svg>
      </button>
      <button
        class="relative block w-full rounded-lg border border-white/10"
        type="button"
        @click="() => setActive(option)"
      >
        <img
          :alt="option.label"
          :src="option.image"
          class="h-44 w-full rounded-[13px] object-cover"
        />
        <label class="absolute bottom-2 left-2 z-10 text-xs text-black">Beispielfoto</label>
        <div
          class="absolute left-1/2 top-1/2 z-10 aspect-square h-[60%] -translate-x-1/2 -translate-y-1/2 rounded-full border-2 border-action"
          :class="{ 'border-white bg-white': model === option.value }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-full"
            viewBox="0 0 24 24"
            v-if="model === option.value"
          >
            <path
              fill="#000"
              d="M18.353 7.574a.5.5 0 0 0-.707-.008L9.84 15.373l-3.487-3.486a.5.5 0 0 0-.707.707l3.84 3.84a.498.498 0 0 0 .707 0l8.16-8.16a.5.5 0 0 0 0-.7"
            />
          </svg>
        </div>
      </button>

      <div>
        <label
          class="mt-2 block dark:text-white"
          :class="{
            'font-bold': !!option.description,
            'text-center text-sm': !!!option.description
          }"
        >
          {{ option.label }}
        </label>
        <p
          v-if="option.description"
          class="mt-2 text-sm text-gray-800 dark:text-gray-200"
          v-html="option.description"
        ></p>
      </div>
    </div>
  </fieldset>
</template>
