<template>
  <!-- TrustBox widget - Micro TrustScore -->
  <div
    id="trustpilot-widget-container"
    class="trustpilot-widget"
    data-locale="de-DE"
    data-template-id="5419b637fa0340045cd0c936"
    data-businessunit-id="66cc61e26557657d6b43a56b"
    data-style-height="20px"
    data-style-width="100%"
    data-theme="dark"
  >
    <a href="https://de.trustpilot.com/review/moreathome.de" target="_blank" rel="noopener"
      >Trustpilot</a
    >
  </div>
  <!-- End TrustBox widget -->
</template>

<script>
import { onMounted } from 'vue'

export default {
  name: 'TrustPilotWidget',
  setup() {
    onMounted(() => {
      if (typeof window !== 'undefined' && window.Trustpilot) {
        window.Trustpilot.loadFromElement(document.getElementById('trustpilot-widget-container'))
      }
    })
  }
}
</script>
