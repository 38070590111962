import No from '@public/images/singles/heatpump/no.svg'
import Yes from '@public/images/singles/heatpump/yes.svg'
import FlieseBudget from '@public/images/calc/bathroom/Fliese_Budget.png'
import FlieseStandard from '@public/images/calc/bathroom/Fliese_Standard.png'
import FliesePremium from '@public/images/calc/bathroom/Fliese_Premium.png'
import WanneBudget from '@public/images/calc/bathroom/Wanne_Budget.png'
import WanneStandard from '@public/images/calc/bathroom/Wanne_Standard.png'
import WannePremium from '@public/images/calc/bathroom/Wanne_Premium.png'
import DuscharmaturBudget from '@public/images/calc/bathroom/Duscharmatur_Budget.png'
import DuscharmaturStandard from '@public/images/calc/bathroom/Duscharmatur_Standard.png'
import DuscharmaturPremium from '@public/images/calc/bathroom/Duscharmatur_Premium.png'
import BadewanneBudget from '@public/images/calc/bathroom/Badewanne_Budget.png'
import BadewanneStandard from '@public/images/calc/bathroom/Badewanne_Standard.png'
import BadewannePremium from '@public/images/calc/bathroom/Badewanne_Premium.png'
import BadewannenarmaturBudget from '@public/images/calc/bathroom/Badewannenarmatur_Budget.png'
import BadewannenarmaturStandard from '@public/images/calc/bathroom/Badewannenarmatur_Standard.png'
import BadewannenarmaturPremium from '@public/images/calc/bathroom/Badewannenarmatur_Premium.png'
import ToiletteBudget from '@public/images/calc/bathroom/Toilette_Budget.png'
import ToiletteStandard from '@public/images/calc/bathroom/Toilette_Standard.png'
import ToilettePremium from '@public/images/calc/bathroom/Toilette_Premium.png'
import WaschtischBudget from '@public/images/calc/bathroom/Waschtisch_Budget.png'
import WaschtischStandard from '@public/images/calc/bathroom/Waschtisch_Standard.png'
import WaschtischPremium from '@public/images/calc/bathroom/Waschtisch_Premium.png'
import DoppelwaschtischBudget from '@public/images/calc/bathroom/Doppelwaschtisch_Budget.png'
import DoppelwaschtischStandard from '@public/images/calc/bathroom/Doppelwaschtisch_Standard.png'
import DoppelwaschtischPremium from '@public/images/calc/bathroom/Doppelwaschtisch_Premium.png'
import WaschtischarmaturBudget from '@public/images/calc/bathroom/Waschtischarmatur_Budget.png'
import WaschtischarmaturStandard from '@public/images/calc/bathroom/Waschtischarmatur_Standard.png'
import WaschtischarmaturPremium from '@public/images/calc/bathroom/Waschtischarmatur_Premium.png'

export const configTemplate = {
  floor: {
    enabled: null,
    quality: ''
  },
  walls: {
    enabled: null,
    quality: 'standard',
    size: 0
  },
  shower: {
    enabled: null,
    tub: {
      quality: 'standard'
    },
    handle: {
      quality: 'standard'
    },
    modification: {
      partitionNoDoor: false,
      partitionWithDoor: false,
      onlyDoor: false,
      compartment: false
    }
  },
  bathtub: {
    enabled: false,
    tub: {
      quality: 'standard'
    },
    handle: {
      quality: 'standard'
    },
    glasPartition: false
  },
  toilet: {
    enabled: false,
    quality: 'standard'
  },
  sink: {
    enabled: false,
    type: 'single',
    tub: {
      quality: 'standard'
    },
    handle: {
      quality: 'standard'
    },
    baseCabinet: false
  }
}

export const floorTypeOptions = [
  {
    label: 'Fliesen Budget',
    image: FlieseBudget,
    value: 'budget'
  },
  {
    label: 'Fliesen Standard',
    image: FlieseStandard,
    value: 'standard'
  },
  {
    label: 'Fliesen Premium',
    image: FliesePremium,
    value: 'premium'
  }
]

export const tubOptions = [
  {
    label: 'Wanne Budget',
    image: WanneBudget,
    value: 'budget'
  },
  {
    label: 'Wanne Standard',
    image: WanneStandard,
    value: 'standard'
  },
  {
    label: 'Wanne Premium',
    image: WannePremium,
    value: 'premium'
  }
]

export const handleOptions = [
  {
    label: 'Duscharmatur Budget',
    image: DuscharmaturBudget,
    value: 'budget'
  },
  {
    label: 'Duscharmatur Standard',
    image: DuscharmaturStandard,
    value: 'standard'
  },
  {
    label: 'Duscharmatur Premium',
    image: DuscharmaturPremium,
    value: 'premium'
  }
]

export const bathtubOptions = [
  {
    label: 'Badewanne Budget',
    image: BadewanneBudget,
    value: 'budget'
  },
  {
    label: 'Badewanne Standard',
    image: BadewanneStandard,
    value: 'standard'
  },
  {
    label: 'Badewanne Premium',
    image: BadewannePremium,
    value: 'premium'
  }
]

export const bathHandleOptions = [
  {
    label: 'Badewannenarmatur Budget',
    image: BadewannenarmaturBudget,
    value: 'budget'
  },
  {
    label: 'Badewannenarmatur Standard',
    image: BadewannenarmaturStandard,
    value: 'standard'
  },
  {
    label: 'Badewannenarmatur Premium',
    image: BadewannenarmaturPremium,
    value: 'premium'
  }
]

export const toiletOptions = [
  {
    label: 'Toilette Budget',
    image: ToiletteBudget,
    value: 'budget'
  },
  {
    label: 'Toilette Standard',
    image: ToiletteStandard,
    value: 'standard'
  },
  {
    label: 'Toilette Premium',
    image: ToilettePremium,
    value: 'premium'
  }
]

export const sinkTubOptions = {
  single: [
    {
      label: 'Waschtisch Budget',
      image: WaschtischBudget,
      value: 'budget'
    },
    {
      label: 'Waschtisch Standard',
      image: WaschtischStandard,
      value: 'standard'
    },
    {
      label: 'Waschtisch Premium',
      image: WaschtischPremium,
      value: 'premium'
    }
  ],
  double: [
    {
      label: 'Doppelwaschtisch Budget',
      image: DoppelwaschtischBudget,
      value: 'budget'
    },
    {
      label: 'Doppelwaschtisch Standard',
      image: DoppelwaschtischStandard,
      value: 'standard'
    },
    {
      label: 'Doppelwaschtisch Premium',
      image: DoppelwaschtischPremium,
      value: 'premium'
    }
  ]
}

export const sinkHandleOptions = {
  single: [
    {
      label: 'Waschtischarmatur Budget',
      image: WaschtischarmaturBudget,
      value: 'budget'
    },
    {
      label: 'Waschtischarmatur Standard',
      image: WaschtischarmaturStandard,
      value: 'standard'
    },
    {
      label: 'Waschtischarmatur Premium',
      image: WaschtischarmaturPremium,
      value: 'premium'
    }
  ],
  double: [
    {
      label: 'Waschtischarmatur Budget',
      image: WaschtischarmaturBudget,
      value: 'budget'
    },
    {
      label: 'Waschtischarmatur Standard',
      image: WaschtischarmaturStandard,
      value: 'standard'
    },
    {
      label: 'Waschtischarmatur Premium',
      image: WaschtischarmaturPremium,
      value: 'premium'
    }
  ]
}

export const sinkOptions = [
  {
    label: 'Einzelwaschbecken',
    value: 'single'
  },
  {
    label: 'Doppelwaschbecken',
    value: 'double'
  }
]
