<script setup>
import { computed } from 'vue'
import SidebarMenu from '@/components/Navigation/LeftSide/SidebarMenu.vue'
import statusClasses from '@/components/Navigation/LeftSide/statusClasses.js'

import validations from '@/data/validations'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const setCurrentService = (index) => {
  model.value.services[index].enabled = true
  model.value.step = 'services.' + index
}

const setService = () => {
  model.value.step = 'services'
}

const steps = computed(() => {
  return Object.entries(model.value.services)
    .map(([key, service]) => {
      const validate = (service, key) => {
        if (model.value.step === 'services.' + key) {
          return statusClasses.current
        }

        if (typeof validations[key] !== 'undefined') {
          const val = validations[key].safeParse(service)

          if (val.success) {
            return statusClasses.valid
          } else {
            return statusClasses.invalid
          }
        }

        return statusClasses.empty
      }

      return {
        key: key,
        title: service.title,
        enabled: service.enabled,
        valid_class: validate(service, key),
        action: function (index) {
          model.value.step = 'services.' + index
          setCurrentService(index)
        }
      }
    })
    .filter((s) => s.enabled)
})
</script>

<template>
  <SidebarMenu :action="setService" title="Handwerk" :steps="steps"></SidebarMenu>
</template>
