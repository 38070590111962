<script setup>
import { computed, onMounted } from 'vue'
import AmountInput from './AmountInput.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: {},
  options: Array
})

const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})

onMounted(() => {
  if (props.modelValue.length === 0) {
    model.value = props.options
  }
})
</script>
<template>
  <div class="grid gap-4 sm:grid-cols-2">
    <div v-for="(option, k) in model" :key="k" class="flex flex-row items-center gap-x-3">
      <div class="w-3/5">
        <img :alt="option.title" :src="option.image" class="h-44 w-full rounded-[13px]" />
        <label class="mt-2 block text-center text-sm text-white">{{ option.label }}</label>
      </div>
      <div class="w-2/5 shrink grow-0 pb-16">
        <label class="my-1 block px-3 text-center text-action"> Anzahl</label>
        <AmountInput v-model="option.amount" format="0" />
      </div>
    </div>
  </div>
</template>
