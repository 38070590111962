export const renew = `<p>Kunststofffenster und Holzfenster sind zwei weit verbreitete Varianten von Fensterrahmen, die jeweils ihre eigenen Vorz&uuml;ge und Eigenschaften haben. Im Folgenden werden die Unterschiede, Nutzen und Besonderheiten beider Typen er&ouml;rtert, sowie die Gr&uuml;nde f&uuml;r die Aufbereitung von alten Holzfenstern dargelegt.</p>
<p><strong>Kunststofffenster</strong>:</p>
<ul>
<li><strong>Material</strong>: Kunststofffenster bestehen aus Polyvinylchlorid (PVC), einem robusten und langlebigen Kunststoff.</li>
</ul>
<ul>
<li><strong>Wartung</strong>: Diese Fenster sind sehr pflegeleicht, da PVC witterungsbest&auml;ndig ist und nicht gestrichen oder nachbehandelt werden muss.</li>
</ul>
<ul>
<li><strong>Isolierung</strong>: Kunststofffenster verf&uuml;gen &uuml;ber gute W&auml;rme- und Schallisolationseigenschaften. Oftmals sind sie mit Mehrkammer-Systemen ausgestattet, die die Isolierungseffizienz erh&ouml;hen.</li>
</ul>
<ul>
<li><strong>Preis</strong>: Im Vergleich zu Holzfenstern sind Kunststofffenster in der Regel kosteng&uuml;nstiger in der Anschaffung.</li>
</ul>
<ul>
<li><strong>&Ouml;kologie</strong>: Wenngleich PVC recycelbar ist, steht die Herstellung und Entsorgung von Kunststofffenstern aus &ouml;kologischer Sicht oft in der Kritik, da PVC nicht biologisch abbaubar ist und bei der Verbrennung umweltsch&auml;dliche Stoffe freisetzen kann.</li>
</ul>
<p><strong>Holzfenster</strong>:</p>
<ul>
<li><strong>Material</strong>: Holzfenster werden aus verschiedenen Holzarten wie Kiefer, L&auml;rche, Eiche oder Tropenh&ouml;lzern hergestellt. Holz ist ein nat&uuml;rlich nachwachsender Rohstoff.</li>
</ul>
<ul>
<li><strong>Wartung</strong>: Im Vergleich zu Kunststofffenstern ben&ouml;tigen Holzfenster mehr Pflege. Sie sollten regelm&auml;&szlig;ig kontrolliert, gereinigt und alle paar Jahre mit Lack oder Lasur behandelt werden, um sie vor Witterungseinfl&uuml;ssen zu sch&uuml;tzen.</li>
</ul>
<ul>
<li><strong>Isolierung</strong>: Holz hat nat&uuml;rliche isolierende Eigenschaften und kann zur Energieeffizienz des Hauses beitragen. Hochwertige Holzfenster bieten eine sehr gute W&auml;rme- und Schalld&auml;mmung.</li>
</ul>
<ul>
<li><strong>Preis</strong>: Holzfenster sind in der Regel teurer als Kunststofffenster, vor allem wenn es sich um hochwertiges Holz, Sonderanfertigungen oder spezielle Verglasungen handelt.</li>
</ul>
<ul>
<li><strong>&Ouml;kologie</strong>: Holz ist ein &ouml;kologisch vorteilhafter Baustoff, da es CO2 bindet und bei nachhaltiger Forstwirtschaft als Ressource dauerhaft zur Verf&uuml;gung stehen kann.</li>
</ul>
<p><strong>Aufbereitung von alten Holzfenstern</strong>: Die Aufbereitung von alten Holzfenstern kann aus mehreren Gr&uuml;nden sinnvoll sein:</p>
<ul>
<li><strong>Nachhaltigkeit</strong>: Die Aufarbeitung tr&auml;gt zur Schonung von Ressourcen bei, indem bestehende Materialien genutzt statt neue produziert werden.</li>
</ul>
<ul>
<li><strong>&Auml;sthetik</strong>: Alte Holzfenster, insbesondere in historischen Geb&auml;uden, haben oft eine einzigartige Optik und tragen zum Charme eines Hauses bei. Eine fachgerechte Aufarbeitung kann diese Merkmale erhalten und gleichzeitig die Funktionalit&auml;t verbessern.</li>
</ul>
<ul>
<li><strong>Kosteneffizienz</strong>: In manchen F&auml;llen kann die Restaurierung kosteng&uuml;nstiger als der komplette Austausch der Fenster sein, insbesondere wenn die Bausubstanz durch den Austausch beeintr&auml;chtigt werden w&uuml;rde.</li>
</ul>
<ul>
<li><strong>Energieeffizienz</strong>: Durch die Aufbereitung k&ouml;nnen alte Fenster mit modernen Dichtungen und Isolierverglasung nachger&uuml;stet werden, ohne dass die gesamte Fensteranlage ausgetauscht werden muss, was die Energieeffizienz des Hauses verbessert.</li>
</ul>
<p>Zusammengefasst k&ouml;nnen sowohl Kunststoff- als auch Holzfenster je nach den individuellen Bed&uuml;rfnissen und Werten des Hausbesitzers passende L&ouml;sungen bieten. W&auml;hrend Kunststofffenster vor allem durch ihre Kosten- und Wartungsvorteile punkten, bieten Holzfenster neben &auml;sthetischen Vorteilen auch eine &ouml;kologisch nachhaltige Alternative. Die Aufbereitung von alten Holzfenstern bietet nicht nur einen Beitrag zum Umweltschutz, sondern erh&auml;lt auch den Charakter &auml;lterer Geb&auml;ude, w&auml;hrend sie gleichzeitig die Energieffizienz verbessert.</p>`

export const renewReveals = `<p>Ein Fenstertausch ist mehr als nur das einfache Ersetzen einer Altverglasung durch ein neues Fenster. Nach dem Einbau des neuen Fensters ist es auch wichtig, die Fensterleibungen, also die Wandbereiche, die das Fenster umgeben, entsprechend nachzuarbeiten. Hier sind einige Gr&uuml;nde, warum die Nacharbeit der Fensterleibungen eine notwendige Ma&szlig;nahme beim Fenstertausch ist:</p>
<ol>
<li><strong>D&auml;mmung verbessern:</strong> Neue Fenster haben oft bessere D&auml;mmwerte als alte Fenster. Um die energetische Effizienz des Geb&auml;udes zu maximieren, muss sichergestellt werden, dass auch die Fensterleibungen eine ad&auml;quate D&auml;mmung aufweisen und keine W&auml;rmebr&uuml;cken bestehen. Eine nachtr&auml;gliche D&auml;mmung der Leibungen kann helfen, Heizkosten zu sparen und den Wohnkomfort zu erh&ouml;hen.</li>
<li><strong>Luftdichtigkeit sicherstellen:</strong> Die Luftdichtigkeit ist f&uuml;r die Energieeffizienz und das Raumklima entscheidend. Undichte Fensterleibungen k&ouml;nnen zu Zugluft und erh&ouml;hten Heizkosten f&uuml;hren. Im Rahmen der Nacharbeit werden daher auch die Fugen zwischen Fensterrahmen und Mauerwerk abgedichtet, um Luftstr&ouml;mungen zu verhindern.</li>
<li><strong>Schallschutz erh&ouml;hen:</strong> Moderne Fenster sind oft auch im Hinblick auf besseren Schallschutz konzipiert. Durch die Anpassung der Leibungen kann der Schallschutz zus&auml;tzlich gesteigert werden. In L&auml;rm belasteten Gebieten tr&auml;gt dies erheblich zur Wohnqualit&auml;t bei.</li>
<li><strong>Feuchtigkeitssch&auml;den vermeiden:</strong> Alte Fensterleibungen k&ouml;nnen Risse oder andere Besch&auml;digungen aufweisen, die zu Feuchtigkeitseindringungen f&uuml;hren k&ouml;nnen. Die &Uuml;berarbeitung der Leibungen schlie&szlig;t diese Schwachstellen und sch&uuml;tzt so besser vor Feuchtigkeit und damit verbundenen Sch&auml;den wie Schimmelbildung.</li>
<li><strong>Optisches Erscheinungsbild verbessern:</strong> Neue Fenster k&ouml;nnen eine andere Gr&ouml;&szlig;e oder Tiefe haben als die alten. Um ein harmonisches Gesamtbild zu erhalten, m&uuml;ssen die Leibungen angepasst werden. Gleichzeitig kann durch Verputzen, Streichen oder andere Verkleidungen ein ansprechendes Finish erzielt werden.</li>
<li><strong>Bauliche Anforderungen erf&uuml;llen:</strong> Bauvorschriften, insbesondere die Energieeinsparverordnung (EnEV), stellen Anforderungen an den Fenstereinbau und die zugeh&ouml;rigen Leibungen. Die Nacharbeit der Leibungen ist somit oft auch gesetzlich vorgeschrieben, um den Mindeststandards f&uuml;r W&auml;rmed&auml;mmung und Energieeffizienz zu entsprechen.</li>
<li><strong>Strukturelle Integrit&auml;t wahren:</strong> Beim Ausbau alter Fenster k&ouml;nnen Sch&auml;den an der Bausubstanz rund um das Fenster offenbart werden. Das Nacharbeiten der Fensterleibungen bietet die Gelegenheit, diese Sch&auml;den zu reparieren und die strukturelle Integrit&auml;t des Geb&auml;udes aufrechtzuerhalten.</li>
</ol>
<p>Zusammenfassend ist die Nacharbeit der Fensterleibungen beim Fenstertausch ein wichtiger Schritt, um die energetische Effizienz, die Luftdichtigkeit, den Schallschutz, den Feuchteschutz, das &auml;sthetische Aussehen und die strukturelle Integrit&auml;t des Geb&auml;udes zu gew&auml;hrleisten. Ein Fachbetrieb kann diese Arbeiten professionell durchf&uuml;hren und sicherstellen, dass die neuen Fenster optimal in das Bauwerk integriert werden.</p>`

export const renewSills = `<p>Ein Tausch der Fensterb&auml;nke, sowohl innen als auch au&szlig;en, kann aus &auml;sthetischen Gr&uuml;nden oder aufgrund von Verschlei&szlig; und Sch&auml;den n&ouml;tig sein. Es gibt einige wichtige Aspekte, die beachtet werden sollten, um sicherzustellen, dass der Austausch effizient und sicher durchgef&uuml;hrt wird.</p>
<p><strong>Vorbereitung und Auswahl des Materials:</strong></p>
<ol>
<li><strong>Materialwahl:</strong> F&uuml;r Innenfensterb&auml;nke sind Materialien wie Holz, Stein oder Kunststoff &uuml;blich, w&auml;hrend f&uuml;r Au&szlig;enfensterb&auml;nke wetterbest&auml;ndige Materialien wie Aluminium, Stein oder spezielle Kunststoffe verwendet werden.</li>
<li><strong>Ma&szlig;e nehmen:</strong> Genauigkeit ist wichtig beim Messen der bestehenden Fensterbank, um sicherzustellen, dass die neue Fensterbank exakt passt.</li>
<li><strong>Design und Farbe:</strong> W&auml;hlen Sie ein Design und eine Farbe, die zum bestehenden Interieur bzw. Exterieur des Hauses passen.</li>
<li><strong>Werkzeuge und Materialien:</strong> Stellen Sie alle ben&ouml;tigten Werkzeuge und Materialien wie Dichtstoffe, Schrauben, D&uuml;bel, S&auml;ge, Bohrmaschine usw. bereit.</li>
</ol>
<p><strong>Demontage der alten Fensterb&auml;nke:</strong></p>
<ol>
<li><strong>Schutz der Umgebung:</strong> Decken Sie M&ouml;bel und Boden ab, um sie vor Staub und Schmutz zu sch&uuml;tzen.</li>
<li><strong>Entfernung:</strong> Bauen Sie die alte Fensterbank vorsichtig aus, um Sch&auml;den am Fensterrahmen oder der Wand zu vermeiden. M&ouml;glicherweise m&uuml;ssen Sie Dichtstoffe, Schrauben oder N&auml;gel entfernen.</li>
<li><strong>Reinigung:</strong> Nach der Demontage sollten Sie die Auflagefl&auml;chen gr&uuml;ndlich reinigen.</li>
</ol>
<p><strong>Installation der neuen Fensterb&auml;nke:</strong></p>
<ol>
<li><strong>Trockenprobe:</strong> Legen Sie die neue Fensterbank ein, um sicherzustellen, dass sie passt, bevor Sie sie endg&uuml;ltig befestigen.</li>
<li><strong>Ausrichtung:</strong> &Uuml;berpr&uuml;fen Sie die Waagerechte der Fensterbank mit einer Wasserwaage. F&uuml;r Au&szlig;enfensterb&auml;nke ist ein leichtes Gef&auml;lle nach au&szlig;en wichtig, damit Regenwasser ablaufen kann.</li>
<li><strong>Befestigung:</strong> Verwenden Sie D&uuml;bel und Schrauben, um die Fensterbank zu befestigen. Achten Sie darauf, dass sie fest und sicher sitzt.</li>
<li><strong>Abdichtung:</strong> Versiegeln Sie die Fugen zwischen Fensterbank und Wand mit Silikon oder Acryl, um das Eindringen von Feuchtigkeit zu vermeiden.</li>
</ol>
<p><strong>Nachbereitung:</strong></p>
<ol>
<li><strong>&Uuml;berpr&uuml;fung:</strong> Stellen Sie sicher, dass alles korrekt installiert ist und keine sichtbaren L&uuml;cken oder Sch&auml;den zu erkennen sind.</li>
<li><strong>Reinigung:</strong> Entfernen Sie s&auml;mtlichen Schmutz und Bauschutt, der w&auml;hrend der Installation entstanden ist.</li>
</ol>
<p><strong>Sicherheit und Nachhaltigkeit:</strong></p>
<ol>
<li><strong>Sicherheit:</strong> Beachten Sie alle Sicherheitshinweise und tragen Sie geeignete Schutzkleidung.</li>
<li><strong>Entsorgung:</strong> Alte Fensterb&auml;nke sollten umweltgerecht entsorgt oder recycelt werden.</li>
</ol>
<p><strong>Hinweis:</strong> Insbesondere bei &auml;lteren Geb&auml;uden oder bei Fenstern, die nicht dem Standard entsprechen, kann es notwendig sein, eine individuelle Anpassung vor Ort durchzuf&uuml;hren. Zudem sollte bei nicht tragf&auml;higen W&auml;nden oder Mauerwerk ein Fachmann hinzugezogen werden, um Sch&auml;den an der Bausubstanz zu vermeiden.</p>
<p>Der Austausch der Fensterb&auml;nke kann eine deutliche optische Aufwertung f&uuml;r das Geb&auml;ude bedeuten und bei korrekter Ausf&uuml;hrung die Energieeffizienz und Wetterbest&auml;ndigkeit verbessern. Eine sorgf&auml;ltige Planung und Ausf&uuml;hrung sind entscheidend f&uuml;r das Gelingen des Vorhabens.</p>`

export default {
  renew,
  renewReveals,
  renewSills
}
