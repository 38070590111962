import { getImageUrl } from '@/utils/image'

export const windowTypeOptions = [
  { label: 'Fenster erneuern', value: 'renew' },
  { label: 'Fenster aufbereiten', value: 'improve' }
]

export const windowMaterialOptions = [
  { label: 'Holz', value: 'wood' },
  { label: 'Kunststoff', value: 'plastic' }
]

export const windowSillsOptions = [
  { label: 'innen', value: 'inner' },
  { label: 'außen', value: 'outer' },
  { label: 'innen und außen', value: 'both' }
]

export const windowBuiltTypesOptions = [
  { label: 'normales Fenster', value: 'normal' },
  { label: 'Altbaufenster', value: 'old' },
  { label: 'Sprossenfenster', value: 'muntin' },
  { label: 'mit Rolladen', value: 'shutter' }
]

export const windowTypesOptions = [
  {
    label: 'Fenster einfach',
    value: 'singleLeaf',
    image: getImageUrl('/images/calc/windows/einfluegliges_fenster.svg')
  },
  {
    label: 'Fenster doppelt',
    value: 'doubleLeaf',
    image: getImageUrl('/images/calc/windows/zweifluegliges_fenster.svg')
  },
  {
    label: 'Balkontür',
    value: 'balconyTerraceDoor',
    image: getImageUrl('/images/calc/windows/balkontuer.svg')
  },
  {
    label: 'großes Fenster',
    value: 'largeFixed',
    image: getImageUrl('/images/calc/windows/grosses_feststehendes_fenster.svg')
  },
  {
    label: 'Schiebeelement',
    value: 'tiltTurnSlide',
    image: getImageUrl('/images/calc/windows/kippdrehschiebeelement.svg')
  },
  {
    label: 'kleines Fenster ',
    value: 'small',
    image: getImageUrl('/images/calc/windows/kleines_fenster.png')
  }
]
