<script setup>
import { computed, onMounted, ref } from 'vue'
import Panel from '@/components/Elements/Panel.vue'
import Switch from '@/components/Inputs/Switch.vue'
import { scrollToSelector } from '@/utils/scroll'
import track from '@/utils/tracking'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const setCurrentService = (index) => {
  model.value.services[index].enabled = true
  model.value.step = 'services.' + index

  if (window.innerWidth < 1024) {
    scrollToSelector('#right')
  }
}

const setService = (index, service, value) => {
  if (value) {
    setCurrentService(index)
  } else {
    model.value.step = 'services'
  }
}

onMounted(() => {
  track.yandex(97558574, 'reachGoal', 'services')
  track.yandex(97558944, 'reachGoal', 'services')
})
</script>

<template>
  <Panel title="Handwerk" subtitle="Wählen Sie die Leistungen für Ihre Sanierung">
    <ul>
      <template v-for="(service, k) in model.services">
        <li
          class="flex flex-row items-center border-b py-3 last:border-b-0 dark:border-white"
          v-if="service.forTypes.includes(model.base.type)"
          :class="k === 'contractor' ? 'mb-5' : ''"
        >
          <div class="mr-5 block shrink-0">
            <Switch
              v-model="service.enabled"
              @update:model-value="(v) => setService(k, service, v)"
            />
          </div>
          <h4
            class="cursor-pointer text-xl font-normal hover:text-action-700 dark:hover:text-action"
            :class="{
              'font-semibold text-action-700 dark:text-action': modelValue.step === 'services.' + k,
              'dark:text-white': modelValue.step !== 'services.' + k
            }"
            @click.prevent="() => setCurrentService(k)"
          >
            {{ service.title }}
          </h4>
        </li>
      </template>
    </ul>
  </Panel>
</template>
