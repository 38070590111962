<script setup>
import { computed, onMounted } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Quality from '@/components/Inputs/Quality.vue'
import ImageOptionsSelection from '../Inputs/ImageOptionsSelection.vue'
import Headline from '../Elements/Headline.vue'
import RoomsSelection from '../Inputs/RoomsSelection.vue'
import RoomsTabs from '../Inputs/RoomsTabs.vue'

import { floorTypeOptions } from '@/data/options/floor'
import infos from '@/data/infos/services/floor'
import AlertInfo from '../Elements/AlertInfo.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentRoomType = computed({
  get: () => {
    if (!model.value.change.currentRoom) {
      return model.value.change.type
    }
    return model.value.change.roomConfigs[model.value.change.currentRoom].type
  },
  set: (v) => {
    if (!model.value.change.currentRoom) {
      model.value.change.type = v
    } else {
      model.value.change.roomConfigs[model.value.change.currentRoom].type = v
    }
  }
})

const changeRooms = computed(() => {
  return props.form.rooms
    .filter((i) => i.enabled && !['bathroom', 'guest_wc'].includes(i.type))
    .map((room) => {
      const autoValue = model.value.change.type ? 'autoset' : 'unset'

      return {
        ...room,
        indicator: model.value.change.roomConfigs[room.id]?.type ? 'set' : autoValue
      }
    })
})

onMounted(() => {
  props.form.rooms
    .filter((i) => i.enabled && !['bathroom', 'guest_wc'].includes(i.type))
    .forEach((room) => {
      if (!model.value.change.roomConfigs[room.id]) {
        model.value.change.roomConfigs[room.id] = {
          type: ''
        }
      }
    })
})
</script>

<template>
  <div>
    <FormGroup
      info-title="Böden"
      :info="infos.renew"
      label="Soll neuer Boden verlegt werden?"
      name="change"
    >
      <template #right>
        <Switch v-model="model.change.enabled" />
      </template>
      <template v-if="model.change.enabled">
        <RoomsTabs
          class="mt-4"
          v-model="model.change.currentRoom"
          :rooms="changeRooms"
          :indicator="model.change.type ? 'set' : 'unset'"
        />
        <AlertInfo v-if="!model.change.currentRoom" class="mt-2">
          Bitte wählen Sie einen Raum aus
        </AlertInfo>
        <ImageOptionsSelection
          v-if="model.change.currentRoom"
          class="mt-4"
          name="type"
          can-clear
          lightbox
          v-model="currentRoomType"
          :options="floorTypeOptions"
        />
        <Headline title="Qualität" subtitle="des Bodenbelages"></Headline>
        <Quality v-model="model.change.quality" />
      </template>
    </FormGroup>

    <FormGroup
      info-title="Aufbereitung von bestehendem Holzfußboden"
      :info="infos.wood"
      label="Soll bestehender Holzfußboden aufbereitet werden?"
      name="recycleWoodenFloor"
    >
      <template #right>
        <Switch v-model="model.recycleWoodenFloor.enabled" />
      </template>

      <RoomsSelection
        class="mt-4"
        v-if="model.recycleWoodenFloor.enabled"
        v-model="model.recycleWoodenFloor.rooms"
        :rooms="form.rooms"
      />
    </FormGroup>

    <FormGroup
      info-title="Entfernung von bestehenden Fußbodenbelägen"
      :info="infos.remove"
      label="Soll bestehender Fußboden entfernt werden?"
      name="removeOld"
    >
      <template #right>
        <Switch v-model="model.removeOld.enabled" />
      </template>
      <RoomsSelection
        :all="false"
        class="mt-4"
        v-if="model.removeOld.enabled"
        v-model="model.removeOld.rooms"
        :rooms="form.rooms"
      />
    </FormGroup>

    <FormGroup
      info-title="Aufbereitung von Fußleisten"
      :info="infos.sockel"
      label="Wollen Sie neue Fußleisten?"
      name="newSkirtingBoards"
    >
      <template #right>
        <Switch v-model="model.newSkirtingBoards.enabled" />
      </template>

      <template v-if="model.newSkirtingBoards.enabled">
        <RoomsSelection class="mt-4" v-model="model.newSkirtingBoards.rooms" :rooms="form.rooms" />
        <Headline title="Qualität"></Headline>
        <Quality class="mt-10" v-model="model.newSkirtingBoards.quality" />
      </template>
    </FormGroup>

    <FormGroup
      info-title="Aufbereitung von Fußleisten"
      :info="infos.wood"
      label="Sollen bestehende Fußleisten aufbereitet und lackiert werden?"
      name="recycleSkirtingBoards"
    >
      <template #right>
        <Switch v-model="model.recycleSkirtingBoards.enabled" />
      </template>

      <RoomsSelection
        class="mt-4"
        v-if="model.recycleSkirtingBoards.enabled"
        v-model="model.recycleSkirtingBoards.rooms"
        :rooms="form.rooms"
      />
    </FormGroup>
  </div>
</template>
