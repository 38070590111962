export const renew = `<p>Wenn es um den Kauf einer neuen Zimmert&uuml;r geht, gibt es verschiedene Qualit&auml;tsstufen, die sich in Material, Verarbeitung, Design und Preis unterscheiden. Im Folgenden werden die drei generellen Kategorien &ndash; Budget-Qualit&auml;t, Standard-Qualit&auml;t und Premium-Qualit&auml;t &ndash; beschrieben.</p>
<ol>
<li><strong>Budget-Qualit&auml;t</strong>: Zimmert&uuml;ren, die als Budget-Qualit&auml;t klassifiziert sind, bieten die grundlegendsten Eigenschaften, die f&uuml;r eine funktionelle T&uuml;r notwendig sind. In dieser Kategorie stehen Kostenersparnis und grundlegende Funktionalit&auml;t im Vordergrund. Materialien wie einfache Holzwerkstoffe oder furnierte T&uuml;ren sind &uuml;blich, wobei oft auf Details wie massive Rahmen oder aufwendige Verriegelungssysteme verzichtet wird. Der Fokus liegt eher auf einer schlichten Optik und einer einfachen Montage. Die Oberfl&auml;chen k&ouml;nnen anf&auml;lliger f&uuml;r Kratzer oder Besch&auml;digungen sein, und die Haltbarkeit ist im Vergleich zu h&ouml;herwertigen T&uuml;ren oftmals geringer. Dichtungen und Schallschutzeigenschaften k&ouml;nnen ebenso einfacher ausgef&uuml;hrt sein.</li>
<li><strong>Standard-Qualit&auml;t</strong>: Bei T&uuml;ren der Standard-Qualit&auml;t kann man von einem ausgeglichenen Verh&auml;ltnis zwischen Preis und Leistung ausgehen. Hierbei kommen meist bessere Materialien zum Einsatz, wie beispielsweise hochwertigere Holzwerkstoffe oder Massivholzelemente. Die Verarbeitung ist sorgf&auml;ltiger, und es gibt oft eine gr&ouml;&szlig;ere Auswahl an Designs. Mittelklasse-T&uuml;ren k&ouml;nnen Merkmale aufweisen wie stabilere Rahmen, bessere D&auml;mmungseigenschaften und Strapazierf&auml;higkeit. Auch die Beschl&auml;ge und Scharniere sind in der Regel von besserer Qualit&auml;t, was die Nutzungsdauer und -komfort erh&ouml;ht. Zudem haben T&uuml;ren dieser Qualit&auml;t oft eine bessere Oberfl&auml;chenbehandlung und sind damit resistenter gegen&uuml;ber Abnutzung und allt&auml;glichen Beanspruchungen.</li>
<li><strong>Premium-Qualit&auml;t</strong>: Premium-Zimmert&uuml;ren repr&auml;sentieren das h&ouml;chste Qualit&auml;tssegment, bei welchem Material, Verarbeitung, Design und Technologie auf dem neuesten Stand sind. Hier werden oft massive H&ouml;lzer, hochwertige Furniere oder innovative Verbundmaterialien verwendet. Eine exzellente Verarbeitung, zum Beispiel durch rahmenb&uuml;ndige Verarbeitung oder versteckte B&auml;nder, zeichnet diese T&uuml;ren aus. Sie bieten h&auml;ufig zus&auml;tzliche Eigenschaften wie erh&ouml;hten Einbruchschutz, ausgezeichnete Schallschutz- und Isolationseigenschaften sowie eine erstklassige Oberfl&auml;chenveredelung, die sie besonders widerstandsf&auml;hig gegen&uuml;ber Umwelteinfl&uuml;ssen macht. Designoptionen in dieser Kategorie sind oft vielf&auml;ltig und k&ouml;nnen individuelle Anpassungen f&uuml;r Kunden umfassen. Premium-T&uuml;ren sind f&uuml;r ihre Langlebigkeit und ihren hohen Komfort bekannt und stellen daher eine Investition dar, die auch langfristig ihren Wert beh&auml;lt.</li>
</ol>
<p>W&auml;hrend Budget-T&uuml;ren f&uuml;r tempor&auml;re L&ouml;sungen oder Renovierungen mit begrenztem Budget passend sein k&ouml;nnen, sind T&uuml;ren der Standard-Qualit&auml;t f&uuml;r die meisten Wohnsituationen geeignet. Premium-T&uuml;ren allerdings sind f&uuml;r jene geeignet, die Wert auf eine hochwertige Innengestaltung, besondere Funktionalit&auml;ten und Langlebigkeit legen.</p>`

export const entranceDoor = `<p>Wenn Sie eine neue Eingangst&uuml;r f&uuml;r Ihr Heim ausw&auml;hlen, sto&szlig;en Sie auf ein breites Spektrum an Qualit&auml;tsstufen und Preisbereichen. Im Folgenden finden Sie eine detaillierte Beschreibung der unterschiedlichen Qualit&auml;tsstufen bei Eingangst&uuml;ren: Budget-Qualit&auml;t, Standard-Qualit&auml;t und Premium-Qualit&auml;t.</p>
<ol>
<li><strong>Budget-Qualit&auml;t</strong>: Eingangst&uuml;ren im Budget-Bereich sind die wirtschaftlichste Wahl. Sie sind typischerweise aus kosteng&uuml;nstigen Materialien gefertigt, wie zum Beispiel aus einfacherem Holz, Kunststoff oder einer zusammengesetzten Faserplatte. Diese T&uuml;ren k&ouml;nnen weniger robust sein und bieten nicht dieselbe Langlebigkeit und Sicherheit wie h&ouml;herwertige Modelle. Sie k&ouml;nnten auch eine schlechtere W&auml;rme- und Schalld&auml;mmung aufweisen und sind eventuell nicht optimal gegen Witterungseinfl&uuml;sse oder Einbruchsversuche gesch&uuml;tzt. Budget-T&uuml;ren haben h&auml;ufig eine begrenzte Auswahl an Designs und Farben, und die Verarbeitung kann einfacher sein. Die Garantiezeiten sind meist k&uuml;rzer als bei h&ouml;herwertigen T&uuml;ren.</li>
</ol>
<ol>
<li><strong>Standard-Qualit&auml;t</strong>: Die Standard-Qualit&auml;t stellt den Mittelweg dar und ist h&auml;ufig das Gleichgewicht zwischen Kosten und Leistung. Diese T&uuml;ren werden oft aus langlebigem Holz, verst&auml;rktem Kunststoff oder einer Metall-Konstruktion gefertigt, die eine bessere Stabilit&auml;t und Sicherheit bietet. Eingangst&uuml;ren in Standardqualit&auml;t haben zumeist bessere Isoliereigenschaften, wodurch sie Energie effizienter halten und besser vor L&auml;rm sch&uuml;tzen. Die Auswahl an Designs und Anpassungsoptionen ist breiter als bei der Budget-Kategorie, wodurch sie sich besser an den Stil Ihres Hauses anpassen lassen. Diese Modelle werden auch mit l&auml;ngeren Garantiezeiten und besseren Schlie&szlig;systemen ausgestattet sein.</li>
</ol>
<ol>
<li><strong>Premium-Qualit&auml;t</strong>: Eingangst&uuml;ren im Premium-Segment bieten das h&ouml;chste Niveau an Qualit&auml;t und Leistung. Diese sind aus hochwertigen Materialien wie massivem Holz, hochleistungsf&auml;higem Kunststoff oder robustem Metall (z.B. Aluminium) hergestellt. Die T&uuml;ren k&ouml;nnen funktionale Merkmale wie verbesserte Einbruchsicherheit, mehrfache Verriegelungssysteme, ausgezeichnete W&auml;rmed&auml;mmung und hochwertige Dichtungen aufweisen. Premium-T&uuml;ren haben auch oft ein anspruchsvolles Design, das mit einer Vielzahl von Anpassungsm&ouml;glichkeiten wie Farben, Glaseins&auml;tzen, Beschl&auml;gen und mehr angeboten wird. Sie sind auf Langlebigkeit ausgerichtet und h&auml;ufig mit einer umfangreichen Garantie ausgestattet. Zudem sind diese T&uuml;ren oft am besten in der Lage, den Energiespareffekt zu maximieren, die Sicherheit Ihres Hauses zu steigern und den Wert Ihrer Immobilie zu erh&ouml;hen.</li>
</ol>
<p>Unabh&auml;ngig von der Qualit&auml;tsstufe, die Sie w&auml;hlen, ist es wichtig, dass Sie eine T&uuml;r ausw&auml;hlen, die sowohl zu Ihrem Budget als auch zu Ihren Bed&uuml;rfnissen in Bezug auf Sicherheit, Energieeffizienz und &Auml;sthetik passt. Bedenken Sie auch, dass eine gute Installation wesentlich ist, um die beste Leistung Ihrer Eingangst&uuml;r zu gew&auml;hrleisten.</p>`

export const lack = `<p>Das Aufbereiten von bestehenden Zimmert&uuml;ren bietet sowohl unter praktischen als auch unter Nachhaltigkeitsaspekten sinnvolle Vorteile. Hier ein Informationstext, der erl&auml;utert, warum es eine &uuml;berlegenswerte Option ist:</p>
<p><strong>Aufbereitung von Zimmert&uuml;ren: Warum ist es sinnvoll?</strong></p>
<p>Zimmert&uuml;ren sind mehr als nur funktionale Elemente in Wohnr&auml;umen; sie tragen zur &Auml;sthetik des Interieurs bei und haben eine praktische Bedeutung f&uuml;r den Sicht-, Schall- sowie W&auml;rmeschutz. Mit der Zeit k&ouml;nnen T&uuml;ren jedoch Gebrauchsspuren aufweisen, nicht mehr zeitgem&auml;&szlig; aussehen oder funktionale M&auml;ngel entwickeln. An diesem Punkt kommt die Aufbereitung als sinnvolle Option ins Spiel.</p>
<ol>
<li><strong> Kostenersparnis:</strong> Das Ersetzen von T&uuml;ren kann sehr kostspielig sein. Die Aufbereitung vorhandener T&uuml;ren hingegen ist meist deutlich g&uuml;nstiger, da lediglich Materialien f&uuml;r die Renovierung sowie, sofern nicht selbst ausgef&uuml;hrt, Handwerkerkosten f&uuml;r die Arbeit anfallen.</li>
<li><strong> Wertsteigerung des Interieurs:</strong> Aufbereitete T&uuml;ren k&ouml;nnen das gesamte Ambiente eines Raumes aufwerten. Durch neue Anstriche, das Aufarbeiten von Oberfl&auml;chen oder den Austausch von Beschl&auml;gen kann eine zeitgem&auml;&szlig;e Optik erzeugt werden, die individuelle Stilpr&auml;ferenzen widerspiegelt.</li>
<li><strong> Nachhaltigkeit:</strong> Die Aufarbeitung von T&uuml;ren ist eine umweltschonende Entscheidung. Sie reduziert die Notwendigkeit, neue Materialien zu verwenden und verringert somit den &ouml;kologischen Fu&szlig;abdruck. Auch die Abfallmenge wird minimiert, da weniger Material entsorgt wird.</li>
<li><strong> Individuelle Gestaltungsm&ouml;glichkeiten:</strong> Bei der Aufbereitung von T&uuml;ren haben Eigent&uuml;mer die Freiheit, Farben und Beschl&auml;ge nach eigenem Geschmack auszuw&auml;hlen. So l&auml;sst sich das Design ma&szlig;geschneidert an die restliche Raumgestaltung anpassen.</li>
<li><strong> Erhalt von Charakter und Charme:</strong> Alte T&uuml;ren, insbesondere in historischen Geb&auml;uden, besitzen oft eine besondere &Auml;sthetik und sind mit dem Charakter des Hauses eng verkn&uuml;pft. Die Aufbereitung erm&ouml;glicht es, diesen authentischen Charme zu bewahren und gleichzeitig Funktionalit&auml;t zu gew&auml;hrleisten.</li>
<li><strong> Zeitersparnis:</strong> Die Aufbereitung von T&uuml;ren ist in der Regel schneller abgeschlossen als ein kompletter Austausch. Es entfallen aufwendige Ma&szlig;nahmen wie das Ausmessen und Anpassen neuer T&uuml;ren, was die Renovierungszeit verk&uuml;rzt.</li>
<li><strong> Problembehebung:</strong> Oftmals sind es kleinere Probleme wie Quietschen oder verzogene T&uuml;ren, die mit einer Aufbereitung effektiv gel&ouml;st werden k&ouml;nnen. Das Nachjustieren von Beschl&auml;gen oder das Ausbessern von Rissen in der T&uuml;r kann bereits gro&szlig;e Wirkung zeigen.</li>
</ol>
<p><strong>Fazit:</strong></p>
<p>Das Aufbereiten von Zimmert&uuml;ren stellt eine &ouml;konomische und &ouml;kologische Alternative zum kompletten Austausch dar. Es bietet zudem die M&ouml;glichkeit, T&uuml;ren individualisiert und stilbewusst zu gestalten und den Charme sowie die Qualit&auml;t der T&uuml;ren zu bewahren, was langfristig den Wohnkomfort verbessert. In einer Zeit, in der Ressourcenschonung und Nachhaltigkeit immer bedeutender werden, ist die Aufbereitung von T&uuml;ren ein praktischer und zugleich verantwortungsvoller Weg, um die Lebensdauer von Bauelementen zu verl&auml;ngern.</p>`

export default {
  renew,
  entranceDoor,
  lack
}
