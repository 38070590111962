<script setup>
import { computed } from 'vue'

import { windowTypesOptions } from '@/data/options/windows'

import ConfigItem from './ConfigItem.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  service: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const getSectionTitle = (type) => {
  return windowTypesOptions.find((o) => o.value === type).label
}
</script>
<template>
  <div class="grid gap-5">
    <template v-for="(i, k) in service.change.amounts">
      <div v-if="i > 0">
        <h3 class="mb-3 block text-lg font-bold dark:text-white">{{ getSectionTitle(k) }}</h3>
        <div class="grid gap-10">
          <template v-for="(i2, k2) in model">
            <ConfigItem v-model="model[k2]" :index="k2" v-if="i2.type == k" />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>
