<script setup>
import { computed } from 'vue'

import FormGroup from '@/components/Inputs/FormGroup.vue'
import Radio from '@/components/Inputs/Radio.vue'
import Range from '@/components/Inputs/Range.vue'

import { windowBuiltTypesOptions } from '@/data/options/windows'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  index: Number,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})
</script>

<template>
  <div class="flex border-b border-gray-200">
    <span class="w-16 text-lg font-bold dark:text-white">#{{ index + 1 }}</span>
    <div class="grid grow gap-y-2 pb-10">
      <!--
      <div class="flex items-center">
        <label for="length" class="text-gray-200 text-sm w-24">Länge</label>
        <Range
          v-model="model['length']"
          name="length"
          :min="0"
          :step="0.2"
          :max="5"
          suffix="m"
          class="grow"
        />
      </div>

      <div class="flex items-center">
        <label for="length" class="text-gray-200 text-sm w-24">Breite</label>
        <Range
          suffix="m"
          v-model="model.width"
          name="width"
          :min="0"
          :step="0.2"
          :max="5"
          class="grow"
        />
      </div>
      -->
      <div>
        <label for="length" class="mb-2 block text-sm text-gray-200">Art</label>
        <Radio v-model="model.built" name="type" :options="windowBuiltTypesOptions" />
      </div>
    </div>
  </div>
</template>
