<template>
  <div class="relative">
    <div class="mx-auto mb-32 max-w-lg">
      <div class="rounded-3xl">
        <LoadingCircle v-if="loading" class="mx-auto mt-60" />
        <template v-else>
          <!--          <div id="new-form" class="hidden">
            <ContractorStep
              v-if="model.step === 'contractor' && !submitted && !submitting && !single"
              :model="model"
            />
            <ContactForm
              v-if="model.step === 'contactForm' && !submitted && !loading"
              :model="model"
              :totals="totals"
              :submitting="submitting"
              :single="single"
              @back="goBack"
            />
          </div>-->
          <ContactFormLv
            v-model="model"
            :totals="totals"
            :options="options"
            :submitted="submitted"
            :submitting="submitting"
            :loading="loading"
            :response="response"
            v-if="
              (model.step === 'contact' ||
                model.step === 'contractor' ||
                model.step === 'contactForm') &&
              !submitted &&
              !loading &&
              isLv
            "
          />
          <ContactFormOld
            v-model="model"
            :totals="totals"
            :options="options"
            :submitted="submitted"
            :submitting="submitting"
            :loading="loading"
            :response="response"
            v-if="
              (model.step === 'contact' ||
                model.step === 'contractor' ||
                model.step === 'contactForm') &&
              !submitted &&
              !loading &&
              !isLv
            "
          />
        </template>
      </div>
    </div>
    <ResponseSection
      v-if="submitted && !loading"
      :success="!response"
      :title="response ? 'Oops...😟' : 'Vielen Dank für Ihre Anfrage!'"
      :message="
        response
          ? 'Etwas ist schief gelaufen. Bitte versuchen Sie es erneut oder kontaktieren Sie uns direkt.'
          : ''
      "
    />
    <div v-if="!isProtected" class="mt-5 rounded-b-3xl bg-white sm:p-10"></div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch, nextTick } from 'vue'
import 'intl-tel-input/build/css/intlTelInput.css'
import track from '@/utils/tracking'

// Import components
import LoadingCircle from '@/components/Elements/LoadingCircle.vue'
import ResponseSection from '@/components/Structure/V2/ResponseSection.vue'
import ContractorStep from './ContractorStep.vue'
import ContactForm from './ContactForm.vue'
import ContactFormOld from '@/components/Structure/Singles/ContactFormOld.vue'
import ContactFormLv from '@/components/Structure/Singles/ContactFormLv.vue'

const props = defineProps({
  modelValue: Object,
  totals: Object,
  submitted: Boolean,
  submitting: Boolean,
  response: Object,
  options: Array,
  loading: Boolean,
  lastStep: String,
  single: Boolean,
  isLv: Boolean
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const isProtected = ref(true)
const currentStep = ref('')

const enabledServices = computed(() => {
  return Object.keys(model.value.services).filter((key) => model.value.services[key].enabled)
})

const nextStep = () => {
  const steps = ['contractor', 'contactForm']
  const currentIndex = steps.indexOf(currentStep.value)
  if (currentIndex < steps.length - 1) {
    currentStep.value = steps[currentIndex + 1]
    model.value.step = currentStep.value
  }
}

const goBack = () => {
  model.value.step =
    enabledServices.value.length >= 4 && !props.single ? 'contractor' : props.lastStep
}

watch(
  () => model.value.step,
  (newValue) => {
    if (enabledServices.value.length >= 4 && !props.single) {
      currentStep.value = 'contractor'
      if (model.value.services.contractor.enabled === null) {
        model.value.step = 'contractor'
      }
    } else {
      currentStep.value = 'contactForm'
      model.value.step = 'contractor'
    }
  }
)

watch(enabledServices, (newValue) => {
  if (!newValue.length) {
    model.value.step = 'services'
  }
})

onMounted(() => {
  if (enabledServices.value.length >= 4 && !props.single) {
    currentStep.value = 'contractor'
    model.value.step = 'contractor'
  } else {
    currentStep.value = 'contactForm'
    model.value.step = 'contactForm'
  }
  model.value.contact.postal_code = model.value.base.postal_code
  track.yandex(97558944, 'reachGoal', 'contactPage')
  track.tmBegin(model.value.type, props.totals.total.value)
})
</script>
