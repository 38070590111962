<script setup>
import { computed, onMounted } from 'vue'
import AmountInput from './AmountInput.vue'
import CounterInput from '@/components/Inputs/CounterInput.vue'

const emit = defineEmits(['update:modelValue', 'change'])

const props = defineProps({
  modelValue: {},
  options: Array
})

const model = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit('update:modelValue', value)
  }
})

const emitChange = (value, type) => {
  emit('change', {
    value,
    type
  })
}
</script>
<template>
  <div class="grid gap-4 md:grid-cols-3">
    <div v-for="(option, k) in options" :key="k" class="flex flex-col items-center gap-3">
      <div class="w-full">
        <div class="relative">
          <img
            :alt="option.title"
            :src="option.image"
            class="h-36 w-full rounded-[13px] object-cover"
          />
        </div>

        <label class="mt-2 block text-center text-sm text-white">{{ option.label }}</label>
      </div>
      <div>
        <CounterInput
          v-model="model[option.value]"
          @update:model-value="(v) => emitChange(v, option.value)"
        />
      </div>
    </div>
  </div>
</template>
