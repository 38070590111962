<script setup>
import { computed } from 'vue'
import InfoPanelIcon from './InfoPanelIcon.vue'

const props = defineProps({
  title: String,
  subtitle: String,
  name: String,
  infoTitle: String,
  info: String
})

const modalTitle = computed(() => {
  return props.infoTitle || props.title
})
</script>
<template>
  <aside>
    <div class="flex flex-row justify-between">
      <h2 class="text-2xl font-bold dark:text-white md:text-4xl">
        {{ title }}
      </h2>
      <InfoPanelIcon
        v-if="info"
        height="h-8 md:h-10"
        :content="info"
        :title="modalTitle"
        class="ml-2"
      >
        <slot name="info" />
      </InfoPanelIcon>
    </div>
    <p class="mb-5 mt-2 text-gray-200 md:mb-8 md:text-lg">
      {{ subtitle }}
    </p>

    <section :data-name="name" class="relative">
      <slot />
    </section>
  </aside>
</template>
