<template>
  <div id="contact" class="relative">
    <img
      src="/images/bg/final.png"
      alt="background"
      class="absolute inset-0 z-10 hidden h-full min-h-[95vh] w-full rounded-3xl object-cover sm:block"
    />
    <div class="container relative z-10 mx-auto max-w-7xl md:pb-20 md:pt-20">
      <div class="max-w-lg rounded-3xl">
        <template v-if="submitted">
          <div class="rounded-3xl bg-action p-5 sm:p-10">
            <h1 class="mb-3 text-3xl font-bold">Vielen Dank für Ihre Anfrage!</h1>
            <div class="prose text-lg">
              <p>
                Wir haben soeben eine <b>SMS mit einem Bestätigungslink</b> an Sie gesendet. Bitte
                bestätigen Sie Ihre Daten. Sollten wir keine Bestätigung erhalten, werden wir uns
                zeitnah bei Ihnen melden, um das weitere Vorgehen zu besprechen.
              </p>
              <p>
                Sollten Sie vorab noch Fragen haben, stehen wir Ihnen gerne unter
                <a href="tel:040 - 468 956 96-0">040 468 956 96-0</a> zur Verfügung.
              </p>
              <p>Vielen Dank für Ihr Vertrauen und viel Erfolg bei Ihrer anstehenden Sanierung.</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="rounded-t-3xl bg-action p-5 sm:px-10 sm:pb-5 sm:pt-10">
            <h1 class="mb-3 text-3xl font-bold">Wir haben die passenden Sanierungsprofis.</h1>
            <p class="text-lg">
              Jetzt <b>kostenlos</b> und <b>unverbindlich</b> Ihr passendes Angebot anfordern:
            </p>

            <fieldset class="mt-5 grid grid-cols-2 gap-5">
              <input
                type="text"
                v-model="model.contact.firstname"
                id="firstname"
                placeholder="Vorname"
                class="input input-final"
              />
              <input
                type="text"
                id="lastname"
                v-model="model.contact.lastname"
                placeholder="Nachname"
                class="input input-final"
              />
            </fieldset>

            <fieldset class="mt-5 grid gap-5">
              <input
                type="text"
                id="postal_code"
                v-model="model.contact.postal_code"
                placeholder="Ihre Postleitzahl"
                class="input input-final"
              />

              <input
                type="text"
                id="email"
                v-model="model.contact.email"
                placeholder="Ihre E-Mail"
                class="input input-final"
              />
              <input
                type="text"
                id="phone"
                v-model="model.contact.phone"
                placeholder="Ihre Telefonnumer"
                class="input input-final"
              />
            </fieldset>

            <p class="prose mt-3 text-sm">
              Mit dem Klick auf den unterstehenden Button stellen Sie eine Vermittlungsanfrage,
              akzeptieren die <a href="https://moreathome.de/agb" target="_blank">AGB</a> und
              bestätigen, dass Sie die
              <a href="https://moreathome.de/datenschutz" target="_blank">Datenschutzerklärung</a>
              zur Kenntnis genommen haben. Wir können Ihnen Informationen über ähnliche Produkte und
              Leistungen per E-Mail zusenden. Ein Widerspruch ist jederzeit hier möglich.
            </p>

            <div class="mt-5 grid items-center gap-3 md:grid-cols-2">
              <button
                type="submit"
                name="submit"
                class="btn btn--primary flex w-full flex-row items-center justify-center gap-x-3 px-3 py-2"
                :disabled="submitting"
              >
                <Loading v-if="submitting" class="my-1" />
                <span v-else>Angebote anfordern</span>
              </button>

              <div>
                <div class="h-[45px] rounded border border-primary">
                  <div id="badge-ribbon" class="-mt-2 w-full md:origin-left md:scale-[68%]"></div>
                </div>
              </div>
            </div>
            <p class="mb-0 mt-3 text-center">
              Dieser Service ist für Sie vollständig <u>kostenfrei und unverbindlich</u>!
            </p>
            <div class="mt-3 flex items-center justify-center gap-5">
              <div>
                <img src="/images/icons/ssl.png" class="h-20" alt="SSL Verschlüsselung" />
              </div>
              <div>
                <img src="/images/icons/dsgvo.png" class="h-20" alt="DSGVO konform" />
              </div>
            </div>
          </div>

          <div class="rounded-b-3xl bg-white p-5 sm:p-10">
            <h2 class="mb-10 text-2xl">Ihre Leistungen im Überblick</h2>

            <div>
              <div v-for="i in totals.services" class="mb-5 border-b-2 border-primary pb-5">
                <div class="mb-1 flex justify-between">
                  <h3 class="text-lg">{{ i.name }}</h3>
                  <p class="text-lg font-bold"><AnimatedNumber :model-value="i.total.value" /></p>
                </div>
              </div>
              <div class="flex items-end justify-between">
                <h5 class="text-2xl font-bold">Gesamtsumme:</h5>
                <p class="text-4xl font-bold">
                  <AnimatedNumber :model-value="totals.total.value" />
                </p>
              </div>
              <div class="mt-10 flex items-end justify-between">
                <button type="button" class="btn btn--primary" @click="goBack">Zurück</button>
                <button
                  @click="toTop"
                  type="button"
                  class="flex size-8 rotate-45 items-center justify-center bg-action shadow"
                >
                  <span class="sr-only">To Top</span>
                  <svg class="-ml-0.5 size-8 -rotate-45" viewBox="0 0 21 21">
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="m6.5 12.5l4-4l4 4"
                    />
                  </svg>
                </button>
              </div>
            </div></div
        ></template>
      </div>
    </div>
  </div>
</template>
<script setup>
import Loading from '@/components/Elements/Loading.vue'
import AnimatedNumber from '@/components/Inputs/AnimatedNumber.vue'
import track from '@/utils/tracking'
import scrollToSelector from '@/utils/scroll'
import { defineProps, defineEmits, computed, onMounted } from 'vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object,
  totals: Object,
  submitted: Boolean,
  submitting: Boolean
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const goBack = () => {
  model.value.step = 'services'
}

const toTop = () => {
  scrollToSelector('#contact')
}

onMounted(() => {
  model.value.contact.postal_code = model.value.base.postal_code

  track.yandex(97558944, 'reachGoal', 'contactPage')
  track.tmBegin(model.value.type, props.totals.total.value)

  if (typeof reviewsBadgeRibbon !== 'undefined' && !props.submitted)
    reviewsBadgeRibbon('badge-ribbon', {
      store: 'www.moreathome.de',
      size: 'small',
      mono: true
    })
})
</script>
