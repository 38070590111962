<script setup>
import { computed, onMounted } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Quality from '@/components/Inputs/Quality.vue'
import ImageOptionsSelection from '../Inputs/ImageOptionsSelection.vue'
import Headline from '../Elements/Headline.vue'
import AlertInfo from '../Elements/AlertInfo.vue'
import { wallsOptions, wallsCeilingsOptions } from '@/data/options/walls'
import infos from '@/data/infos/services/wallsCeiling'
import RoomsSelection from '../Inputs/RoomsSelection.vue'
import RoomsTabs from '../Inputs/RoomsTabs.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentRoomType = computed({
  get: () => {
    if (!model.value.change.currentRoom) {
      return model.value.change.type
    }
    return model.value.change.roomConfigs[model.value.change.currentRoom].type
  },
  set: (v) => {
    if (!model.value.change.currentRoom) {
      model.value.change.type = v
    } else {
      model.value.change.roomConfigs[model.value.change.currentRoom].type = v
    }
  }
})

const currentCeilingType = computed({
  get: () => {
    if (!model.value.changeCeiling.currentRoom) {
      return model.value.changeCeiling.type
    }
    return model.value.changeCeiling.roomConfigs[model.value.changeCeiling.currentRoom].type
  },
  set: (v) => {
    if (!model.value.changeCeiling.currentRoom) {
      model.value.changeCeiling.type = v
    } else {
      model.value.changeCeiling.roomConfigs[model.value.changeCeiling.currentRoom].type = v
    }
  }
})

const changeRooms = computed(() => {
  return props.form.rooms
    .filter((i) => i.enabled && !['bathroom', 'guest_wc'].includes(i.type))
    .map((room) => {
      return {
        ...room,
        checked: model.value.change.roomConfigs[room.id]?.type ? true : false
      }
    })
})

const changeRoomsCeiling = computed(() => {
  return props.form.rooms
    .filter((i) => i.enabled && !['bathroom', 'guest_wc'].includes(i.type))
    .map((room) => {
      return {
        ...room,
        checked: model.value.changeCeiling.roomConfigs[room.id]?.type ? true : false
      }
    })
})

const roomsWithCeilingSpots = computed(() => {
  const roomsIdsWithDownlights = Object.keys(model.value.changeCeiling.roomConfigs).reduce(
    (acc, key) => {
      const room = model.value.changeCeiling.roomConfigs[key]
      if (room.type === 'all') {
        acc.push(key)
      }
      return acc
    },
    []
  )

  return props.form.rooms.filter((i) => {
    return roomsIdsWithDownlights.includes(i.id)
  })
})

onMounted(() => {
  props.form.rooms
    .filter((i) => i.enabled && !['bathroom', 'guest_wc'].includes(i.type))
    .forEach((room) => {
      if (!model.value.change.roomConfigs[room.id]) {
        model.value.change.roomConfigs[room.id] = {
          type: ''
        }
      }

      if (!model.value.changeCeiling.roomConfigs[room.id]) {
        model.value.changeCeiling.roomConfigs[room.id] = {
          type: ''
        }
      }
    })
})
</script>

<template>
  <div>
    <FormGroup
      info-title="Wände und Decken"
      :info="infos.walls"
      label="Sollen Wände bearbeitet werden?"
      name="change"
    >
      <template #right>
        <Switch v-model="model.change.enabled" />
      </template>
      <template v-if="model.change.enabled">
        <RoomsTabs
          class="mt-4"
          v-model="model.change.currentRoom"
          :rooms="changeRooms"
          :checked="!model.change.currentRoom"
        />
        <AlertInfo v-if="!model.change.currentRoom" class="mt-2"
          >Bitte wählen Sie einen Raum aus</AlertInfo
        >
        <ImageOptionsSelection
          v-if="model.change.currentRoom"
          name="type"
          class="mt-4"
          can-clear
          v-model="currentRoomType"
          :options="wallsOptions"
        />
        <Headline
          title="Qualität"
          class="!mt-4"
          subtitle="der Farben, Tapeten und Fliesen"
        ></Headline>
        <Quality v-model="model.change.quality" />
      </template>
    </FormGroup>

    <FormGroup
      info-title="Decken"
      :info="infos.ceilings"
      label="Sollen Decken bearbeitet werden?"
      name="changeCeiling"
    >
      <template #right>
        <Switch v-model="model.changeCeiling.enabled" />
      </template>

      <template v-if="model.changeCeiling.enabled">
        <RoomsTabs
          class="mt-4"
          v-model="model.changeCeiling.currentRoom"
          :rooms="changeRoomsCeiling"
          :checked="!model.changeCeiling.currentRoom"
        />
        <AlertInfo v-if="!model.changeCeiling.currentRoom" class="mt-2"
          >Bitte wählen Sie einen Raum aus</AlertInfo
        >

        <ImageOptionsSelection
          v-if="model.changeCeiling.currentRoom"
          class="mt-4"
          name="type"
          cols="sm:grid-cols-3"
          can-clear
          v-model="currentCeilingType"
          :options="wallsCeilingsOptions"
        />
        <Headline title="Qualität" class="!mt-4" subtitle="der Farben"></Headline>
        <Quality v-model="model.changeCeiling.quality" />
      </template>
    </FormGroup>

    <FormGroup
      info-title="Deckenspots"
      :info="infos.ceilingSpots"
      label="Sollen Deckenspots verbaut werden?"
      name="ceilingSpots"
    >
      <template #right>
        <Switch v-model="model.ceilingSpots.enabled" />
      </template>

      <RoomsSelection
        class="mt-4"
        v-if="model.ceilingSpots.enabled"
        v-model="model.ceilingSpots.rooms"
        :rooms="roomsWithCeilingSpots"
      />
    </FormGroup>

    <FormGroup label="Soll bestehende Tapete an Wand und Decke entfernt werden?" name="removeOld">
      <template #right>
        <Switch v-model="model.removeOld.enabled" />
      </template>
      <RoomsSelection
        class="mt-4"
        v-if="model.removeOld.enabled"
        v-model="model.removeOld.rooms"
        :rooms="form.rooms"
      />
    </FormGroup>

    <FormGroup
      info-title="Stuck ausbessern oder auswaschen"
      :info="infos.improveStucco"
      label="Soll bestehender Stuck aufgearbeitet werden?"
      name="improveStucco"
    >
      <template #right>
        <Switch v-model="model.improveStucco.enabled" />
      </template>

      <RoomsSelection
        class="mt-4"
        v-if="model.improveStucco.enabled"
        v-model="model.improveStucco.rooms"
        :rooms="form.rooms"
      />
    </FormGroup>

    <FormGroup
      info-title="Neuen Stuck anbringen"
      :info="infos.newStucco"
      label="Soll neuer Stuck angebracht werden?"
      name="newStucco"
    >
      <template #right>
        <Switch v-model="model.newStucco.enabled" />
      </template>

      <RoomsSelection
        class="mt-4"
        v-if="model.newStucco.enabled"
        v-model="model.newStucco.rooms"
        :rooms="form.rooms"
      />
    </FormGroup>
  </div>
</template>
