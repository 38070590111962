import { getImageUrl } from '@/utils/image'

export const wallsOptions = [
  {
    label: 'Wände spachteln, schleifen und streichen',
    image: getImageUrl('/images/calc/walls/all.png'),
    value: 'rebase'
  },
  {
    label: 'Wände nur streichen',
    image: getImageUrl('/images/calc/walls/painting.png'),
    value: 'paint'
  },
  {
    label: 'Wände tapezieren',
    image: getImageUrl('/images/calc/walls/wallpapering.png'),
    value: 'wallpaper'
  },
  { label: 'Wände fliesen', image: getImageUrl('/images/calc/walls/tile.png'), value: 'tile' }
]

export const wallsCeilingsOptions = [
  {
    label: 'Decke abhängen, spachteln, schleifen und streichen',
    image: getImageUrl('/images/calc/walls/down.png'),
    value: 'all'
  },
  {
    label: 'Decke spachteln, schleifen und streichen',
    image: getImageUrl('/images/calc/walls/all.png'),
    value: 'rebase'
  },
  {
    label: 'Decke nur streichen',
    image: getImageUrl('/images/calc/walls/painting.png'),
    value: 'painting'
  }
]
