<script setup>
import scrollToSelector from '@/utils/scroll'
import { computed, defineProps } from 'vue'

const props = defineProps({
  modelValue: Object,
  totals: Object,
  isProject: Boolean,
  isInternal: Boolean
})

const showNext = computed(() => {
  if (props.modelValue.step === 'contact') {
    return false
  }

  if (props.modelValue.step.startsWith('services') && (props.isProject || props.isInternal)) {
    return false
  }

  return true
})

const canPrev = computed(() => {
  if (props.modelValue.step !== 'base') {
    return true
  }

  return false
})

const canNext = computed(() => {
  if (props.modelValue.step.startsWith('services') && !props.totals.total.value) {
    return false
  }

  return true
})

const goPrev = () => {
  if (props.modelValue.step.startsWith('rooms')) {
    if (window.innerWidth < 1024) {
      scrollToSelector('#grid')
    } else {
      props.modelValue.step = 'base'
    }
  } else if (props.modelValue.step.startsWith('services')) {
    if (window.innerWidth < 1024) {
      scrollToSelector('#grid')
    } else {
      props.modelValue.step = 'rooms'
    }
  } else if (props.modelValue.step.startsWith('contact')) {
    props.modelValue.step = 'services'
  }
}

const goNext = () => {
  if (props.modelValue.step === 'base') {
    props.modelValue.step = 'rooms'
  } else if (props.modelValue.step.startsWith('rooms')) {
    props.modelValue.step = 'services'
  } else if (props.modelValue.step.startsWith('services')) {
    props.modelValue.step = 'contact'
  }
}

const nextLabel = computed(() => {
  if (props.modelValue.step === 'base') {
    return 'zu den Räumen'
  }

  if (props.modelValue.step.startsWith('rooms')) {
    return 'zum Handwerk'
  }

  if (props.modelValue.step.startsWith('services')) {
    return 'Handwerker finden'
  }

  return 'Absenden'
})

const prevLabel = computed(() => {
  if (props.modelValue.step === 'base') {
    return 'zurück'
  }

  if (props.modelValue.step.startsWith('rooms')) {
    if (window.innerWidth < 1024) {
      return 'weiterer Raum'
    }

    return 'zum Objekt'
  }

  if (props.modelValue.step.startsWith('services')) {
    if (window.innerWidth < 1024) {
      return 'weiter'
    }

    return 'zu den Räumen'
  }

  return 'zum Handwerk'
})
</script>

<template>
  <div
    class="mb-10 mt-10 w-full flex-row items-center justify-end space-x-4 border-t border-gray-200/20 pt-8 md:mb-0"
  >
    <button class="btn btn--action px-4" :disabled="!canPrev" type="button" @click.prevent="goPrev">
      {{ prevLabel }}
    </button>
    <button
      v-if="showNext"
      id="next"
      class="btn btn--action px-4"
      :disabled="!canNext"
      type="button"
      @click.prevent="goNext"
    >
      {{ nextLabel }}
    </button>
  </div>
</template>
