<template>
  <button
    id="service-final"
    @click="updateService"
    :class="[
      'btn px-10 py-3 text-xl',
      getNextService() === 'contactForm' ? 'btn--action border-2 border-primary' : 'btn--primary'
    ]"
  >
    {{ isLv ? 'Jetzt Leistungsübersicht sichern' : buttonLabel }}
  </button>
</template>

<script setup>
import { computed, defineEmits, defineProps } from 'vue'

const emit = defineEmits(['update:modelValue', 'update:steps'])

const props = defineProps({
  modelValue: Object,
  enabledServices: Array,
  availableServices: Array,
  isLv: Boolean
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const findActiveService = (service) => {
  const currentStep = model.value.step
  if (currentStep.includes(service)) {
    return model.value.services[service].enabled
  }
  return false
}

const getNextService = () => {
  const currentService = model.value.step.split('_')[1]
  const currentIndex = props.enabledServices.indexOf(currentService)

  const findNextActiveService = (startIndex) => {
    for (let i = startIndex; i < props.enabledServices.length; i++) {
      const service = props.enabledServices[i]
      if (
        service !== 'contractor' &&
        model.value.services[service].enabled &&
        !model.value.services[service].completed
      ) {
        return `services_${service}_start`
      }
    }
    return null
  }

  // Look for the next active service starting from the next index
  let nextService = findNextActiveService(currentIndex + 1)

  // If no service is found, wrap around to the beginning
  if (!nextService) {
    nextService = findNextActiveService(0)
  }

  // If still no active service is found, return 'contact'
  return nextService || 'contactForm'
}

const updateService = () => {
  const newStep = model.value.step
  const service = newStep.split('_')[1]
  const isActive = findActiveService(service)

  if (isActive) {
    model.value.step = getNextService()
  }
}

const buttonLabel = computed(() => {
  return getNextService() === 'contactForm' ? 'Jetzt Handwerker finden' : 'Nächster Schritt'
})
</script>
