import { getImageUrl } from '@/utils/image'

export const facadeTypeOptions = [
  {
    label: 'Wärmedämmverbundsystem mit Riemchen',
    value: 'clinker',
    image: getImageUrl('/images/calc/facade/riemchen.png')
  },
  {
    label: 'Wärmedämmverbundsystem mit Putz',
    value: 'plaster',
    image: getImageUrl('/images/calc/facade/plaster.png')
  },
  {
    label: 'Fassade streichen',
    value: 'paint',
    image: getImageUrl('/images/calc/facade/paint.png')
  },
  {
    label: 'Einblasdämmung',
    value: 'blown_insulation',
    image: getImageUrl('/images/calc/facade/blown_insulation.png')
  }
]
