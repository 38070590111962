export const renew = `<p>Ein Bodenbelag ist die Oberfl&auml;chenschicht des Fu&szlig;bodens in einem Raum und tr&auml;gt ma&szlig;geblich zur Optik, Funktionalit&auml;t und dem Komfort des Raums bei. Verschiedene Materialien und Konstruktionen bieten dabei unterschiedliche Eigenschaften f&uuml;r diverse Anwendungsbereiche. Hier werden sechs g&auml;ngige Typen von Bodenbel&auml;gen beschrieben:</p>
<ol>
<li>Laminat: Laminatboden ist ein beliebter und preisg&uuml;nstiger Bodenbelag, der aus mehreren Schichten besteht. Die oberste Schicht bildet eine transparente Verschlei&szlig;schicht, die das Dekorpapier mit Holz-, Stein- oder Fantasieoptik sch&uuml;tzt. Darunter befindet sich das Tr&auml;germaterial, meistens eine hochverdichtete Faserplatte (HDF). Laminat ist strapazierf&auml;hig, leicht zu reinigen und einfach zu verlegen. Es eignet sich f&uuml;r nahezu alle Wohnbereiche, mit Ausnahme von Feuchtr&auml;umen.</li>
<li>Diele: Dielenb&ouml;den sind traditionelle Holzb&ouml;den, die aus l&auml;nglichen, massiven Holzbrettern bestehen, die aneinandergef&uuml;gt und auf dem Untergrund verschraubt oder genagelt werden. Sie bringen eine nat&uuml;rliche und warme Atmosph&auml;re in jeden Raum und sind besonders langlebig. Dielen k&ouml;nnen abgeschliffen und neu versiegelt oder ge&ouml;lt werden, um sie &uuml;ber Jahre hinweg zu erhalten. Sie eignen sich besonders f&uuml;r Wohn- und Schlafbereiche, sollten aber nicht in Feuchtr&auml;umen verlegt werden.</li>
<li>Parkett: Parkett ist ein hochwertiger und edler Holzboden, der aus Massivholzst&auml;bchen oder -platten seines und zu Mustern verlegt wird, wie zum Beispiel dem Fischgr&auml;t- oder W&uuml;rfelmuster. Parkettb&ouml;den sind langlebig und k&ouml;nnen mehrmals abgeschliffen und neu behandelt werden. Sie sind warm unter den F&uuml;&szlig;en und wirken schalld&auml;mmend. Parkett ben&ouml;tigt eine gewisse Pflege und ist empfindlich gegen&uuml;ber Feuchtigkeit und Kratzern.</li>
<li>Teppich: Teppichboden bietet eine weiche, warme und schalld&auml;mmende Oberfl&auml;che, die sich ideal f&uuml;r Schlafzimmer und Wohnr&auml;ume eignet. Er ist in vielen Farben, Mustern und Texturen erh&auml;ltlich und sorgt f&uuml;r ein gem&uuml;tliches Raumklima. Allerdings kann Teppich Allergenen wie Staub und Milben einen N&auml;hrboden bieten und ist nicht so einfach zu reinigen wie harte Bodenbel&auml;ge. F&uuml;r Feuchtr&auml;ume ist Teppich daher nicht empfehlenswert.</li>
<li>Vinyl: Vinylb&ouml;den sind sehr vielseitig und robust, leicht zu reinigen und zu verlegen. Sie bestehen aus Kunststoff und sind oft in Form von Planken oder Fliesen erh&auml;ltlich, die unterschiedlichste Designs imitieren k&ouml;nnen, darunter Holz, Stein und Keramik. Moderne Vinylb&ouml;den sind oft wasserfest und damit auch f&uuml;r K&uuml;chen oder B&auml;der geeignet. Sie bieten zudem eine gute Trittschalld&auml;mmung und sind fu&szlig;warm.</li>
<li>Fliese: Fliesen sind besonders widerstandsf&auml;hig, wasserfest und leicht zu reinigen, was sie ideal f&uuml;r B&auml;der, K&uuml;chen und andere Feuchtr&auml;ume macht. Sie bestehen aus Keramik, Porzellan oder Naturstein und sind in einer Vielzahl von Farben, Gr&ouml;&szlig;en und Mustern verf&uuml;gbar. Fliesen sind sehr langlebig und eignen sich auch f&uuml;r Fu&szlig;bodenheizungen. Da sie jedoch hart und kalt sein k&ouml;nnen, werden sie in Wohn- und Schlafr&auml;umen seltener verwendet.</li>
</ol>`

export const remove = `<p>Die Entfernung von alten Fu&szlig;bodenbel&auml;gen ist ein wesentlicher Schritt bei Renovierungs- oder Sanierungsarbeiten in Geb&auml;uden. Je nach Art des vorliegenden Materials kann der Aufwand variieren und bestimmte Sicherheitsma&szlig;nahmen erfordern. Im Folgenden erhalten Sie einen detaillierten Einblick in die Herausforderung der Entfernung verschiedener Fu&szlig;bodenbel&auml;ge und den speziellen Umgang mit Asbest.</p>
<ol>
<li>Teppichb&ouml;den: Teppichb&ouml;den sind h&auml;ufig mit einem Klebstoff auf dem Untergrund fixiert. Die Entfernung erfolgt in der Regel durch Aufschneiden des Teppichs in Streifen und anschlie&szlig;endes Abziehen. Der Klebstoffr&uuml;ckst&auml;nde kann manchmal m&uuml;hsam sein und erfordert gegebenenfalls den Einsatz spezieller L&ouml;sungsmittel oder Schleifmaschinen.</li>
<li>Laminat: Laminatb&ouml;den sind meist schwimmend verlegt, was bedeutet, dass sie nicht mit dem Untergrund verklebt sind, sondern durch ein Klick-System zusammengehalten werden. Die Entfernung ist daher oft weniger aufwendig. Die Paneele werden St&uuml;ck f&uuml;r St&uuml;ck auseinandergenommen und entfernt.</li>
<li>Parkett: Echtholzparkett kann genagelt oder verklebt sein. Bei genageltem Parkett ist es n&ouml;tig, die N&auml;gel zu l&ouml;sen, was zeitaufwendig sein kann. Bei verklebtem Parkett m&uuml;ssen &auml;hnlich wie bei Teppichb&ouml;den die Klebstoffe entfernt werden. Hierbei kommt es oft zum Einsatz von Schleifmaschinen oder speziellen Abbeizmitteln.</li>
<li>PVC und Vinyl: PVC- oder Vinylbel&auml;ge sind oft fest verklebt, und ihre Entfernung kann sehr anstrengend sein. Manchmal lassen sich alte Bel&auml;ge nur in kleinen St&uuml;cken abl&ouml;sen. Auch hier kann es n&ouml;tig sein, hartn&auml;ckige Kleberreste mit entsprechenden Mitteln oder Schabern zu beseitigen.</li>
<li>Fliesen: Fliesen sind in der Regel fest mit dem Untergrund verklebt oder verfugt, was die Entfernung zu einer sehr staub- und l&auml;rmintensiven Arbeit macht. F&uuml;r das Herausbrechen der Fliesen werden oft Hammer und Mei&szlig;el oder elektrische Aufbruchhammer verwendet.</li>
</ol>
<p>Asbest-Thematik: Ein besonderes Augenmerk ist auf Fu&szlig;bodenbel&auml;ge zu legen, die vor den 1990er Jahren verlegt wurden, da diese Asbest enthalten k&ouml;nnen. Asbest ist ein gesundheitsgef&auml;hrdender Stoff, der bei unsachgem&auml;&szlig;er Handhabung Lungenerkrankungen, wie Asbestose oder Mesotheliom, verursachen kann. Bei Verdacht auf Asbest ist es erforderlich, eine Probe des Materials von Experten analysieren zu lassen. Die Entfernung von asbesthaltigen Materialien darf ausschlie&szlig;lich von speziell ausgebildeten Fachkr&auml;ften unter strengen Sicherheitsauflagen durchgef&uuml;hrt werden. Unter keine Umst&auml;nden sollte man als Laie versuchen, solche Bel&auml;ge selbst zu entfernen.</p>
<p>Zusammenfassend ist die Entfernung von alten Fu&szlig;bodenbel&auml;gen oft eine herausfordernde Aufgabe, die je nach Material unterschiedlich viel Zeit und Arbeit erfordert. Insbesondere der Umgang mit potenziell asbesthaltigen Materialien erfordert professionelle Handhabung und sollte in jedem Fall Fachleuten &uuml;berlassen bleiben, um gesundheitliche Risiken zu minimieren.</p>`

export const wood = `<p>Die Aufbereitung von altem Holzfu&szlig;boden kann vielerlei Vorteile bieten und stellt h&auml;ufig eine sinnvolle Alternative zum vollst&auml;ndigen Austausch des Bodenbelags dar. Dieser Informationstext betrachtet die Gr&uuml;nde, warum eine Aufbereitung sinnvoll sein kann, und die Aspekte, die dabei zu beachten sind.</p>
<p>&Auml;sthetischer Wert: Alte Holzb&ouml;den haben oft einen einzigartigen Charakter. Sie weisen eine Patina auf, die durch moderne B&ouml;den nur schwer nachzuahmen ist. Durch das Aufarbeiten, wie Abschleifen und Neuversiegeln, k&ouml;nnen Kratzer, Dellen und Verf&auml;rbungen entfernt werden, wodurch der Holzboden sein urspr&uuml;ngliches Aussehen und seine Sch&ouml;nheit zur&uuml;ckerlangt. Die nat&uuml;rliche Maserung und Farbe des Holzes kommt wieder zur Geltung, und in vielen F&auml;llen steigert die erneuerte Oberfl&auml;che den gesamten Wohnwert des Raumes.</p>
<p>Historischer Wert: In historischen Geb&auml;uden ist der Erhalt des urspr&uuml;nglichen Bodenbelags oft w&uuml;nschenswert, um den authentischen Charakter des Geb&auml;udes zu erhalten. Die fachgerechte Restaurierung alter Holzb&ouml;den kann dazu beitragen, den kulturellen und historischen Wert des Geb&auml;udes zu bewahren.</p>
<p>&Ouml;kologische Aspekte: Die Aufbereitung von vorhandenem Material ist umweltfreundlicher als ein kompletter Austausch. Denn w&auml;hrend die Produktion neuer Bodenbel&auml;ge Rohstoffe verbraucht und Energie ben&ouml;tigt, hat der alte Boden diesen &ouml;kologischen Fu&szlig;abdruck bereits hinter sich. Durch das Aufarbeiten werden Ressourcen geschont und die Menge von Abfall, der ansonsten bei der Entsorgung anfallen w&uuml;rde, wird reduziert.</p>
<p>Kostenaspekte: Auch wenn die professionelle Aufbereitung eines Holzbodens Kosten verursacht, kann sie in vielen F&auml;llen kosteneffektiver sein als der Kauf und die Installation eines neuen Bodenbelags. Dies ist insbesondere dann der Fall, wenn der alte Holzboden aus hochwertigen H&ouml;lzern besteht, die heutzutage teuer oder schwer zu beschaffen sind.</p>
<p>Langlebigkeit: Holzb&ouml;den sind f&uuml;r ihre Langlebigkeit bekannt. Viele Holzb&ouml;den, die gut gepflegt wurden, k&ouml;nnen &uuml;ber Jahrzehnte hinweg genutzt werden. Durch die Aufbereitung k&ouml;nnen Sie diese Haltbarkeit weiter verl&auml;ngern, indem Sie das Holz vor Abnutzung und Sch&auml;den sch&uuml;tzen.</p>
<p>Bei der Aufbereitung eines Holzbodens gibt es jedoch auch Kriterien zu beachten, um sicherzustellen, dass es sich um eine sinnvolle Ma&szlig;nahme handelt. Wichtig ist der Zustand des Holzes: Ist es noch stabil genug oder durch Feuchtigkeit und Sch&auml;dlinge besch&auml;digt? Ebenso sollten die Kosten im Vorfeld genau kalkuliert werden, um abzuw&auml;gen, ob eine Aufbereitung wirtschaftlich und technisch sinnvoll ist.</p>
<p>Zusammengefasst hat die Aufbereitung alter Holzb&ouml;den sowohl aus &auml;sthetischen, historischen, &ouml;kologischen als auch wirtschaftlichen Gesichtspunkten zahlreiche Vorteile und ist daher in vielen F&auml;llen eine empfehlenswerte Option.</p>`

export const sockel = `<p>Fu&szlig;leisten, auch Sockelleisten oder Bodenleisten genannt, sind ein elementarer Bestandteil des Innenausbaus. Sie dienen nicht nur zum Abdecken der Fuge zwischen Wand und Boden, sondern auch als dekoratives Element, das einen nahtlosen &Uuml;bergang schafft und den Raum optisch verfeinert. Es gibt verschiedene Arten von Fu&szlig;leisten, die sich hinsichtlich Material, Form, Gr&ouml;&szlig;e und Befestigungsart unterscheiden.</p>
<ol>
<li><strong>Holzleisten:</strong> Diese Leisten werden oft aus Massivholz oder furnierten MDF-Platten hergestellt. Sie bringen W&auml;rme und Nat&uuml;rlichkeit in den Raum und k&ouml;nnen lackiert, gebeizt oder mit Holz&ouml;l behandelt werden, um das Aussehen und die Haltbarkeit zu verbessern. Beliebte Holzsorten sind Eiche, Buche, Ahorn und Kiefer.</li>
<li><strong>MDF-Leisten:</strong> MDF (mitteldichte Faserplatte) ist ein sehr h&auml;ufig verwendetes Material f&uuml;r Sockelleisten. MDF-Leisten sind in einer Vielzahl von Profilen und Gr&ouml;&szlig;en erh&auml;ltlich und k&ouml;nnen mit einer Dekorfolie ummantelt oder f&uuml;r eine individuelle Gestaltung gestrichen werden.</li>
<li><strong>Kunststoffleisten:</strong> Sie sind kosteng&uuml;nstig, wasserfest und leicht zu s&auml;ubern. Kunststoffleisten eignen sich besonders f&uuml;r Badezimmer, K&uuml;chen oder anderen Feuchtr&auml;umen. Sie sind in verschiedenen Farben und Mustern verf&uuml;gbar und h&auml;ufig als Imitationen von Holzleisten zu finden.</li>
<li><strong>Metallleisten:</strong> Aus Materialien wie Edelstahl, Aluminium oder Messing gefertigt, bieten sie eine moderne und industrielle Optik. Metallleisten sind besonders langlebig und strapazierf&auml;hig und k&ouml;nnen eine gute Wahl f&uuml;r &ouml;ffentliche oder stark frequentierte Bereiche sein.</li>
<li><strong>Steinleisten:</strong> Diese Leisten sind oft aus Materialien wie Marmor, Granit oder anderen Steinarten hergestellt. Sie sind besonders haltbar und k&ouml;nnen ein luxuri&ouml;ses Flair in den Raum bringen. Allerdings sind sie auch schwerer und in der Regel teurer als andere Leistenarten.</li>
<li><strong>Stuckleisten:</strong> Traditionell aus Gips hergestellt, dienen Stuckleisten vor allem dekorativen Zwecken. Sie sind mit klassischen Verzierungen und Mustern versehen und besonders f&uuml;r Altbauten oder in R&auml;umen mit hohen Decken beliebt.</li>
</ol>
<p>Bei der Montage von Fu&szlig;leisten gibt es ebenfalls verschiedene Methoden:</p>
<ul>
<li><strong>Schrauben und D&uuml;beln:</strong> eine robuste Befestigungsart, die h&auml;ufig bei Holzleisten verwendet wird.</li>
</ul>
<ul>
<li><strong>Nageln:</strong> oft bei MDF- und Holzleisten angewandt.</li>
</ul>
<ul>
<li><strong>Kleben:</strong> eine schnelle und saubere Methode, die besonders bei Kunststoff- und MDF-Leisten zum Einsatz kommt.</li>
</ul>
<ul>
<li><strong>Klemmsysteme:</strong> bieten eine schraubenlose Montage und sind einfach zu installieren.</li>
</ul>
<p>Die Auswahl der passenden Fu&szlig;leisten h&auml;ngt von pers&ouml;nlichen Vorlieben, dem vorhandenen Bodenbelag, der Wandbeschaffenheit und funktionalen Aspekten ab. Es ist wichtig, dass die Fu&szlig;leisten harmonisch zum Gesamtbild des Raumes passen und die technischen Anforderungen erf&uuml;llen.</p>`

export default {
  renew,
  remove,
  wood,
  sockel
}
