export const open = `<p>Beim &Ouml;ffnen und Schlie&szlig;en einer Wand, sei es f&uuml;r Renovierungen, Umbauten oder nachtr&auml;gliche Anpassungen, sind unterschiedliche Aspekte je nach Wandtyp - massiv oder Leichtbau - zu beachten. Hier einige grundlegende Informationen zu beiden Wandtypen und den jeweiligen Besonderheiten:</p>
<p>Massivw&auml;nde: Massivw&auml;nde bestehen traditionell aus Materialien wie Ziegel, Beton oder Stein und zeichnen sich durch ihre hohe Stabilit&auml;t und Schalld&auml;mmf&auml;higkeit aus.</p>
<p>&Ouml;ffnen einer Massivwand: Beim &Ouml;ffnen einer Massivwand - etwa um eine T&uuml;r oder ein Fenster einzubauen - muss vor allem die statische Integrit&auml;t des Geb&auml;udes beachtet werden. Hier sind einige wichtige Punkte:</p>
<ol>
<li>Statische Pr&uuml;fung: Bevor man eine &Ouml;ffnung schafft, sollte ein Statiker konsultiert werden, um zu pr&uuml;fen, ob die Wand tragend ist und welche Auswirkungen die &Ouml;ffnung haben k&ouml;nnte.</li>
<li>St&uuml;tzma&szlig;nahmen: Bei tragenden W&auml;nden m&uuml;ssen beim &Ouml;ffnen ausreichend St&uuml;tz- und Abfangma&szlig;nahmen eingeplant werden, um die Struktur &uuml;ber der &Ouml;ffnung zu sichern.</li>
<li>Staub- und Schuttkontrolle: Schweres Material wie Beton oder Ziegel erzeugt beim Entfernen viel Staub und Schutt. Hier m&uuml;ssen entsprechende Vorkehrungen zur Sauberhaltung und Entsorgung getroffen werden.</li>
</ol>
<p>Schlie&szlig;en einer Massivwand: Wird eine &Ouml;ffnung wieder geschlossen, sollten die Materialien und Techniken sorgf&auml;ltig gew&auml;hlt werden, um die Wandstruktur und -eigenschaften wiederherzustellen:</p>
<ol>
<li>Passendes Material: Es muss ein zum restlichen Mauerwerk passendes Material verwendet werden, um die Stabilit&auml;t und Dichtigkeit zu garantieren.</li>
<li>Fugentechnik: Die Verbindung zwischen neuem und altem Mauerwerk muss gr&uuml;ndlich hergestellt werden, um Risse und bauphysikalische Probleme wie W&auml;rmebr&uuml;cken zu vermeiden.</li>
</ol>
<p>Leichtbauw&auml;nde: Leichtbauw&auml;nde sind oft nicht tragend und bestehen aus Metallrahmen oder Holzst&auml;ndern, die mit Gipskartonplatten, Spanplatten oder anderen Leichtbaumaterialien beplankt sind.</p>
<p>&Ouml;ffnen einer Leichtbauwand: Das &Ouml;ffnen einer Leichtbauwand ist einfacher als bei einer Massivwand, da sie weniger Gewicht und &uuml;blicherweise keine tragende Funktion haben:</p>
<ol>
<li>Lokalisierung von Installationen: Vor dem Schneiden der Wand&ouml;ffnung m&uuml;ssen Stromleitungen, Wasserrohre und andere Installationen lokalisiert und bei Bedarf verlegt werden.</li>
<li>Stabiler Rahmen: Es muss ein stabiler Rahmen um die &Ouml;ffnung hergestellt werden, um die Struktur zu sichern und die Kanten der Gipskartonplatten zu sch&uuml;tzen.</li>
<li>Geringere Baubelastung: Da die Materialien leichter sind, fallen weniger Staub und Schutt als bei einer Massivwand an, allerdings sollte auch hier auf Sauberkeit geachtet werden.</li>
</ol>
<p>Schlie&szlig;en einer Leichtbauwand: Beim Schlie&szlig;en einer Leichtbauwand m&uuml;ssen ebenfalls wichtige Schritte beachtet werden:</p>
<ol>
<li>Rahmensetzung: Wie beim &Ouml;ffnen muss auch beim Schlie&szlig;en ein ordentlicher Rahmen gesetzt werden, der die neuen Wandmaterialien st&uuml;tzt.</li>
<li>Plattenmaterial und Verspachteln: Gipskarton oder &auml;hnliches Material wird zugeschnitten und befestigt, wobei anschlie&szlig;end die Fugen verspachtelt und geschliffen werden sollten, um eine glatte Oberfl&auml;che zu erlangen.</li>
<li>Endbearbeitung: Die Wand wird abschlie&szlig;end verputzt, gegl&auml;ttet und erforderlichenfalls mit Tapete oder Farbe beschichtet.</li>
</ol>
<p>Sicherheit, Sorgfalt und eine fachgerechte Ausf&uuml;hrung sind sowohl beim &Ouml;ffnen als auch beim Schlie&szlig;en von W&auml;nden unerl&auml;sslich, unabh&auml;ngig vom Wandtyp. Beim Umgang mit tragenden Strukturen sollten immer Fachleute hinzugezogen werden, um die Integrit&auml;t des Bauwerks zu gew&auml;hrleisten.</p>`

export default {
  open
}
