<template>
  <main class="min-h-screen bg-beige p-3">
    <aside class="fixed right-3 top-24 z-[10000] rounded bg-white p-3 shadow-lg">
      <select v-model="model" id="testcase">
        <option value="calc">Calculator</option>
        <option value="calc2">CalculatorV2</option>
        <option value="calc2:lv">CalculatorV2 LV</option>
        <option value="single:heatpump">Single: Wärmepumpe</option>
        <option value="single:facade">Single: Fassade</option>
        <option value="single:electric">Single: Elektronik</option>
        <option value="single:floor">Single: Boden</option>
        <option value="single:window">Single: Fenster</option>
        <option value="single:bath">Single: Badezimmer</option>
      </select>
    </aside>

    <component :is="components[selected]" :type="type" :is-lv="state"> </component>
  </main>
</template>

<script setup lang="ts">
import Calculator from '@/components/Calculator.vue'
import CalculatorV2 from '@/components/CalculatorV2.vue'
import Singles from '@/components/Singles.vue'
import { computed, ref } from 'vue'

const selected = ref('calc2')
const type = ref('')
const state = ref(false)

interface Comp {
  [key: string]: any
}

const components: Comp = {
  calc: Calculator,
  calc2: CalculatorV2,
  single: Singles
}

const model = computed({
  get: () => selected.value,
  set: (value) => {
    if (value.includes(':')) {
      const [name, calc] = value.split(':')
      selected.value = name
      type.value = calc
      state.value = calc === 'lv'
    } else {
      selected.value = value
      state.value = false
    }
  }
})
</script>
