<script setup>
import { computed } from 'vue'
import SidebarMenu from '@/components/Navigation/LeftSide/SidebarMenu.vue'
import statusClasses from '@/components/Navigation/LeftSide/statusClasses.js'
import { z } from 'zod'

const RoomValidationSchema = z.object({
  name: z.string().min(2),
  width: z.number().gt(0),
  height: z.number().gt(0),
  length: z.number().gt(0),
  current: z.boolean(),
  enabled: z.boolean()
})

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const setCurrentRoom = (index) => {
  model.value.rooms.forEach((room, k) => {
    room.current = k === index
  })
}

const steps = computed(() => {
  const rooms = props.modelValue.rooms
    .map((room, key) => {
      const validate = (room, key) => {
        if (model.value.step === 'rooms.' + key) {
          return statusClasses.current
        }

        if (RoomValidationSchema.safeParse(room).success) {
          return statusClasses.valid
        }

        return statusClasses.invalid
      }

      return {
        key: key,
        title: room.name || 'Zimmer',
        enabled: room.enabled,
        valid_class: validate(room, key),
        action: (index) => {
          model.value.step = 'rooms.' + index
          setCurrentRoom(index)
        }
      }
    })
    .filter((r) => r.enabled)

  return rooms
})
</script>

<template>
  <SidebarMenu :action="() => (model.step = 'rooms')" title="Räume" :steps="steps"></SidebarMenu>
</template>
