<template v-if="!isCurrentStepInTotals">
  <div class="flex flex-col justify-center gap-8">
    <h2 class="text-2xl text-primary">
      Es scheint, dass Sie keine Optionen ausgewählt haben, sodass wir die Berechnung nicht
      durchführen können. Bitte überprüfen Sie Ihre Eingaben oder entfernen Sie diese Dienstleistung
      aus der Liste.
    </h2>
    <div class="w-full">
      <button
        class="btn btn--light px-10 py-3 text-xl"
        @click="($event) => $event.view.location.reload()"
      >
        Zurück
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue'

const props = defineProps({
  model: {},
  totals: {},
  isCurrentStepInTotals: Boolean
})

const model = computed({
  get: () => props.model,
  set: (v) => emit('update:modelValue', v)
})
</script>
