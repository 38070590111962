<script setup>
import { computed, defineEmits, defineProps } from 'vue'
import useEmitter from '@/composables/useEmitter.js'

const $emit = defineEmits(['update:modelValue'])

const emitter = useEmitter()

const props = defineProps({
  rooms: Array,
  all: {
    type: Boolean,
    default: false
  },
  checked: {
    type: String,
    default: 'unset'
  },
  onCreateAction: {
    type: Function,
    default: null
  },
  modelValue: {}
})

const setModel = (value) => {
  $emit('update:modelValue', value)
}

const currentRooms = computed(() => {
  const rooms = []

  if (props.all) {
    rooms.push({ label: 'Alle', value: 'all', checked: props.checked })
  }

  props.rooms
    .filter((i) => i.enabled)
    .map((room) => {
      return {
        checked: room.checked,
        label: room.name,
        value: room.id,
        width: room.width,
        length: room['length']
      }
    })
    .forEach((room) => {
      rooms.push(room)
    })

  return rooms
})

const addNewRoom = () => {
  if (props.onCreateAction) {
    props.onCreateAction()
  } else emitter.emit('calc:add-room')
}
</script>
<template>
  <div class="relative block">
    <ul class="flex flex-wrap gap-4">
      <li
        v-for="i in currentRooms"
        @click.prevent="() => setModel(i.value)"
        class="relative grow rounded border-2 px-7 py-2 text-center hover:cursor-pointer"
        :class="{
          'border-white bg-white text-black': modelValue == i.value || i.checked,
          'border-action dark:text-white': modelValue != i.value && !i.checked,
          'font-bold': modelValue == i.value,
          '!text-red-500': !i.width || !i['length']
        }"
      >
        {{ i.label }}
        <span class="text-xs font-normal">{{
          !i.width || !i['length'] ? `(fehlende Größe)` : ''
        }}</span>
      </li>

      <button type="button" class="flex flex-row items-center dark:text-white" @click="addNewRoom">
        <span
          class="mr-3 flex h-4 w-4 items-center justify-center rounded-full bg-action p-0 not-italic text-black"
          >+</span
        >
        <span>Zimmer hinzufügen</span>
      </button>
    </ul>
  </div>
</template>
