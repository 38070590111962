<script setup>
import { computed } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Quality from '@/components/Inputs/Quality.vue'
import AmountInput from '../Inputs/AmountInput.vue'

import infos from '@/data/infos/services/doors'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})
</script>

<template>
  <div>
    <FormGroup
      info-title="Türen erneuern"
      :info="infos.renew"
      label="Sollen Türen erneuert werden?"
      name="renew"
    >
      <template #right>
        <Switch v-model="model.renew.enabled" />
      </template>
      <FormGroup
        label="Wie viele Türen sollen insgesamt erneuert werden?"
        class="mt-5"
        v-if="model.renew.enabled"
      >
        <template #right>
          <AmountInput name="amount" v-model="model.renew.amount" class="w-16 px-2" />
        </template>

        <Quality class="mt-10" name="quality" v-model="model.renew.quality" />
      </FormGroup>
    </FormGroup>

    <FormGroup
      info-title="Türen lackieren und aufbereiten"
      :info="infos.lack"
      label="Sollen bestehende Türen lackiert und aufbereitet werden?"
      name="lack"
    >
      <template #right>
        <Switch v-model="model.lack.enabled" />
      </template>
      <FormGroup
        label="Wie viele Türen sollen lackiert werden?"
        class="mt-5"
        v-if="model.lack.enabled"
      >
        <template #right>
          <AmountInput name="amount" v-model="model.lack.amount" class="w-16 px-2" />
        </template>
      </FormGroup>
    </FormGroup>

    <FormGroup
      info-title="Eingangstür erneuern"
      :info="infos.entranceDoor"
      label="Möchten Sie eine neue Eingangstür?"
      name="entranceDoor"
    >
      <template #right>
        <Switch v-model="model.entranceDoor.enabled" />
      </template>

      <template v-if="model.entranceDoor.enabled">
        <Quality class="mt-10" v-model="model.entranceDoor.quality" />
      </template>
    </FormGroup>
  </div>
</template>
