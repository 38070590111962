<script setup>
import { ref, watch } from 'vue'

const props = defineProps({
  modelValue: {},
  name: {
    type: String,
    default: 'amount'
  }
})

const emit = defineEmits(['update:modelValue'])

const unformatNumber = (value) => {
  return parseInt(value + '').toString()
}

const v = ref(unformatNumber(props.modelValue))

const formatNumber = () => {
  const value = parseInt(v.value || 0)

  v.value = unformatNumber(value)

  emit('update:modelValue', value)
}

const selectAllText = (event) => {
  event.target.select()
}

watch(
  () => props.modelValue,
  (value) => {
    v.value = unformatNumber(value)
  }
)
</script>

<template>
  <input
    v-model="v"
    :name="name"
    type="number"
    class="input no-spinners"
    @blur="formatNumber"
    @focus="selectAllText"
    @keyup.enter="formatNumber"
  />
</template>
