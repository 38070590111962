<script setup>
import { debounce } from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Range from '@/components/Inputs/Range.vue'
import Headline from '@/components/Elements/Headline.vue'

import Square from '@/components/Icons/RoomSizes/Square.vue'
import Less from '@/components/Icons/RoomSizes/Less.vue'
import Right from '@/components/Icons/RoomSizes/Right.vue'
import Corner from '@/components/Icons/RoomSizes/Corner.vue'
import FormattedInput from '@/components/Inputs/FormattedInput.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const form = ref(props.modelValue)

const currentQmWallCeiling = computed(() => {
  return (
    (form.value.areas.walls + form.value.areas.ceiling - form.value.less_qm)
      .toFixed(2)
      .replace('.', ',') + ' m²'
  )
})

const currentPerimeter = computed(() => {
  return form.value.areas.perimeter.toFixed(2).replace('.', ',') + ' m'
})

const currentQmFloor = computed(() => {
  return form.value.areas.floor.toFixed(2).replace('.', ',') + ' m²'
})

const calcFloor = () => {
  let x = form.value.width * form.value['length']

  if (form.value.shape === 'less') {
    x -= form.value.corner.width * form.value.corner['length']
  }

  if (form.value.shape === 'right') {
    x += form.value.corner.width * form.value.corner['length']
  }

  if (form.value.shape === 'corner') {
    x -= form.value.corner.width * form.value.corner['length']
  }

  form.value.areas.floor = x
}

const calcCeiling = () => {
  form.value.areas.ceiling = form.value.areas.floor
}

const calcPerimeter = () => {
  let x = form.value.width * 2 + form.value['length'] * 2

  if (form.value.shape === 'less') {
    x += form.value.corner['length'] * 2
  }

  if (form.value.shape === 'right') {
    x += form.value.corner['length'] * 2
  }

  if (form.value.shape === 'corner') {
    //
  }

  form.value.areas.perimeter = x
}

const calcWalls = () => {
  let wall1 = form.value.width * form.value.height
  let wall2 = form.value['length'] * form.value.height

  let cornerWall1 = form.value.corner.width * form.value.height
  let cornerWall2 = form.value.corner['length'] * form.value.height

  let x = (wall1 + wall2) * 2

  if (form.value.shape === 'less') {
    x -= cornerWall1 * 2
  }

  if (form.value.shape === 'right') {
    x += cornerWall1 * 2
  }

  if (form.value.shape === 'corner') {
    x -= -cornerWall1 + cornerWall2
  }

  form.value.areas.walls = x
}

const sizes = computed(() => {
  return `Wand- und Deckenfläche: ${currentQmWallCeiling.value}, Bodenfläche: ${currentQmFloor.value}, Umfang: ${currentPerimeter.value}`
})

const recalc = () => {
  calcPerimeter()
  calcFloor()
  calcCeiling()
  calcWalls()
}

watch(
  () => props.modelValue,
  (v) => {
    form.value = v
    recalc()
  },
  { deep: true }
)

watch(
  form,
  (v) => {
    recalc()
  },
  { deep: true }
)

watch(
  form,
  debounce((v) => {
    model.value = v
  }, 800),
  { deep: true }
)

onMounted(() => {
  recalc()
})
</script>

<template>
  <div>
    <Headline title="Aktuelle Größen" :subtitle="sizes"></Headline>

    <section class="relative mb-4">
      <FormattedInput
        inputClass="rounded-b-none"
        :tabIndex="1"
        class="absolute bottom-[1.9%] left-1/2 w-36 -translate-x-1/2"
        v-model="form.width"
        :placeholder="form.width"
      />

      <FormattedInput
        inputClass="rounded-l-none"
        :tabIndex="2"
        class="absolute left-[1.3%] top-1/2 w-36 -translate-y-1/2"
        v-model="form['length']"
        :placeholder="form['length']"
      />

      <template v-if="form.shape === 'square'">
        <Square class="text-action" :width="2" />
      </template>

      <template v-if="form.shape === 'less'">
        <Less class="text-action" :width="2" />

        <FormattedInput
          inputClass="rounded-b-none"
          class="absolute right-5 top-[20.5%] w-24"
          v-model="form.corner['length']"
          :placeholder="form.corner['length']"
        />

        <FormattedInput
          inputClass="rounded-r-none"
          class="absolute right-[26%] top-1/2 w-24 -translate-y-1/2"
          v-model="form.corner.width"
          :placeholder="form.corner.width"
        />
      </template>

      <template v-if="form.shape === 'right'">
        <Right class="text-action" :width="2" />

        <FormattedInput
          inputClass="rounded-b-none"
          class="absolute right-5 top-[18.3%] w-24"
          v-model="form.corner['length']"
          :placeholder="form.corner['length']"
        />

        <FormattedInput
          inputClass="rounded-r-none"
          class="absolute right-[-.3%] top-1/2 w-24 -translate-y-1/2"
          v-model="form.corner.width"
          :placeholder="form.corner.width"
        />
      </template>

      <template v-if="form.shape === 'corner'">
        <Corner class="text-action" :width="2" />

        <FormattedInput
          inputClass="rounded-t-none"
          class="absolute right-5 top-[29.4%] w-24"
          v-model="form.corner['length']"
          :placeholder="form.corner['length']"
        />

        <FormattedInput
          inputClass="rounded-r-none"
          class="absolute right-[28%] top-10 w-24"
          v-model="form.corner.width"
          :placeholder="form.corner.width"
        />
      </template>
    </section>

    <FormGroup label="Wie hoch ist der Raum?">
      <Range
        v-model="form.height"
        label="Höhe"
        :step="0.1"
        name="height"
        :min="1.5"
        :max="5"
        suffix="m"
      />
    </FormGroup>
  </div>
</template>
