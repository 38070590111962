<template>
  <Listbox v-model="model" multiple :name="name">
    <ListboxOptions static as="div" class="flex flex-wrap justify-items-stretch gap-4">
      <ListboxOption
        :key="o.value"
        :value="o.value"
        as="template"
        v-for="o in options"
        v-slot="{ selected }"
      >
        <button
          type="button"
          class="radio-item"
          :class="{
            'border-primary bg-primary font-bold text-white dark:border-white dark:bg-white dark:text-black':
              selected,
            '!text-red-500': !o.width || !o['length']
          }"
        >
          <span>{{ o.label }}</span>
          <span class="ml-1 text-xs font-normal">{{
            !o.width || !o['length'] ? `(fehlende Größe)` : ''
          }}</span>
        </button>
      </ListboxOption>

      <slot name="after" />
    </ListboxOptions>
  </Listbox>
</template>

<script setup>
import { Listbox, ListboxOptions, ListboxOption } from '@headlessui/vue'

import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Array,
    default: () => []
  },
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  options: {
    type: Array,
    default: () => []
  }
})

const $emit = defineEmits(['update:modelValue'])

const model = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    $emit('update:modelValue', value)
  }
})
</script>
