export const renew = `<p><strong>W&auml;rmed&auml;mmverbundsysteme (WDVS) mit Riemchen vs. WDVS mit Putz</strong></p>
<p>Ein W&auml;rmed&auml;mmverbundsystem ist eine Methode zur thermischen Isolierung von Geb&auml;udeau&szlig;enw&auml;nden. Es besteht grunds&auml;tzlich aus D&auml;mmstoffplatten, die auf die Au&szlig;enwand aufgebracht und mit einem Oberfl&auml;chenschutz versehen werden. Dabei gibt es zwei popul&auml;re Varianten der Oberfl&auml;chengestaltung: die Riemchenfassade und die Putzfassade.</p>
<p><strong>WDVS mit Riemchen:</strong> Riemchen sind d&uuml;nne Ziegel oder Klinkerpl&auml;ttchen, die als dekorative Elemente bei der Gestaltung von Fassaden verwendet werden. Bei einem WDVS mit Riemchen werden diese nach der Anbringung der D&auml;mmplatte montiert. Die Riemchenfassade bietet nicht nur einen optischen Vorteil, indem sie die &Auml;sthetik einer traditionellen Klinkerfassade nachahmt, sondern sie sch&uuml;tzt auch die D&auml;mmschicht vor mechanischen Sch&auml;den und Witterungseinfl&uuml;ssen. Diese Art von Fassadenbekleidung ist besonders unterhaltungsarm und langlebig.</p>
<p><strong>WDVS mit Putz:</strong> Bei einem WDVS mit Putz wird &uuml;ber die D&auml;mmstoffplatten ein spezieller Armierungsputz aufgetragen, in den oft ein Armierungsgewebe eingelegt wird, um Risse zu verhindern und zus&auml;tzliche Stabilit&auml;t zu verleihen. Dar&uuml;ber kommt der eigentliche Oberputz, welcher in verschiedenen Strukturen und Farben verf&uuml;gbar ist und dem Geb&auml;ude eine individuelle Optik verleiht. Eine Putzfassade ist leichter zu realisieren und in der Regel g&uuml;nstiger als eine Riemchenfassade, ben&ouml;tigt jedoch regelm&auml;&szlig;ige Anstriche bzw. Instandhaltung.</p>
<p><strong>Vergleich von WDVS und Einblasd&auml;mmung:</strong></p>
<p>Das W&auml;rmed&auml;mmverbundsystem ist eine aufgebrachte D&auml;mmschicht, die oft Polystyrol, Mineralwolle oder Resol-Hartschaum umfasst. Diese D&auml;mmung wird fest mit der Au&szlig;enwand verbunden und bildet zusammen mit der Oberfl&auml;chenbeschichtung eine nahtlose D&auml;mmschicht, die sowohl W&auml;rmeschutz als auch, je nach Bedarf, einen gewissen Schallschutz bietet. WDVS sind von au&szlig;en sichtbar und ver&auml;ndern das Erscheinungsbild des Geb&auml;udes.</p>
<p>Die Einblasd&auml;mmung hingegen ist eine Technik, bei der isolierendes Material wie z.B. Zellulose, Mineralwolle oder expandiertes Polystyrol in Zwischenr&auml;ume oder Hohlr&auml;ume von W&auml;nden, D&auml;chern oder Decken eingeblasen wird. Dies wird oft bei der Nachr&uuml;stung von Altbauten angewandt, bei denen Hohlr&auml;ume vorhanden sind. Die Methode ist unsichtbar, nachdem sie einmal angewandt wurde, und eignet sich besonders gut, um ohne gr&ouml;&szlig;ere Bauma&szlig;nahmen von au&szlig;en oder innen den W&auml;rmeschutz eines Hauses zu verbessern.</p>
<p>W&auml;hrend ein WDVS insbesondere bei Neubauten oder umfangreichen Sanierungsprojekten bevorzugt wird, bietet sich die Einblasd&auml;mmung vor allem dann an, wenn eine effektive D&auml;mmung ohne gr&ouml;&szlig;ere Ver&auml;nderung an der Fassade gew&uuml;nscht ist. Beide Methoden verbessern die Energieeffizienz eines Geb&auml;udes, reduzieren Heizkosten und tragen zum Klimaschutz bei, unterscheiden sich jedoch in ihren Anwendungsgebieten, ihrer Optik und der Art der Installation.</p>`

export default {
  renew
}
