export const baseTypeOptions = [
  { label: 'Wohnung', value: 'apartment' },
  { label: 'Haus', value: 'house' }
]

export const baseFloorOptions = [
  { label: 'Souterrain', value: 'su_terrain' },
  { label: 'Erdgeschoss', value: 'ground' },
  { label: 'Etagenwohnung', value: 'apartment' },
  { label: 'Dachgeschoss', value: 'roof' },
  { label: 'Penthouse', value: 'penthouse' }
]

export const baseBuiltOptions = [
  { label: 'vor 1940', value: 'before_1940' },
  { label: '1941 - 1980', value: '1941_1980' },
  { label: '1981 - heute', value: 'after_1981' }
]

export const baseFloorsOptions = [
  { label: 'Kellergeschoss', value: 'basement' },
  { label: 'Erdgeschoss', value: 'ground' },
  { label: '1. Stock', value: 'floor_1' },
  { label: '2. Stock', value: 'floor_2' },
  { label: '3. Stock', value: 'floor_3' },
  { label: 'Dachgeschoss', value: 'attic' }
]

export const baseHouseTypeOptions = [
  { label: 'Einfamilienhaus', value: 'single' },
  { label: 'Reihenhaus', value: 'row' },
  { label: 'Reihenendhaus', value: 'rowend' },
  { label: 'Doppelhaushälfte', value: 'double' }
]

export const baseBuiltTypeOptions = [
  { label: 'Massiv', value: 'massiv' },
  { label: 'Holzständerbauweise', value: 'wood' },
  { label: 'Sonstiges', value: 'other' }
]
