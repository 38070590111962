<script setup>
import { computed } from 'vue'
import SidebarMenu from '@/components/Navigation/LeftSide/SidebarMenu.vue'
import statusClasses from '@/components/Navigation/LeftSide/statusClasses.js'
import { z } from 'zod'

const ApartmentValidationSchema = z.object({
  postal_code: z.string().min(2),
  size: z.number().gt(0),
  floor: z.string().min(1),
  built: z.string().min(1)
})

const HouseValidationSchema = z.object({
  postal_code: z.string().min(2),
  usage: z.number().gt(0),
  cellar: z.number(),
  floors: z.array(z.string().min(1)),
  house_type: z.string().min(1),
  built_type: z.string().min(1),
  built: z.preprocess(Number, z.number())
})

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const setCurrentRoom = (index) => {
  model.value.rooms.forEach((room, k) => {
    room.current = k === index
  })
}

const steps = computed(() => {
  const validate = () => {
    if (model.value.step === 'base') {
      return statusClasses.current
    }

    if (
      model.value.base.type === 'house' &&
      HouseValidationSchema.safeParse(model.value.base).success
    ) {
      return statusClasses.valid
    }

    if (
      model.value.base.type === 'apartment' &&
      ApartmentValidationSchema.safeParse(model.value.base).success
    ) {
      return statusClasses.valid
    }

    return statusClasses.invalid
  }

  const objectItem = {
    title: 'Objekt',
    valid_class: validate(),
    action: () => {
      model.value.step = 'base'
    }
  }

  return [objectItem]
})
</script>

<template>
  <SidebarMenu
    :action="() => (model.step = 'base')"
    title="Grunddaten"
    :steps="steps"
  ></SidebarMenu>
</template>
