import { getImageUrl } from '@/utils/image'

export const roofTypeOptions = [
  { label: 'Dach erneuern inkl. Dämmung', value: 'renew' },
  { label: 'nur Dämmung', value: 'insulation' }
]

export const roofInsulationLayer = [
  { label: '20cm', value: 20 },
  { label: '30cm', value: 30 }
]

export const roofFormsOptions = [
  { label: 'Satteldach', value: 'saddle', image: getImageUrl('/images/calc/roof/saddle.png') },
  { label: 'Pultdach', value: 'shed', image: getImageUrl('/images/calc/roof/shed.png') },
  { label: 'Walmdach', value: 'hip', image: getImageUrl('/images/calc/roof/hip.png') },
  { label: 'Flachdach', value: 'flat', image: getImageUrl('/images/calc/roof/flat.png') }
  // Spitzdach = Zeltdach
  // { label: 'Spitzdach', value: 'pointed', image: getImageUrl('/images/calc/roof/pointed.png') }
]

export const roofLayerOptions = [
  { label: 'Betonziegel', value: 'concreteTile' },
  { label: 'Tondachpfanne', value: 'clayTile' },
  { label: 'Bitum', value: 'bitumen' },
  { label: 'Dachfolie', value: 'film' }
]
