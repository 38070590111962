<script setup>
import { computed } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import AmountInput from '../Inputs/AmountInput.vue'

import infos from '@/data/infos/services/pipes'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})
</script>

<template>
  <div>
    <FormGroup
      info-title="Versorgungsleitungen erneuern"
      :info="infos.renew"
      label="Sollen Versorgungsleitungen erneuert werden?"
      name="renew"
    >
      <template #right>
        <Switch v-model="model.renew.enabled" />
      </template>
      <FormGroup
        label="Wie viele Steigleitungen haben Sie?"
        class="mt-5"
        v-if="model.renew.enabled"
      >
        <template #right>
          <AmountInput v-model="model.renew.amount" name="amount" class="w-16 px-2" />
        </template>
      </FormGroup>
    </FormGroup>
  </div>
</template>
