export const securityBox = `<p>Unterschiedliche Sicherungsk&auml;sten und Anzeichen f&uuml;r deren Austausch</p>
<p>Sicherungsk&auml;sten, auch als Verteilerk&auml;sten oder Stromk&auml;sten bekannt, spielen eine entscheidende Rolle in der elektrischen Installation eines Geb&auml;udes. Sie sch&uuml;tzen elektrische Schaltkreise vor &Uuml;berlast und Kurzschl&uuml;ssen, indem sie den Stromfluss bei Problemen unterbrechen. Es gibt verschiedene Arten von Sicherungsk&auml;sten, die je nach Alter der Installation, den Anforderungen des Geb&auml;udes und den geltenden Sicherheitsvorschriften variieren.</p>
<ol type="1">
<li>Alte Sicherungsk&auml;sten mit Schmelzsicherungen: In &auml;lteren Geb&auml;uden befinden sich oft noch Sicherungsk&auml;sten mit Schmelzsicherungen, bei denen ein Metallfaden durchbrennt, wenn die Stromst&auml;rke zu hoch wird. Diese &auml;lteren Systeme sind nicht so benutzerfreundlich und k&ouml;nnen Sicherheitsrisiken darstellen, da nicht immer klar ist, ob eine Sicherung durchgebrannt ist, und weil manchmal eine falsche Sicherung ausgew&auml;hlt wird, was ein Brandrisiko darstellen kann.</li>
</ol>
<ol start="2" type="1">
<li>Moderne Sicherungsk&auml;sten mit Leitungsschutzschaltern (LS-Schalter): Moderne Sicherungsk&auml;sten verwenden in der Regel automatische Leitungsschutzschalter (auch bekannt als Circuit Breaker), die den Stromkreis bei &Uuml;berlastung oder Kurzschluss automatisch ausschalten. Diese Schalter sind deutlich benutzerfreundlicher und sicherer, weil sie schnell und einfach zur&uuml;ckgesetzt werden k&ouml;nnen und deutlich anzeigen, welcher Schalter ausgel&ouml;st hat.</li>
</ol>
<ol start="3" type="1">
<li>Sicherungsk&auml;sten mit Fehlerstrom-Schutzschaltern (FI-Schalter bzw. RCD): FI-Schalter sind dazu da, Fehlerstr&ouml;me zu erkennen, die beispielsweise durch besch&auml;digte Kabel oder fehlerhafte Ger&auml;te verursacht werden. Ein FI-Schalter schaltet bei einem Fehlerstrom von wenigen Milliampere innerhalb k&uuml;rzester Zeit die betroffene Stromleitung ab und sch&uuml;tzt somit vor elektrischen Schl&auml;gen.</li>
</ol>
<p>Anzeichen daf&uuml;r, dass ein neuer Sicherungskasten installiert werden sollte, k&ouml;nnen vielf&auml;ltig sein:</p>
<ul type="disc">
<li>Alter: Wenn der Sicherungskasten sehr alt ist und noch Schmelzsicherungen verwendet, ist ein Austausch aus Sicherheitsgr&uuml;nden ratsam.</li>
</ul>
<ul type="disc">
<li>H&auml;ufiges Ausl&ouml;sen: Wenn Sicherungen h&auml;ufig ohne erkennbaren Grund ausl&ouml;sen, k&ouml;nnte das ein Hinweis auf &Uuml;berlastung oder ein zugrundeliegendes elektrisches Problem sein.</li>
</ul>
<ul type="disc">
<li>Fehlende Kapazit&auml;t: Beim Hinzuf&uuml;gen neuer elektrischer Ger&auml;te oder bei Erweiterungen des Hauses k&ouml;nnte der alte Sicherungskasten nicht gen&uuml;gend Kapazit&auml;t oder freie Pl&auml;tze f&uuml;r zus&auml;tzliche Schaltkreise bieten.</li>
</ul>
<ul type="disc">
<li>Kein FI-Schutz: Modernisierungsbedarf besteht insbesondere, wenn ein &auml;lteres Elektroinstallationssystem keinen Fehlerstrom-Schutzschalter hat.</li>
</ul>
<ul type="disc">
<li>Sichtbare Sch&auml;den: Rost, Korrosion oder andere sichtbare Sch&auml;den am Sicherungskasten selbst oder an den darin befindlichen Bauteilen sind ein klares Zeichen, dass der Kasten ersetzt werden muss.</li>
</ul>
<ul type="disc">
<li>Nicht konform mit aktuellen Normen: Ein Sicherungskasten, der nicht den aktuellen Sicherheitsnormen entspricht (z.B. IEC-Normen), sollte ausgetauscht werden, um Risiken zu minimieren und die Sicherheit zu erh&ouml;hen.</li>
</ul>
<p>Es ist stets empfehlenswert, eine Installation oder Erneuerung von Sicherungsk&auml;sten durch eine qualifizierte Elektrofachkraft durchf&uuml;hren zu lassen, um sicherzustellen, dass alles korrekt installiert wird und den g&uuml;ltigen Sicherheitsrichtlinien entspricht.</p>`

export const heatPump = `<p>Ein Sicherungskasten, auch Verteilerkasten oder Elektroverteiler genannt, ist das zentrale Element in einem Geb&auml;ude, das den elektrischen Strom aus dem Netz der Energieversorgung in einzelne Stromkreise aufteilt. Bei der Installation einer W&auml;rmepumpe kann es notwendig sein, den bestehenden Sicherungskasten anzupassen oder einen zus&auml;tzlichen Sicherungskasten zu installieren. Hier sind einige Gr&uuml;nde daf&uuml;r:</p>
<ol type="1">
<li>Kapazit&auml;t des bestehenden Sicherungskastens: Ein bestehender Sicherungskasten ist dimensioniert f&uuml;r die vorhandene elektrische Last des Geb&auml;udes. Die Installation einer W&auml;rmepumpe stellt eine signifikante zus&auml;tzliche Belastung dar. Wenn die vorhandene Kapazit&auml;t nicht ausreicht, kann dies zu &Uuml;berlastung und Ausfall des Systems f&uuml;hren. Daher muss sichergestellt werden, dass der Sicherungskasten die zus&auml;tzliche Last der W&auml;rmepumpe bew&auml;ltigen kann.</li>
</ol>
<ol start="2" type="1">
<li>Dedizierte Stromkreise: W&auml;rmepumpen ben&ouml;tigen in der Regel einen dedizierten Stromkreis mit entsprechenden Leistungsschutzschaltern, da sie hohe Anlaufstr&ouml;me aufweisen k&ouml;nnen. Dies gew&auml;hrleistet nicht nur die zuverl&auml;ssige Funktion, sondern auch die Sicherheit des Systems. Der bestehende Sicherungskasten verf&uuml;gt m&ouml;glicherweise nicht &uuml;ber gen&uuml;gend freie Pl&auml;tze f&uuml;r diese zus&auml;tzlichen Schutzschalter.</li>
</ol>
<ol start="3" type="1">
<li>Anforderungen an den Fehlerstromschutz: F&uuml;r W&auml;rmepumpen k&ouml;nnen spezielle Arten von Fehlerstrom-Schutzschaltern (RCDs) erforderlich sein, um Personen vor m&ouml;glichen elektrischen Schl&auml;gen zu sch&uuml;tzen und die Anlage vor Fehlerstr&ouml;men zu sichern. Bestehende Sicherungsk&auml;sten sind m&ouml;glicherweise nicht mit solchen RCDs ausgestattet, was eine Erweiterung oder einen neuen Sicherungskasten erfordert.</li>
</ol>
<ol start="4" type="1">
<li>Schutzklassen und Vorschriften: Eine W&auml;rmepumpe muss gem&auml;&szlig; den jeweils geltenden Elektroinstallation-Vorschriften und Normen abgesichert sein. &Auml;ltere Sicherungsk&auml;sten entsprechen m&ouml;glicherweise nicht den aktuellen Sicherheitsstandards oder den speziellen Anforderungen f&uuml;r die Absicherung von W&auml;rmepumpen.</li>
</ol>
<ol start="5" type="1">
<li>Anschlussm&ouml;glichkeiten f&uuml;r erweiterte Funktionen: W&auml;rmepumpen k&ouml;nnen &uuml;ber intelligente Steuerungen verf&uuml;gen, die mit zus&auml;tzlichen Sensoren, externen Thermostaten und Kommunikationsger&auml;ten verbunden sein m&uuml;ssen. Ein neuer oder angepasster Sicherungskasten kann diese zus&auml;tzlichen Anschlussm&ouml;glichkeiten bieten.</li>
</ol>
<ol start="6" type="1">
<li>Energieeffizienz und Lastmanagement: Moderne W&auml;rmepumpen k&ouml;nnen Teil eines intelligenten Energiemanagementsystems sein, das Lasten aktiv steuern kann, um Energiekosten zu sparen und Netzbelastungen zu optimieren. Hierf&uuml;r k&ouml;nnen spezielle Mess- und Regelinstrumente im Sicherungskasten notwendig sein.</li>
</ol>
<p>Um die Sicherheit, Effizienz und Konformit&auml;t mit technischen Vorschriften zu gew&auml;hrleisten, sollte die Entscheidung &uuml;ber die Erweiterung oder den Austausch des Sicherungskastens von einem qualifizierten Elektrofachmann getroffen werden, der die Gesamtanforderungen der W&auml;rmepumpe und der bestehenden elektrischen Infrastruktur beurteilen kann.</p>`

export const electricalWiring = `<p>Die Erneuerung der Stromleitungen in einer Immobilie ist eine wichtige Ma&szlig;nahme zur Gew&auml;hrleistung der Sicherheit und Effizienz eines elektrischen Systems. Es gibt mehrere Gr&uuml;nde, warum und wann eine solche Erneuerung notwendig werden kann:</p>
<ol type="1">
<li>Alterung und Verschlei&szlig;: Mit der Zeit k&ouml;nnen elektrische Leitungen durch st&auml;ndige Belastung und nat&uuml;rliche Alterungsprozesse verschlei&szlig;en. Isoliermaterialien k&ouml;nnen br&uuml;chig werden, was zu Kurzschl&uuml;ssen oder Stromausf&auml;llen f&uuml;hren kann. In der Regel gilt, dass Elektroinstallationen, die &auml;lter als 30 Jahre sind, einer genauen &Uuml;berpr&uuml;fung bed&uuml;rfen.</li>
</ol>
<ol start="2" type="1">
<li>Sicherheitsstandards: Technologische Entwicklungen und aktualisierte Sicherheitsvorschriften haben im Laufe der Jahre zu h&ouml;heren Sicherheitsstandards gef&uuml;hrt. &Auml;ltere Installationen entsprechen unter Umst&auml;nden nicht mehr den aktuellen Standards. Es ist daher empfehlenswert, regelm&auml;&szlig;ig eine fachliche &Uuml;berpr&uuml;fung durchf&uuml;hren zu lassen und die Elektrik gegebenenfalls zu erneuern.</li>
</ol>
<ol start="3" type="1">
<li>Erweiterte Kapazit&auml;t: Moderne Haushalte haben oft einen h&ouml;heren Strombedarf als fr&uuml;her. Die Hinzuf&uuml;gung neuer elektrischer Ger&auml;te oder der Ausbau von Wohnr&auml;umen kann die aktuellen Stromleitungen &uuml;berlasten. Um &Uuml;berhitzung oder &Uuml;berlastung zu vermeiden, sollten die elektrischen Systeme entsprechend der neuen Anforderungen erweitert und erneuert werden.</li>
</ol>
<ol start="4" type="1">
<li>Sicherheitsrisiken: Anzeichen von Problemen wie h&auml;ufige Sicherungsausf&auml;lle, flackernde Lichter, Ger&uuml;che von verbranntem Kunststoff oder sichtbare Besch&auml;digungen an Kabeln deuten darauf hin, dass eine sofortige &Uuml;berpr&uuml;fung und m&ouml;glicherweise eine Erneuerung erforderlich ist.</li>
</ol>
<p>Wann die Leitungen in einer Immobilie erneuert werden sollten, kann nicht pauschal festgelegt werden. Es h&auml;ngt von den oben genannten Faktoren und von der individuellen Situation der Installationen ab. Wir empfehlen die folgenden Zeitpunkte f&uuml;r die Bewertung einer m&ouml;glichen Erneuerung:</p>
<ul type="disc">
<li>Bei Kauf oder Verkauf einer Immobilie</li>
</ul>
<ul type="disc">
<li>Bei einer umfassenden Renovierung</li>
</ul>
<ul type="disc">
<li>Wenn zus&auml;tzliche elektrische Ger&auml;te hinzugef&uuml;gt werden sollen</li>
</ul>
<ul type="disc">
<li>Nach 30 Jahren Betrieb oder gem&auml;&szlig; der Empfehlung eines Fachmanns</li>
</ul>
<ul type="disc">
<li>Bei Anzeichen von Abnutzung oder defekten Komponenten</li>
</ul>
<p>Die Durchf&uuml;hrung einer Erneuerung sollte immer von einem qualifizierten Elektriker vorgenommen werden, der sicherstellt, dass alle Arbeiten den geltenden Vorschriften und Normen entsprechen. Eine moderne, gut gewartete Elektroinstallation tr&auml;gt nicht nur zur Sicherheit bei, sondern kann auch die Energieeffizienz verbessern und den Wert der Immobilie steigern.</p>`

export const dataCabinet = `<p>Ein Datenschrank, auch Netzwerkschrank oder Serverrack genannt, ist ein abschlie&szlig;barer Schrank, der in Unternehmen, &ouml;ffentlichen Einrichtungen und zunehmend auch in Privathaushalten verwendet wird, um Netzwerk- und Telekommunikationsger&auml;te sowie Server sicher und &uuml;bersichtlich unterzubringen. Zu den typischen Komponenten, die in einem Datenschrank installiert werden k&ouml;nnen, geh&ouml;ren Switches, Router, Patch-Panels, Verst&auml;rker, NAS-Systeme (Network-Attached Storage) und verschiedene Server.</p>
<p>Der Datenschrank sch&uuml;tzt die teure und empfindliche Hardware nicht nur vor physischen Einfl&uuml;ssen wie Staub, Schmutz oder unautorisiertem Zugriff, sondern auch vor elektromagnetischen Interferenzen. Dar&uuml;ber hinaus bietet er eine strukturierte Umgebung, in der Kabel und Komponenten ordentlich und systematisch angeordnet werden k&ouml;nnen, um eine optimale Luftzirkulation zu gew&auml;hrleisten und die Betriebstemperatur der Ger&auml;te niedrig zu halten. Dies tr&auml;gt zur Langlebigkeit der Hardware und zur Vermeidung von thermisch bedingten Ausf&auml;llen bei.</p>
<p>Um ein modernes und umfassendes Netzwerk zu Hause zu implementieren, wird heutzutage oft in allen Wohn- und Schlafr&auml;umen eine LAN-Dose (Local Area Network-Dose) verbaut. Diese Netzwerkdosen dienen als Anschlusspunkte f&uuml;r Endger&auml;te wie Computer, Smart-TVs, Spielekonsolen oder auch VoIP-Telefone (Voice over IP) und erm&ouml;glichen eine hochwertige, kabelgebundene Internetverbindung ohne die Stabilit&auml;ts- und Sicherheitsprobleme, die bei einer reinen WLAN-Nutzung auftreten k&ouml;nnen.</p>
<p>Die Verlegung von Netzwerkleitungen sowie das Einsetzen der LAN-Dosen w&auml;hrend einer Renovierung oder beim Neubau ist eine sinnvolle Investition in die digitale Infrastruktur des Hauses. Diese Ma&szlig;nahme bietet folgende Vorteile:</p>
<ol>
<li>Zuverl&auml;ssigkeit: Kabelgebundene Verbindungen sind typischerweise stabiler und weniger anf&auml;llig f&uuml;r St&ouml;rungen als drahtlose Verbindungen.</li>
</ol>
<ol>
<li>Geschwindigkeit: LAN-Dosen bieten die M&ouml;glichkeit, die vollst&auml;ndige Bandbreite des Internetanschlusses zu nutzen, insbesondere bei Anwendungen, die hohe &Uuml;bertragungsraten erfordern, wie Online-Gaming oder Streaming in hoher Aufl&ouml;sung.</li>
</ol>
<ol>
<li>Netzwerksicherheit: Physische Verbindungen sind schwerer abzuh&ouml;ren als drahtlose Verbindungen, was die Daten&uuml;bertragung sicherer macht.</li>
</ol>
<ol>
<li>Komfort: Direkte Anschl&uuml;sse in allen Zimmern bieten Flexibilit&auml;t bei der Einrichtung von Arbeitspl&auml;tzen und Unterhaltungsecken.</li>
</ol>
<p>Ein gut geplanter Datenschrank, zentral positioniert und mit einem sternf&ouml;rmig angelegten Netzwerk, das in jeden Raum mit einer LAN-Dose f&uuml;hrt, stellt das Herzst&uuml;ck der h&auml;uslichen Netzwerkinfrastruktur dar. Mit Blick in die Zukunft, in der immer mehr Ger&auml;te vernetzt werden und der Datenverkehr weiter zunimmt, ist die Investition in diese Technologie ein wichtiger Schritt, um eine zukunftssichere und funktionale Wohnumgebung zu gestalten.</p>`

export const electricalWiringRooms = `<p>Elektrische Leitungen sind eines der wichtigsten Bestandteile der Infrastruktur einer Immobilie. Sie sorgen nicht nur f&uuml;r die zuverl&auml;ssige Versorgung mit Strom, sondern sie spielen auch eine entscheidende Rolle f&uuml;r die Sicherheit der Bewohner. Bei einer Erneuerung der elektrischen Leitungen gibt es mehrere Gr&uuml;nde, warum es sinnvoll sein kann, alle Leitungen in allen R&auml;umen zu erneuern.</p>
<ol>
<li>Sicherheit: Die Sicherheitsstandards im Bereich der Elektroinstallation haben sich im Laufe der Zeit stark weiterentwickelt. Alte Leitungen und Sicherungssysteme entsprechen m&ouml;glicherweise nicht mehr den heutigen Anforderungen, was das Risiko f&uuml;r Kurzschl&uuml;sse, elektrische Schl&auml;ge und sogar Br&auml;nde erh&ouml;ht. Eine Kompletterneuerung gew&auml;hrleistet, dass das gesamte System den aktuellen Sicherheitsstandards entspricht.</li>
</ol>
<ol>
<li>Energieflexibilit&auml;t: Moderne Elektroger&auml;te und -installationen haben h&ouml;here Leistungsanforderungen als noch vor einigen Jahrzehnten. Indem man alle Leitungen in einem Zug erneuert, stellt man sicher, dass das Stromnetz in der Lage ist, den Anschluss neuer, leistungsst&auml;rkerer Ger&auml;te zu unterst&uuml;tzen und flexibel genug ist, um zuk&uuml;nftige technologische Entwicklungen zu bew&auml;ltigen.</li>
</ol>
<ol>
<li>Wertsteigerung der Immobilie: Eine Immobilie mit einem vollst&auml;ndig erneuerten und auf dem neuesten Stand befindlichen elektrischen System ist auf dem Markt wertvoller. F&uuml;r potenzielle K&auml;ufer oder Mieter ist es ein Zeichen daf&uuml;r, dass in naher Zukunft keine zus&auml;tzlichen Investitionen oder Instandhaltungsarbeiten im elektrischen Bereich anstehen.</li>
</ol>
<ol>
<li>Vermeidung von St&uuml;ckwerk: Das Erneuern einzelner Abschnitte kann zu einem Flickenteppich verschiedener Elektrik-Generationen f&uuml;hren. Dadurch kann es zu Inkompatibilit&auml;ten und unvorhersehbaren technischen Problemen kommen. Ein einheitliches Leitungssystem gew&auml;hrleistet hingegen eine harmonische und st&ouml;rungsfreie Funktion.</li>
</ol>
<ol>
<li>Effizienz bei der Durchf&uuml;hrung: Elektriker k&ouml;nnen effizienter arbeiten, wenn alle Leitungen auf einmal ersetzt werden. Das vereinfacht die Planung und Durchf&uuml;hrung der Arbeiten und kann sogar Kosten sparen, da nur ein einziger gro&szlig;er Auftrag ausgef&uuml;hrt werden muss anstatt mehrerer kleinerer.</li>
</ol>
<ol>
<li>Pr&auml;ventive Wartung: Die Erneuerung aller Leitungen erm&ouml;glicht eine pr&auml;ventive Wartung. Alte, noch funktionierende Leitungen k&ouml;nnen bereits abgenutzt sein und in naher Zukunft ausfallen. Mit dem kompletten Austausch aller Leitungen beugt man diesem Risiko systematisch vor.</li>
</ol>
<ol>
<li>Zukunftssicherheit: Die Integration von smarten Heimtechnologien, wie beispielsweise intelligenter Beleuchtung, Heizungssteuerung oder Sicherheitssystemen, erfordert ein robustes und modernes Elektriknetz. Mit einer vollst&auml;ndigen Erneuerung l&auml;sst sich die Immobilie leichter an solche Zukunftstechnologien anpassen.</li>
</ol>
<p>Abschlie&szlig;end l&auml;sst sich sagen, dass die Erneuerung aller elektrischen Leitungen in allen R&auml;umen einer Immobilie eine sinnvolle Investition ist, die zu mehr Sicherheit, besserer Funktionalit&auml;t sowie einer Wertsteigerung der Immobilie f&uuml;hrt und zukunftssichere Grundlagen schafft.</p>`

export const ceilingBurningPoint = `<p>Eine Deckenbrennstelle ist ein Anschlusspunkt f&uuml;r elektrische Leuchtmittel an der Decke eines Raumes. Sie wird auch als Deckenauslass oder einfach als Lampenanschluss bezeichnet. Hier wird eine Lampe oder ein anderer Beleuchtungsk&ouml;rper fest installiert und an das Stromnetz angeschlossen. Deckenbrennstellen sind zentraler Bestandteil der Raumbeleuchtung und tragen wesentlich zur Atmosph&auml;re sowie zur Funktionalit&auml;t eines Raumes bei.</p>
<p>Beim Verlegen, Erweitern oder Neu Anlegen einer Deckenbrennstelle sollten folgende Punkte beachtet werden:</p>
<ol>
<li><strong>Planung</strong>: Bevor man mit der Installation beginnt, sollte man genau planen, wo die Deckenbrennstelle positioniert werden soll. Dazu geh&ouml;ren &Uuml;berlegungen zur optimalen Ausleuchtung des Raumes, zu bestehenden M&ouml;belstellungen und zur &Auml;sthetik. Die Position sollte zudem so gew&auml;hlt werden, dass die Lampe nicht blendet und ein gleichm&auml;&szlig;iges Licht im Raum erzeugt.</li>
</ol>
<ol>
<li><strong>Elektrische Sicherheit</strong>: Die Arbeit mit elektrischen Installationen erfordert gro&szlig;es Fachwissen und sollte nur von qualifizierten Fachleuten durchgef&uuml;hrt werden. Es ist darauf zu achten, dass alle geltenden Sicherheitsvorschriften eingehalten werden, um Gefahren wie elektrische Schl&auml;ge oder Kurzschl&uuml;sse zu vermeiden.</li>
</ol>
<ol>
<li><strong>Stromversorgung</strong>: Sicherstellen, dass die vorhandene elektrische Infrastruktur das Verlegen einer neuen Deckenbrennstelle unterst&uuml;tzt. Das bedeutet, dass ausreichend Kapazit&auml;t im Stromkreis vorhanden sein muss, um zus&auml;tzliche Lasten zu tragen, und dass die Kabel den technischen Anforderungen entsprechen.</li>
</ol>
<ol>
<li><strong>Bauvorschriften</strong>: In vielen Regionen gibt es bestimmte Bauvorschriften oder Normen, die bei der Installation von elektrischen Systemen einzuhalten sind. Dies kann Abstandsregelungen, Art und Schutzklasse der verwendeten Materialien sowie die Installationsh&ouml;he betreffen.</li>
</ol>
<ol>
<li><strong>Schutzma&szlig;nahmen</strong>: In B&auml;dern oder anderen Feuchtr&auml;umen m&uuml;ssen spezielle Schutzma&szlig;nahmen wegen der h&ouml;heren Gefahr von Wasserkontakt getroffen werden. Hier sind oft besondere Schutzklassen f&uuml;r Leuchtmittel vorgeschrieben (IP-Klassifizierung).</li>
</ol>
<ol>
<li><strong>Bestehende Verkabelung</strong>: Die vorhandene Verkabelung und die Position der Stromleitungen m&uuml;ssen vor dem Verlegen einer Deckenbrennstelle genau lokalisiert werden, um Besch&auml;digungen zu vermeiden und sicherzustellen, dass keine elektrischen Leitungen oder andere Versorgungsleitungen durchtrennt werden.</li>
</ol>
<ol>
<li><strong>Schalten und Steuern</strong>: Es sollte &uuml;berlegt werden, ob die neue Deckenbrennstelle &uuml;ber einen eigenen Lichtschalter verf&uuml;gt oder &uuml;ber einen bereits bestehenden geschaltet werden soll. Zudem kann die Einbindung in ein Smart-Home-System erwogen werden, falls eine automatisierte oder ferngesteuerte Lichtsteuerung gew&uuml;nscht ist.</li>
</ol>
<ol>
<li><strong>Kabel- und Anschlussarten</strong>: Die Auswahl der korrekten Kabel- und Anschlusstypen, sowohl hinsichtlich der Leistungsf&auml;higkeit als auch der Kompatibilit&auml;t mit dem Lampentyp, ist notwendig, um eine korrekte Funktion und langfristige Sicherheit zu gew&auml;hrleisten.</li>
</ol>
<p>Wer keine ausreichenden Kenntnisse in Elektrotechnik hat, sollte f&uuml;r solche Arbeiten immer einen qualifizierten Elektriker beauftragen, um Risiken und Gefahren f&uuml;r sich selbst und das Geb&auml;ude zu minimieren. Die fachgerechte Installation gew&auml;hrleistet nicht nur die Sicherheit, sondern auch die Funktionalit&auml;t und Langlebigkeit der Deckenbrennstelle.</p>`

export const dimmer = `<p>Ein Dimmer bietet die M&ouml;glichkeit, die Helligkeit der Beleuchtung in einem Raum individuell zu steuern und damit die gew&uuml;nschte Atmosph&auml;re zu schaffen. Beim Einbau eines Dimmers sind allerdings einige wichtige Aspekte zu beachten, um sowohl Sicherheit als auch Funktionalit&auml;t zu garantieren. Im Folgenden finden Sie eine &Uuml;bersicht &uuml;ber die wichtigsten Schritte und Hinweise, die Sie beim Einbau eines Dimmers ber&uuml;cksichtigen sollten.</p>
<p><strong>Passenden Dimmertyp ausw&auml;hlen:</strong></p>
<p>Es gibt verschiedene Arten von Dimmern, die sich nach der Art der Beleuchtung richten, die Sie dimmen wollen. Die gel&auml;ufigsten sind Phasenabschnittdimmer f&uuml;r LED-Lampen und elektronische Transformatoren, sowie Phasenanschnittdimmer f&uuml;r Gl&uuml;hlampen, Hochvolt-Halogenlampen und konventionelle Transformatoren. Vergewissern Sie sich vor dem Kauf, dass der Dimmer mit Ihren Leuchtmitteln kompatibel ist.</p>
<p><strong>Kompatibilit&auml;t der Leuchtmittel:</strong> <br /> <br /> Nicht alle Leuchtmittel sind dimmbar. Pr&uuml;fen Sie vor der Installation, ob Ihre Leuchtmittel f&uuml;r den Betrieb mit einem Dimmer geeignet sind, um Flackern oder Besch&auml;digung derselben zu vermeiden.</p>`

export default {
  securityBox,
  heatPump,
  electricalWiring,
  electricalWiringRooms,
  dataCabinet,
  ceilingBurningPoint,
  dimmer
}
