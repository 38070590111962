import { configTemplate } from './options/baths'

export default {
  contractor: {
    order: 0,
    enabled: null,
    completed: false,
    forTypes: ['apartment', 'house'],
    title: 'Sanierung mit Generalunternehmen'
  },
  electrical: {
    order: 1,
    enabled: false,
    completed: false,
    forTypes: ['apartment', 'house'],
    title: 'Elektrik',
    security_box: {
      enabled: null
    },
    data_cabinet: {
      enabled: null
    },
    heat_pump: {
      enabled: null,
      forTypes: ['house']
    },
    electrical_wiring: {
      enabled: null,
      rooms: []
    },
    switch_programm: {
      enabled: false,
      quality: '' // budget, standard, premium
    },
    ceiling_burning_point_created: {
      enabled: false,
      rooms: []
    },
    ceiling_burning_point_extended: {
      enabled: false,
      rooms: []
    },
    dimmer: {
      enabled: false,
      rooms: []
    },
    roomIds: []
  },
  heating: {
    order: 2,
    floors: 1,
    enabled: false,
    completed: false,
    forTypes: ['apartment', 'house'],
    title: 'Heizungstechnik / Kamin & Ofen',
    heating_system: {
      enabled: false,
      type: ''
    },
    heating_rebuild: {
      enabled: false,
      type: ''
    },
    geyser: {
      enabled: null,
      amount: 0
    },
    radiator: {
      enabled: null,
      items: []
    },
    underfloor_heating: {
      enabled: null,
      rooms: []
    },
    stove: {
      enabled: null,
      quality: '',
      exhaust: {
        enabled: null
      }
    },
    chimney: {
      enabled: null,
      quality: '',
      exhaust: {
        enabled: null
      }
    }
  },
  wallsCeilings: {
    order: 3,
    enabled: false,
    completed: false,
    forTypes: ['apartment', 'house'],
    title: 'Wände & Decken',
    change: {
      enabled: null,
      quality: '',
      currentRoom: '',
      type: '',
      roomConfigs: {}
    },
    removeOld: {
      enabled: null,
      rooms: []
    },
    changeCeiling: {
      enabled: null,
      quality: '',
      currentRoom: '',
      type: '',
      roomConfigs: {}
    },
    ceilingSpots: {
      enabled: false,
      rooms: []
    },
    improveStucco: {
      enabled: false,
      rooms: []
    },
    newStucco: {
      enabled: false,
      rooms: []
    },
    roomIds: []
  },
  floors: {
    order: 4,
    enabled: false,
    completed: false,
    forTypes: ['apartment', 'house'],
    title: 'Böden & Fußleisten',
    change: {
      enabled: false,
      quality: '',
      currentRoom: '',
      type: '',
      roomConfigs: {}
    },
    removeOld: {
      enabled: null,
      rooms: []
    },
    recycleWoodenFloor: {
      enabled: false,
      rooms: []
    },
    newSkirtingBoards: {
      enabled: null,
      rooms: [],
      quality: ''
    },
    recycleSkirtingBoards: {
      enabled: false,
      rooms: []
    },
    roomIds: []
  },
  windows: {
    order: 5,
    enabled: false,
    completed: false,
    forTypes: ['apartment', 'house'],
    title: 'Fenster',
    change: {
      enabled: null,
      type: '',
      material: '',
      amounts: {
        singleLeaf: 0,
        doubleLeaf: 0,
        balconyTerraceDoor: 0,
        largeFixed: 0,
        tiltTurnSlide: 0,
        small: 0
      },
      configs: []
    },
    renewReveals: {
      enabled: null
    },
    renewSills: {
      enabled: null,
      type: ''
    },
    lack: {
      enabled: null,
      type: '',
      amount: 0
    }
  },
  doors: {
    order: 6,
    enabled: false,
    completed: false,
    forTypes: ['apartment', 'house'],
    title: 'Türen',
    renew: {
      enabled: null,
      amount: 0,
      quality: ''
    },
    entranceDoor: {
      enabled: null,
      quality: ''
    },
    lack: {
      enabled: null,
      amount: 0
    }
  },
  baths: {
    order: 7,
    enabled: false,
    completed: false,
    forTypes: ['apartment', 'house'],
    title: 'Bad und WC-Sanierung',
    change: {
      enabled: null,
      quality: '',
      currentRoom: '',
      config: configTemplate,
      roomConfigs: {}
    },
    radiator: {
      enabled: false,
      items: [],
      rooms: []
    },
    ceilings: {
      enabled: false,
      changeCeiling: {
        enabled: false,
        quality: '',
        currentRoom: '',
        type: '',
        roomConfigs: {}
      },
      ceilingSpots: {
        enabled: true,
        rooms: []
      }
    },
    roomIds: []
  },
  roof: {
    order: 8,
    enabled: false,
    completed: false,
    forTypes: ['house'],
    title: 'Dach & Dämmung',
    change: {
      enabled: false,
      type: ''
    },
    renew: {
      size: 0,
      form: '',
      angle: 0,
      angle2: 0,
      width: 0,
      length: 0,
      height: 0,
      area: 0,
      currentPaving: '',
      newPaving: '',
      lights: {
        current: 0,
        new: 0
      },
      windows: {
        current: 0,
        new: 0
      },
      dormers: {
        current: 0,
        new: 0
      },
      gutters: {
        enabled: null
      },
      downspouts: {
        enabled: false
      }
    },
    insulation: {
      size: 0,

      floorCeilingInsulation: {
        enabled: false,
        size: 0,
        layer: ''
      }
    }
  },
  facade: {
    order: 9,
    enabled: false,
    completed: false,
    forTypes: ['house'],
    title: 'Fassade & Dämmung',
    renew: {
      enabled: false,
      type: '',
      size: 0,
      quality: 'standard'
    }
  },
  blueprint: {
    order: 10,
    enabled: false,
    completed: false,
    forTypes: ['apartment', 'house'],
    title: 'Grundrissveränderungen',
    open: {
      enabled: null,
      size: 0,
      type: ''
    },
    close: {
      enabled: null,
      size: 0,
      type: ''
    },
    postProcessing: {
      enabled: null
    }
  },
  stairs: {
    order: 11,
    enabled: false,
    completed: false,
    forTypes: ['apartment', 'house'],
    title: 'Treppe',
    rebuild: {
      enabled: null,
      type: '',
      quality: ''
    },
    renew: {
      enabled: null,
      addWoodSteps: {
        enabled: null,
        amount: 0
      },
      lack: {
        enabled: false
      }
    }
  },
  /*
  security: {
    order: 12,
    enabled: false,
    forTypes: ['apartment', 'house'],
    title: 'Sicherheitstechnik',
    securityFeatures: {
      enabled: false,
      features: [],
      quality: 'standard'
    }
  },
  */
  pipes: {
    order: 13,
    floors: 0,
    enabled: false,
    completed: false,
    forTypes: ['house'],
    title: 'Versorgungsleitungen',
    renew: {
      enabled: false,
      amount: 0
    }
  }
  /*
  roofConstruction: {
    order: 14,
    enabled: false,
    forTypes: ['house'],
    title: 'Dachausbau'
  }
  */
}
