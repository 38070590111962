import HSingle from '@public/images/singles/heatpump/h_single.svg'
import HDouble from '@public/images/singles/heatpump/h_double.svg'
import HRow from '@public/images/singles/heatpump/h_row.svg'
import PGas from '@public/images/singles/heatpump/p_gas.svg'
import POil from '@public/images/singles/heatpump/p_oil.svg'
import PLiquidGas from '@public/images/singles/heatpump/p_liquid_gas.svg'
import PPellet from '@public/images/singles/heatpump/p_pellet.svg'
import PWood from '@public/images/singles/heatpump/p_wood.svg'
import PPower from '@public/images/singles/heatpump/p_power.svg'
import PRHeat from '@public/images/singles/heatpump/pr_heat.svg'
import PROilPet from '@public/images/singles/heatpump/pr_oil_pet.svg'
import PROilSteel from '@public/images/singles/heatpump/pr_oil_steel.svg'
import PROilBase from '@public/images/singles/heatpump/pr_oil_base.svg'
import No from '@public/images/no.svg'
import Yes from '@public/images/yes.svg'

export const houseTypes = [
  {
    label: 'Einfamilienhaus',
    value: 'single',
    image: HSingle
  },
  {
    label: 'Doppelhaushälfte',
    value: 'double',
    image: HDouble
  },
  {
    label: 'Reihenhaus',
    value: 'row',
    image: HRow
  }
]

export const heatings = [
  {
    label: 'Gas',
    value: 'gas',
    image: PGas
  },
  {
    label: 'Öl',
    value: 'oil',
    image: POil
  },
  {
    label: 'Flüssiggas',
    value: 'liquid_gas',
    image: PLiquidGas
  },
  {
    label: 'Pellet',
    value: 'pellet',
    image: PPellet
  },
  {
    label: 'Holz',
    value: 'wood',
    image: PWood
  },
  {
    label: 'Strom',
    value: 'power',
    image: PPower
  }
]

export const removings = [
  {
    label: 'Heizungsanlage',
    value: 'gas',
    image: PRHeat
  },
  {
    label: 'Öltank Kunststoff',
    value: 'oil-plastic',
    image: PROilPet
  },
  {
    label: 'Öltank Stahl',
    value: 'oil-steel',
    image: PROilSteel
  },
  {
    label: 'Erdöltank',
    value: 'oil-ground',
    image: PROilBase
  },
  {
    label: 'Keine/weiß ich nicht',
    value: 'no',
    image: No
  }
]

export const yesNo = [
  {
    label: 'Ja',
    value: true,
    image: Yes
  },
  {
    label: 'Nein',
    value: false,
    image: No
  }
]
