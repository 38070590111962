<script setup>
import { computed } from 'vue'
import { Transition } from 'vue'
import Panel from '@/components/Elements/Panel.vue'
import Electrical from '@/components/Services/Electrical.vue'
import Heating from '@/components/Services/Heating.vue'
import Walls from '@/components/Services/Walls.vue'
import Floors from '@/components/Services/Floors.vue'
import Facade from '@/components/Services/Facade.vue'
import Doors from '@/components/Services/Doors.vue'
import Pipes from '@/components/Services/Pipes.vue'
import Security from '@/components/Services/Security.vue'
import Stairs from '@/components/Services/Stairs.vue'
import Blueprint from '@/components/Services/Blueprint.vue'
import Windows from '@/components/Services/Windows.vue'
import Roof from '@/components/Services/Roof.vue'
import Baths from '@/components/Services/Baths.vue'

import panelInfos from '@/data/infos/services'
import Contractor from '@/components/Services/Contractor.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const currentService = computed({
  get: () => {
    const s = props.modelValue.step.split('.')[1]

    return props.modelValue.services[s]
  },
  set: (v) => {
    const s = props.modelValue.step.split('.')[1]

    model.value.services[s] = v
  }
})

const info = computed(() => {
  const step = props.modelValue.step.split('.')[1]

  return panelInfos[step] || ''
})
</script>

<template>
  <div v-if="currentService">
    <Panel :title="currentService.title" :name="model.step" :info="info">
      <Transition name="fade-in">
        <Contractor
          v-if="model.step === 'services.contractor'"
          v-model="currentService"
          :form="modelValue"
        />

        <Electrical
          v-else-if="model.step === 'services.electrical'"
          v-model="currentService"
          :form="modelValue"
        />

        <Heating
          v-else-if="model.step === 'services.heating'"
          v-model="currentService"
          :form="modelValue"
        />

        <Walls
          v-else-if="model.step === 'services.wallsCeilings'"
          v-model="currentService"
          :form="modelValue"
        />

        <Floors
          v-else-if="model.step === 'services.floors'"
          v-model="currentService"
          :form="modelValue"
        />
        <Windows
          v-else-if="model.step === 'services.windows'"
          v-model="currentService"
          :form="modelValue"
        />
        <Doors
          v-else-if="model.step === 'services.doors'"
          v-model="currentService"
          :form="modelValue"
        />
        <Pipes
          v-else-if="model.step === 'services.pipes'"
          v-model="currentService"
          :form="modelValue"
        />
        <Stairs
          v-else-if="model.step === 'services.stairs'"
          v-model="currentService"
          :form="modelValue"
        />
        <Facade
          v-else-if="model.step === 'services.facade'"
          v-model="currentService"
          :form="modelValue"
        />
        <Roof
          v-else-if="model.step === 'services.roof'"
          v-model="currentService"
          :form="modelValue"
        />
        <Baths
          v-else-if="model.step === 'services.baths'"
          v-model="currentService"
          :form="modelValue"
        />
        <Blueprint
          v-else-if="model.step === 'services.blueprint'"
          v-model="currentService"
          :form="modelValue"
        />
        <Security
          v-else-if="model.step === 'services.security'"
          v-model="currentService"
          :form="modelValue"
        />
      </Transition>
    </Panel>
  </div>
</template>
