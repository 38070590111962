import createRoom from '@/utils/room.js'

/**
 * Updates selected rooms based on room types
 * @param {Object} model - Reactive model containing services and rooms
 * @param {string} servicePath - Path to service (e.g., 'services.wallsCeilings')
 * @param {Array} newTypes - Array of selected room types
 * @param {Object} roomTypes - Room type configurations
 */
export const updateSelectedRooms = (model, servicePath, newTypes, roomTypes) => {
  const newTypesArray = Array.isArray(newTypes) ? newTypes : [newTypes]
  const serviceRef = getServiceRef(model.value, servicePath)

  if (!serviceRef.roomIds) {
    serviceRef.roomIds = []
  }

  // Remove rooms of deselected types only for this service
  serviceRef.roomIds = serviceRef.roomIds.filter((id) => {
    const room = model.value.rooms.find((r) => r.id === id)
    return room && newTypesArray.includes(room.type)
  })

  // Remove only the rooms that belong to this service and arent in newTypes
  model.value.rooms = model.value.rooms.filter((room) => {
    // Keep the room if its not in this service's roomIds
    if (!serviceRef.roomIds.includes(room.id)) {
      return true
    }
    // Keep the room if its type is in newTypes
    return newTypesArray.includes(room.type)
  })

  // Add new rooms for newly selected types
  newTypesArray.forEach((type) => {
    const hasRoomType = serviceRef.roomIds.some(
      (id) => model.value.rooms.find((r) => r.id === id)?.type === type
    )
    if (!hasRoomType) {
      const roomName = getRoomName(type, roomTypes)
      const newRoom = createRoom(roomName, type, false, true)
      model.value.rooms.push(newRoom)
      serviceRef.roomIds.push(newRoom.id)
    }
  })
}

/**
 * Updates room count for a specific type
 * @param {Object} model - Reactive model
 * @param {string} servicePath - Path to service
 * @param {string} roomType - Type of room to update
 * @param {number} newCount - New count of rooms
 * @param roomTypes
 * @returns {void}

 */
export const updateRoomCount = (model, servicePath, roomType, newCount, roomTypes) => {
  console.log('re')
  const serviceRef = getServiceRef(model.value, servicePath)
  const currentCount = getRoomCount(model, servicePath, roomType)

  if (newCount > currentCount) {
    // Add rooms
    for (let i = 0; i < newCount - currentCount; i++) {
      const roomName = getRoomName(roomType, roomTypes)
      const newRoom = createRoom(roomName, roomType, false, true)
      model.value.rooms.push(newRoom)
      serviceRef.roomIds.push(newRoom.id)
    }
  } else if (newCount < currentCount) {
    // Remove rooms
    const roomsOfType = serviceRef.roomIds.filter(
      (id) => model.value.rooms.find((r) => r.id === id)?.type === roomType
    )
    const idsToRemove = roomsOfType.slice(newCount)

    serviceRef.roomIds = serviceRef.roomIds.filter((id) => !idsToRemove.includes(id))

    model.value.rooms = model.value.rooms.filter((room) => !idsToRemove.includes(room.id))
  }
}

/**
 *
 * @param model
 * @param roomId
 * @returns {*}
 */
export const getRoom = (model, roomId) => {
  return model.value.rooms.find((r) => r.id === roomId)
}

/**
 * Gets count of rooms of specific type
 * @param {Object} model - Reactive model
 * @param {string} servicePath - Path to service
 * @param {string} roomType - Type of room to count
 * @returns {number} Count of rooms of specified type
 */
export const getRoomCount = (model, servicePath, roomType) => {
  const serviceRef = getServiceRef(model.value, servicePath)
  return serviceRef.roomIds.filter(
    (id) => model.value.rooms.find((r) => r.id === id)?.type === roomType
  ).length
}

/**
 * Handles counter updates for room amounts
 * @param {Object} model - Reactive model
 * @param {string} servicePath - Path to service
 * @param {Object} roomAmounts - Ref object containing room amounts
 * @param {string} roomType - Type of room being updated
 * @param {number} newValue - New counter value
 * @param roomTypes
 */
export const handleCounterUpdate = (
  model,
  servicePath,
  roomAmounts,
  roomType,
  newValue,
  roomTypes
) => {
  event.preventDefault()
  roomAmounts.value[roomType] = newValue
  updateRoomCount(model, servicePath, roomType, newValue, roomTypes)
}

/**
 * Gets reference to service object from path
 * @param {Object} modelValue - Model value object
 * @param {string} servicePath - Path to service
 * @returns {Object} Service reference
 */
const getServiceRef = (modelValue, servicePath) => {
  return servicePath.split('.').reduce((obj, path) => obj[path], modelValue)
}

/**
 * Gets room name based on room type
 * @param roomType
 * @param roomTypes
 * @returns {string|*}
 */
const getRoomName = (roomType, roomTypes) => {
  const roomTypeObj = roomTypes.find((type) => type.value === roomType)
  return roomTypeObj ? roomTypeObj.label : 'Room'
}

/**
 * Gets sorted rooms based on selected room IDs
 * @param {Object} model - Reactive model
 * @param {string} servicePath - Path to service
 * @returns {Array} Sorted array of room objects
 */
export const getSortedRooms = (model, servicePath) => {
  const serviceRef = getServiceRef(model.value, servicePath)

  return (
    serviceRef.roomIds
      .map((id) => model.value.rooms.find((r) => r.id === id))
      .filter(Boolean)
      // sort by name
      .sort((a, b) => a.name.localeCompare(b.name))
  )
}

/**
 * Gets room name with index for multiple rooms of same type
 * @param {string} label - Room label/name
 * @param {number} overallIndex - Index in sorted rooms array
 * @param {Object} model - Reactive model
 * @param {string} servicePath - Path to service
 * @param {Array} roomTypes - Array of room type configurations
 * @returns {string} Formatted room name with index if needed
 */
export const getRoomNameWithIndex = (label, overallIndex, model, servicePath, roomTypes) => {
  // Find room type object
  const roomTypeObj = roomTypes.find((room) => room.label === label)
  const roomName = roomTypeObj ? roomTypeObj.label : 'Room'

  const serviceRef = getServiceRef(model.value, servicePath)

  // Get rooms of the same type
  const roomsOfType = serviceRef.roomIds.filter(
    (id) => model.value.rooms.find((r) => r.id === id)?.name === label
  )
  const totalRoomsOfType = roomsOfType.length

  // Get sorted rooms and find current room's index
  const sortedRooms = getSortedRooms(model, servicePath)
  const currentRoom = sortedRooms[overallIndex]
  const roomTypeIndex = roomsOfType.findIndex((id) => id === currentRoom.id) + 1

  // Return formatted name
  return totalRoomsOfType > 1 ? `${roomName} (${roomTypeIndex}/${totalRoomsOfType})` : roomName
}

/**
 * Gets the sorted index of a room
 * @param {Object} room - Room object
 * @param {Object} model - Reactive model
 * @param {string} servicePath - Path to service
 * @returns {number} Index of room in sorted array
 */
export const getSortedIndex = (room, model, servicePath) => {
  const sortedRooms = getSortedRooms(model, servicePath)
  return sortedRooms.findIndex((r) => r.id === room.id)
}

/**
 *
 * @param model
 * @param roomId
 * @returns {void}
 */
export const updateRoomSize = (model, roomId) => {
  const room = model.value.rooms.find((r) => r.id === roomId)
  if (room) {
    room.areas.walls = room.areas.floor * 2.5
    room.areas.ceiling = room.areas.floor
  } else {
    console.error('Room not found:', roomId)
  }
}
