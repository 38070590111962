<script setup>
import { z } from 'zod'
import Panel from '@/components/Elements/Panel.vue'
import BaseMenu from '@/components/Navigation/LeftSide/BaseMenu.vue'
import RoomsMenu from '@/components/Navigation/LeftSide/RoomsMenu.vue'
import ServicesMenu from '@/components/Navigation/LeftSide/ServicesMenu.vue'
import SummaryBar from '@/components/Structure/SummaryBar.vue'
import ServicesSelection from '@/components/Navigation/Middle/ServicesSelection.vue'
import { computed, onMounted, ref } from 'vue'
import services from '@/data/services'
import BaseConfiguration from '@/components/Navigation/Right/BaseConfiguration.vue'
import RoomsConfiguration from '@/components/Navigation/Middle/RoomsConfiguration.vue'
import ContactPanel from '@/components/Navigation/Middle/ContactPanel.vue'
import ListOfServicesPanel from '@/components/Navigation/Middle/ListOfServicesPanel.vue'
import Navigation from '@/components/Navigation/Middle/Navigation.vue'
import BaseView from '@/components/Navigation/Middle/BaseView.vue'
import StartInfo from '@/components/Modals/StartInfo.vue'
import Header from '@/components/Structure/Header.vue'

import ServiceConfiguration from '@/components/Navigation/Right/ServiceConfiguration.vue'
import RoomConfiguration from '@/components/Navigation/Right/RoomConfiguration.vue'
import { useLocalStorage } from '@vueuse/core'
import MobileNav from './Navigation/MobileNav.vue'
import useEmitter from '@/composables/useEmitter.js'
import track from '@/utils/tracking.js'
import { initials, registerShortcuts, registerWatcher, totalInitials } from '@/utils/calculators.js'
import { getGclid, getUtmParams } from '@/utils/gclid'
import { ContactValidationSchema } from '@/utils/validators.js'

const API_URL = import.meta.env.VITE_API_URL

const emitter = useEmitter()
const enabled = ref(false)

const submitting = ref(false)
const submitted = ref(false)

const props = defineProps({
  isProject: {
    type: Boolean,
    default: false
  },
  isInternal: {
    type: Boolean,
    default: false
  }
})

const json = (i) => JSON.parse(JSON.stringify(i))

const formData = useLocalStorage('form', json(initials))
const totals = useLocalStorage('totals', json(totalInitials))

onMounted(async () => {
  emitter.on('calc:add-room', () => {
    formData.value.step = 'rooms'
  })

  if (formData.value.version != initials.version || props.isProject || props.isInternal) {
    await reset()
  } else {
    enabled.value = true
  }

  track.tiktok('Starts Form', {
    contents: [
      {
        content_id: 'sanitation',
        content_name: 'sanitation'
      }
    ]
  })
  track.tmViewItem('sanitation')

  // if type in uri set type
  if (window.location.search.includes('type')) {
    const uri = new URLSearchParams(window.location.search)
    const type = uri.get('type')

    if (['apartment', 'house'].includes(type)) {
      formData.value.base.type = type
    }

    if (uri.get('zip')) {
      formData.value.base.postal_code = uri.get('zip')
    }

    if (uri.get('s')) {
      const parts = uri.get('s').split(',')
      const allowed = Object.keys(services)

      parts.forEach((part) => {
        if (allowed.includes(part)) {
          formData.value.services[part].enabled = true
        }
      })
    }
  }
})

const submitHandler = (e) => {
  if (!e.submitter || e.submitter.name !== 'submit') {
    return false
  }

  if (!ContactValidationSchema.safeParse(formData.value.contact).success) {
    alert('Bitte füllen Sie alle Felder aus.')

    return
  }

  submitting.value = true
  formData.value.contact.gclid = getGclid()
  formData.value.utm = getUtmParams()

  fetch(API_URL + '/api/forms/sc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify(formData.value)
  })
    .then((response) => response.json())
    .then(({ data }) => {
      submitted.value = true
      track.fast(data)
      track.yandex(97558574, 'reachGoal', 'form-click')
      track.tiktok(
        'SubmitForm',
        {
          contents: [
            {
              content_id: 'sanitation',
              content_name: 'sanitation'
            }
          ],
          value: data.volume / 100,
          currency: 'EUR'
        },
        {
          email: formData.value.contact.email,
          // phone_number: formData.value.contact.phone,
          external_id: data.id
        }
      )
      track.tmPurchase('sanitation', data.volume / 100, 'EUR', data.id, formData.value.contact)
    })
    .finally(() => {
      submitting.value = false
    })
}

const reset = async () => {
  enabled.value = false
  totals.value = json(totalInitials)
  formData.value = json(initials)

  if ((props.isProject || props.isInternal) && window._widget && window._widget.form) {
    formData.value = json(window._widget.form)
    totals.value = json(window._widget.totals)
  }

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, 700)
  }).then(() => {
    enabled.value = true
  })
}

const calculatedHeight = computed(() => {
  if (formData.value.step.startsWith('contact')) {
    return 'ipad:h-[calc(100vh-85px)] 2xl:h-[calc(100vh-85px)]'
  }

  return 'ipad:h-[calc(100vh-165px)]'
})

const hideMiddleClass = computed(() => {
  if (formData.value.step.startsWith('base')) {
    return 'hidden ipad:block'
  }
})

registerWatcher(formData, totals)
registerShortcuts(formData, totals, reset)
</script>

<template>
  <Transition>
    <form
      id="mah-wrapper"
      @submit.prevent="submitHandler"
      class="mx-auto mt-[140px] max-w-3xl pb-14 text-primary lg:mt-[65px] ipad:max-w-[1700px] ipad:pb-0"
      v-if="enabled"
    >
      <Header />
      <MobileNav v-model="formData" class="ipad:hidden" />

      <template v-if="formData.step !== 'contact'">
        <div
          id="grid"
          class="mt-20 grid gap-3 transition-all ease-in-out ipad:mt-0 ipad:grid-cols-8"
          :class="calculatedHeight"
        >
          <div
            id="left"
            class="relative hidden rounded-xl border border-primary bg-gray-100 p-5 dark:border-action dark:bg-primary ipad:col-span-2 ipad:block ipad:h-full ipad:overflow-scroll ipad:p-7 2xl:p-10"
          >
            <Panel title="Übersicht">
              <div class="flex flex-col gap-y-10">
                <BaseMenu v-model="formData" />
                <RoomsMenu v-model="formData" />
                <ServicesMenu v-model="formData" />
              </div>
            </Panel>

            <a
              @click.prevent="() => reset()"
              class="mt-10 block cursor-pointer text-primary dark:text-action"
            >
              zurücksetzen
            </a>
          </div>
          <div
            id="middle"
            :class="hideMiddleClass"
            class="relative rounded-xl border-primary bg-gray-100 p-5 dark:border-action dark:bg-primary ipad:col-span-3 ipad:overflow-scroll ipad:border ipad:p-7 2xl:p-10"
          >
            <BaseView
              class="hidden ipad:block"
              v-if="formData.step.startsWith('base')"
              v-model="formData"
            />
            <RoomsConfiguration v-if="formData.step.startsWith('rooms')" v-model="formData" />
            <ServicesSelection v-if="formData.step.startsWith('services')" v-model="formData" />
            <ListOfServicesPanel v-if="formData.step.startsWith('los')" v-model="formData" />

            <Navigation
              class="hidden ipad:flex"
              v-model="formData"
              :totals="totals"
              :is-project="isProject"
              :is-internal="isInternal"
            />
          </div>
          <div
            id="right"
            class="rounded-xl border-primary bg-gray-100 p-5 dark:border-action dark:bg-primary ipad:col-span-3 ipad:overflow-scroll ipad:border ipad:p-7 2xl:p-10"
          >
            <div class="relative">
              <Transition name="fade-in">
                <BaseConfiguration v-if="formData.step.startsWith('base')" v-model="formData" />
                <RoomConfiguration
                  v-else-if="formData.step.startsWith('rooms')"
                  v-model="formData"
                />
                <ServiceConfiguration
                  v-else-if="formData.step.startsWith('services')"
                  v-model="formData"
                />
              </Transition>

              <Navigation
                class="flex ipad:hidden"
                v-model="formData"
                :totals="totals"
                :is-project="isProject"
                :is-internal="isInternal"
              />
            </div>
          </div>
        </div>

        <SummaryBar
          v-model="formData"
          :totals="totals"
          :is-project="isProject"
          :is-internal="isInternal"
        />
      </template>
      <ContactPanel
        v-model="formData"
        :totals="totals"
        :submitted="submitted"
        :submitting="submitting"
        v-else
      />
    </form>
  </Transition>
  <StartInfo />
</template>
