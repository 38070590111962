<!-- Modal.vue -->
<template>
  <Teleport to="body">
    <transition
      enter-active-class="transition ease-out duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="transition ease-in duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        v-if="isOpen"
        class="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4"
        @click.self="closeModal"
      >
        <div
          class="relative max-h-[90vh] w-full max-w-4xl overflow-hidden rounded-xl bg-primary shadow-xl"
        >
          <div class="p-6">
            <div class="mb-4 flex items-center justify-between">
              <h2 class="text-2xl font-semibold text-white">{{ title }}</h2>
              <button class="text-white" @click="closeModal">✕</button>
            </div>

            <slot></slot>
          </div>
        </div>
      </div>
    </transition>
  </Teleport>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  title: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['close'])

const closeModal = () => {
  emit('close')
}
</script>
