export const lessQm = `<p>Die durchschnittliche Gr&ouml;&szlig;e von T&uuml;ren und Fenstern in Wohn- und Gesch&auml;ftsr&auml;umen kann variieren, abh&auml;ngig von Bauvorschriften, stilistischen Pr&auml;ferenzen und funktionalen Anforderungen. Hier sind jedoch einige typische Ma&szlig;e, die als grobe Anhaltspunkte dienen k&ouml;nnen:</p>
<ul type="disc">
<li>Innent&uuml;ren in Wohnungen und H&auml;usern haben in der Regel eine Standardgr&ouml;&szlig;e von ca. 198,5 cm in der H&ouml;he und 86 cm in der Breite. Dies entspricht einer Fl&auml;che von etwa 1,71 Quadratmetern. Es gibt jedoch auch schmalere T&uuml;ren, beispielsweise f&uuml;r Schr&auml;nke oder B&auml;der, die in der Breite nur ca. 73,5 cm haben und somit eine Fl&auml;che von etwa 1,46 Quadratmetern einnehmen.</li>
</ul>
<ul type="disc">
<li>Au&szlig;ent&uuml;ren sind oft etwas breiter und h&ouml;her, um eine bessere Isolierung zu erm&ouml;glichen. Eine typische Gr&ouml;&szlig;e f&uuml;r eine Hauseingangst&uuml;r k&ouml;nnte etwa 210 cm x 100 cm sein, was 2,1 Quadratmetern entspricht.</li>
</ul>
<ul type="disc">
<li>Fenstergr&ouml;&szlig;en sind deutlich variabler als T&uuml;rgr&ouml;&szlig;en, da sie stark von der Architektur, dem Zweck des Raumes und &auml;sthetischen Entscheidungen abh&auml;ngen. Typische Fenster k&ouml;nnen in Wohnr&auml;umen, zum Beispiel, eine Fl&auml;che von 1,2 x 1,2 Metern (1,44 Quadratmeter) bis zu 1,8 x 1,2 Metern (2,16 Quadratmeter) haben.</li>
</ul>
<p>Die Angabe der Gr&ouml;&szlig;e von T&uuml;ren und Fenstern ist f&uuml;r die Berechnung von Wandfl&auml;chen aus mehreren Gr&uuml;nden wichtig:</p>
<ol type="1">
<li><strong>Materialbedarf</strong>: Bei Renovierungen oder Neubauten muss die Menge an Materialien wie Tapeten, Farbe oder Verputz genau berechnet werden. Um keinen &Uuml;berschuss zu bestellen und Kosten zu sparen, werden die Fl&auml;chen von T&uuml;ren und Fenstern von der gesamten Wandfl&auml;che abgezogen.</li>
</ol>
<ol start="2" type="1">
<li><strong>Kostensch&auml;tzung</strong>: Handwerker veranschlagen ihre Angebote oft nach der Fl&auml;che, die behandelt werden soll. Bereiche, die T&uuml;ren und Fenster einnehmen, werden in der Regel nicht bearbeitet, weshalb ihre Fl&auml;che nicht in die Kostenberechnung einflie&szlig;t.</li>
</ol>
<ol start="3" type="1">
<li><strong>Energieeffizienz</strong>: Insbesondere bei der Berechnung des W&auml;rmedurchgangs (U-Wert) von W&auml;nden und der Energieeffizienz von Geb&auml;uden ist es notwendig, die eingebauten Fenster- und T&uuml;rgr&ouml;&szlig;en zu kennen, da diese in der Regel einen anderen U-Wert als die Wandfl&auml;chen haben.</li>
</ol>
<p>Zusammenfassend l&auml;sst sich sagen, dass die Kenntnis der Gr&ouml;&szlig;e von T&uuml;ren und Fenstern f&uuml;r eine pr&auml;zise Planung und Berechnung in der Baubranche unerl&auml;sslich ist. Sie hilft dabei, genaue Materialmengen und Kosten zu ermitteln und tr&auml;gt zur Energieeffizienz von Geb&auml;uden bei.</p>`
