import { getImageUrl } from '@/utils/image.js'
import LivingRoom from '@public/images/calc/rooms/livingroom.svg'
import Bedroom from '@public/images/calc/rooms/bedroom.svg'
import Floor from '@public/images/calc/rooms/flur.svg'
import Kitchen from '@public/images/calc/rooms/kitchen.svg'
import Bathroom from '@public/images/calc/rooms/bathroom.svg'
import UtilityRoom from '@public/images/calc/rooms/utility_room.svg'
import SecurityBox from '@public/images/singles/electric/sicherungskasten.svg'
import DataCabinet from '@public/images/singles/electric/sicherungskasten_ds.svg'
import Budget from '@public/images/calc/quality/1_b.svg'
import Standard from '@public/images/calc/quality/2_b.svg'
import Premium from '@public/images/calc/quality/3_b.svg'
import Yes from '@public/images/yes.svg'
import No from '@public/images/no.svg'

export const roomTypes = [
  {
    label: 'Wohn-/Esszimmer',
    value: 'living_room',
    image: LivingRoom
  },
  {
    label: 'Schlaf-/Kinderzimmer',
    value: 'bedroom',
    image: Bedroom
  },
  {
    label: 'Flur',
    value: 'floor',
    image: Floor
  },
  {
    label: 'Küche',
    value: 'kitchen',
    image: Kitchen
  },
  {
    label: 'Badezimmer / WC',
    value: 'bathroom',
    image: Bathroom
  },
  {
    label: 'Hauswirtschaftsraum / Keller',
    value: 'utility_room',
    image: UtilityRoom
  }
]

export const security_box = [
  {
    label: 'Sicherungskasten',
    value: 'security_box',
    image: SecurityBox
  },
  {
    label: 'Sicherungskasten mit Datenschrank',
    value: 'data_cabinet',
    image: DataCabinet
  },
  {
    label: 'Nein / weiß nicht',
    value: 'no',
    image: No
  }
]

export const quality = [
  {
    label: 'Budget Qualität',
    image: Budget,
    value: 'budget'
  },
  {
    label: 'Standard Qualität',
    image: Standard,
    value: 'standard'
  },
  {
    label: 'Premium Qualität',
    image: Premium,
    value: 'premium'
  }
]

export const yesNoOptions = [
  {
    label: 'Ja',
    value: true,
    image: Yes
  },
  {
    label: 'Nein',
    value: false,
    image: No
  }
]

export const configTemplate = {
  selectedRoomTypes: [],
  roomAmounts: {
    living_room: 0,
    kitchen: 0,
    bathroom: 0,
    floor: 0,
    utility_room: 0,
    cellar: 0,
    bedroom: 0
  },
  rooms: [],
  toIgnoreRooms: []
}
