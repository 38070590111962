<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="() => (isOpen = false)" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/25 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-primary p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 dark:text-white">
                Um welchen Raum handelt es sich?
              </DialogTitle>
              <div class="mt-5">
                <ul class="grid grid-cols-2 flex-col gap-3">
                  <li v-for="i in options">
                    <button
                      type="button"
                      class="radio-item w-full"
                      @click.prevent="setItem(i)"
                      :class="{
                        'border-primary bg-primary text-white dark:border-white dark:bg-white dark:text-black':
                          roomType == i
                      }"
                    >
                      <span>{{ i.label }}</span>
                    </button>
                  </li>
                </ul>
              </div>

              <div class="mt-4">
                <button
                  type="button"
                  class="w-full rounded-lg bg-action py-2 font-bold"
                  @click="finish"
                >
                  übernehmen
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed, ref } from 'vue'
import { TransitionRoot, TransitionChild, Dialog, DialogPanel, DialogTitle } from '@headlessui/vue'

const emit = defineEmits(['update:modelValue', 'select'])

const props = defineProps({
  modelValue: Boolean
})

const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value)
})

const roomType = ref(null)

const options = ref([
  { value: 'living_room', label: 'Wohnzimmer' },
  { value: 'living_room', label: 'Kinderzimmer' },
  { value: 'living_room', label: 'Schlafzimmer' },
  { value: 'living_room', label: 'Flur' },
  { value: 'bathroom', label: 'Badezimmer' },
  { value: 'guest_wc', label: 'Gäste-WC' },
  { value: 'kitchen', label: 'Küche' },
  { value: 'living_room', label: 'Bürozimmer' },
  { value: 'utility_room', label: 'HWR' },
  { value: 'cellar', label: 'Kellerraum' }
])

const setItem = (item) => {
  roomType.value = item
}

function finish() {
  isOpen.value = false
  emit('select', roomType.value)
}
</script>
