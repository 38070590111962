<template>
  <div class="mt-28 w-full flex-col md:flex md:max-w-4xl lg:max-w-6xl ipad:mt-28 xl:max-w-6xl">
    <component
      :headlineText="headlineText"
      :is="currentComponent"
      v-model="model"
      :totals="totals"
      :enabledServices="enabledServices"
      :availableSteps="availableSteps"
      :hasSlots="hasSlots"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import Base from '@/components/Services/Base.vue'
import Electrical from '@/components/Services/V2/Electrical.vue'
import Floor from '@/components/Services/V2/Floor.vue'
import Window from '@/components/Services/V2/Window.vue'
import Bath from '@/components/Services/V2/Bath.vue'
import Facade from '@/components/Services/V2/Facade.vue'
import Stairs from '@/components/Services/V2/Stairs.vue'
import Doors from '@/components/Services/V2/Doors.vue'
import Roof from '@/components/Services/V2/Roof.vue'
import Pipes from '@/components/Services/V2/Pipes.vue'
import Blueprint from '@/components/Services/V2/Blueprint.vue'
import Heating from '@/components/Services/V2/Heating.vue'
import Walls from '@/components/Services/V2/Walls.vue'

const props = defineProps({
  modelValue: Object,
  totals: Object,
  enabledServices: Array,
  availableSteps: Array,
  hasSlots: Boolean
})

const emit = defineEmits(['update:modelValue'])

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const stepComponents = {
  types: Base,
  services: Base,
  services_electrical: Electrical,
  services_floors: Floor,
  services_windows: Window,
  services_baths: Bath,
  services_facade: Facade,
  services_stairs: Stairs,
  services_doors: Doors,
  services_roof: Roof,
  services_pipes: Pipes,
  services_blueprint: Blueprint,
  services_heating: Heating,
  services_wallsCeilings: Walls
}

const headlineTexts = {
  types: '',
  services: 'Wählen Sie eine oder mehrere Dienstleistungen aus!',
  services_electrical: 'Elektronik',
  services_floors: 'Böden & Fussleisten',
  services_windows: 'Fenster',
  services_baths: 'Bäder & Gäste WCs',
  services_facade: 'Fassade & Dämmung',
  services_stairs: 'Treppe',
  services_doors: 'Türen',
  services_roof: 'Dach & Dämmung',
  services_pipes: 'Versorgungsleitungen',
  services_blueprint: 'Grundrissveränderung',
  services_heating: 'Heizungstechnik',
  services_wallsCeilings: 'Wände & Decken'
}

const currentComponent = computed(() => {
  if (model.value.step === 'services') {
    return Base
  }
  for (const [key, component] of Object.entries(stepComponents)) {
    if (model.value.step.includes(key) && key !== 'services') {
      return component
    }
  }
  return null
})

const headlineText = computed(() => {
  if (model.value.step === 'services') {
    return headlineTexts.services
  }
  for (const [key, text] of Object.entries(headlineTexts)) {
    if (model.value.step.includes(key) && key !== 'services') {
      return text
    }
  }
  return ''
})
</script>
