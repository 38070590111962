export const baths = `<p>Das Badezimmer ist ein Ort der Ruhe und Entspannung, welcher Funktionalit&auml;t und Design in Einklang bringen sollte. Eine Badezimmersanierung ist ein umfangreiches Projekt, bei dem viele Aspekte zu ber&uuml;cksichtigen sind, um das gew&uuml;nschte Ergebnis zu erreichen. Hier sind die wichtigsten Punkte, die bei einer Badezimmersanierung beachtet werden sollten, unterteilt nach Materialqualit&auml;ten:&nbsp;</p>
<p><strong>Planung</strong>&nbsp;</p>
<p>Vor Beginn der Sanierung sollte eine genaue Planung stehen. Hierbei sind die Raumaufteilung, die Auswahl der Sanit&auml;robjekte sowie die gew&uuml;nschte Ausstattung zentral. Ebenso sollten hierbei der Budgetrahmen abgesteckt und die Materialien entsprechend den Qualit&auml;tsstufen Budget, Standard und Premium gew&auml;hlt werden.&nbsp;</p>
<p><strong>Materialien</strong>&nbsp;</p>
<p>Bei den Materialien l&auml;sst sich grunds&auml;tzlich in drei gro&szlig;e Qualit&auml;tsstufen unterscheiden:&nbsp;</p>
<ol type="1">
<li><strong>Budget-Qualit&auml;t</strong>:&nbsp;</li>
</ol>
<ul type="disc">
<li><strong>Fliesen</strong>: Hier werden oft Standardkeramikfliesen verwendet. Sie sind kosteng&uuml;nstig, aber in der Auswahl oft eingeschr&auml;nkt.&nbsp;</li>
</ul>
<ul type="disc">
<li><strong>Sanit&auml;robjekte</strong>: Sanit&auml;robjekte aus Kunststoff oder einfacher Keramik. Sie sind preiswert, aber oft weniger langlebig.&nbsp;</li>
</ul>
<ul type="disc">
<li><strong>Armaturen</strong>: Einstiegsmodelle, die h&auml;ufig aus verchromtem Kunststoff bestehen. Sie erf&uuml;llen ihre Funktion, haben aber eine geringere Lebensdauer.&nbsp;</li>
</ul>
<ul type="disc">
<li><strong>M&ouml;bel</strong>: Die g&uuml;nstigsten Optionen sind hier oft flachverpackte M&ouml;bel, die aus Spanplatten mit einer Folienbeschichtung bestehen.&nbsp;</li>
</ul>
<ol type="1">
<li><strong>Standard-Qualit&auml;t</strong>:&nbsp;</li>
</ol>
<ul type="disc">
<li><strong>Fliesen</strong>: Mittelpreisige Wand- und Bodenfliesen aus Keramik oder Porzellan. Sie bieten eine gr&ouml;&szlig;ere Auswahl an Designs und Farben.&nbsp;</li>
</ul>
<ul type="disc">
<li><strong>Sanit&auml;robjekte</strong>: Diese sind meist aus hochwertigerer Keramik oder Mineralguss und weisen eine bessere Verarbeitung auf.&nbsp;</li>
</ul>
<ul type="disc">
<li><strong>Armaturen</strong>: In dieser Kategorie findet man Armaturen aus Metall mit fortschrittlicheren Mechanismen, die nachhaltiger und langlebiger sind.&nbsp;</li>
</ul>
<ul type="disc">
<li><strong>M&ouml;bel</strong>: Es gibt eine gr&ouml;&szlig;ere Auswahl an vormontierten M&ouml;beln, die aus besseren Materialien wie MDF oder Holzfurnieren mit einer h&ouml;heren Widerstandsf&auml;higkeit gefertigt sind.&nbsp;</li>
</ul>
<ol type="1">
<li><strong>Premium-Qualit&auml;t</strong>:&nbsp;</li>
</ol>
<ul type="disc">
<li><strong>Fliesen</strong>: Hochwertigste Materialien wie Naturstein, hochwertiges Porzellan oder handgefertigte Keramikfliesen, die exklusive Designs und besondere Oberfl&auml;chen bieten.&nbsp;</li>
</ul>
<ul type="disc">
<li><strong>Sanit&auml;robjekte</strong>: Designorientierte, hochqualitative Objekte aus Keramik, Mineralguss oder sogar Edelstahl. H&auml;ufig handelt es sich um Markenprodukte mit erweiterten Funktionen.&nbsp;</li>
</ul>
<ul type="disc">
<li><strong>Armaturen</strong>: Hochwertige Armaturen aus massivem Messing oder Edelstahl, mit einer langen Gew&auml;hrleistung und oft mit innovativen Technologien wie Thermostaten oder ber&uuml;hrungslosen Funktionen.&nbsp;</li>
</ul>
<ul type="disc">
<li><strong>M&ouml;bel</strong>: Handarbeit, nat&uuml;rliche Materialien wie Massivholz und individuelle Ma&szlig;fertigung zeichnen die M&ouml;bel in dieser Kategorie aus.&nbsp;</li>
</ul>
<p><strong>Funktionalit&auml;t und Komfort</strong>&nbsp;</p>
<p>Unabh&auml;ngig von der gew&auml;hlten Qualit&auml;tsstufe sollte immer auf Funktionalit&auml;t und Komfort geachtet werden. Barrierefreiheit, gen&uuml;gend Stauraum und eine durchdachte Beleuchtung sind wesentliche Aspekte, die das Badezimmer langfristig nutzbar und angenehm machen.&nbsp;</p>
<p><strong>Installation</strong>&nbsp;</p>
<p>Eine professionelle Installation ist entscheidend f&uuml;r die Langlebigkeit und Funktion des Badezimmers. Wasserleitungen, Elektrik und vor allem die Abdichtung m&uuml;ssen fachgem&auml;&szlig; ausgef&uuml;hrt werden, um sp&auml;tere Sch&auml;den und Zusatzkosten zu vermeiden.&nbsp;</p>
<p><strong>Kosten</strong>&nbsp;</p>
<p>Die Kosten einer Badezimmersanierung variieren stark je nach Umfang der Sanierung, Wahl der Materialien und dem Einsatz von Fachfirmen. Ein Budget-Badezimmer kann ab einigen Tausend Euro realisiert werden, w&auml;hrend Premium-Badezimmer leicht das Zehnfache oder mehr kosten k&ouml;nnen.&nbsp;</p>
<p><strong>Fazit</strong>&nbsp;</p>
<p>Eine Badezimmersanierung erfordert sorgf&auml;ltige Planung, ein klares Budget und die Auswahl passender Materialien, die sowohl den eigenen Anspr&uuml;chen gerecht werden als auch langfristig Freude bereiten. Eine gute Mischung aus Qualit&auml;t, Design und Funktionalit&auml;t sorgt f&uuml;r ein gelungenes Ergebnis, das den Alltag bereichert und das Zuhause aufwertet.&nbsp;</p>`

export const roof = `
<p>Wer sein Dach erneuern m&ouml;chte, sollte einige wichtige Punkte beachten, um die richtige Entscheidung zu treffen. Zun&auml;chst muss man zwischen Betonziegeln und Tondachpfannen unterscheiden. Betonziegel sind preiswerter, aber auch schwerer und weniger formstabil als Tondachpfannen. Diese sind zwar teurer, aber daf&uuml;r langlebiger, frostbest&auml;ndiger und haben eine bessere Optik.</p>
<p>Des Weiteren gibt es verschiedene Dachformen, die f&uuml;r die Optik und Funktionalit&auml;t des Daches entscheidend sind. Das Walmdach hat vier Dachfl&auml;chen, die an den Seiten abgeschr&auml;gt sind. Das Satteldach ist das klassische Dach mit zwei geneigten Fl&auml;chen, die in der Mitte aufeinandertreffen. Das Flachdach hat eine geringe Neigung und das Pultdach eine einseitige Schr&auml;gfl&auml;che.</p>
<p>Um die Dachfl&auml;che zu berechnen, muss man den Neigungswinkel beachten. Dieser betr&auml;gt beim Walmdach etwa 30 bis 50 Grad, beim Satteldach 30 bis 60 Grad, beim Flachdach unter 10 Grad und beim Pultdach meist zwischen 5 und 15 Grad.</p>
<p>Bei der Planung eines neuen Daches ist es also wichtig, die richtigen Materialien und Dachformen sowie die passenden Neigungswinkel zu w&auml;hlen, um ein optisch ansprechendes und funktionales Ergebnis zu erzielen. Bei der Auswahl der Materialien f&uuml;r das Dach spielen auch weitere Faktoren eine wichtige Rolle. So sollte man beispielsweise die &ouml;kologische Vertr&auml;glichkeit der Materialien ber&uuml;cksichtigen und darauf achten, dass sie recyclebar sind. Zudem ist die Energieeffizienz des Daches entscheidend, um Heizkosten zu sparen und die Umweltbelastung zu reduzieren. Dachziegel mit integrierten Solarzellen k&ouml;nnen hier eine gute Wahl sein, um erneuerbare Energie zu nutzen und das Geb&auml;ude energieeffizienter zu machen. Auch die Wartungsfreundlichkeit des Materials ist ein wichtiger Aspekt, um Folgekosten gering zu halten und die Langlebigkeit des Daches zu gew&auml;hrleisten. Durch sorgf&auml;ltige Planung und Auswahl der richtigen Materialien kann man somit nicht nur ein &auml;sthetisch ansprechendes Dach gestalten, sondern auch langfristig von einer optimalen Funktionalit&auml;t profitieren.</p>
`

export default {
  baths,
  roof
}
