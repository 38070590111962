<script setup>
import { computed } from 'vue'
import Radio from '@/components/Inputs/Radio.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Number from '@/components/Inputs/Number.vue'
import Range from '@/components/Inputs/Range.vue'
import Panel from '@/components/Elements/Panel.vue'
import Checkbox from '@/components/Inputs/Checkbox.vue'

import {
  baseTypeOptions,
  baseFloorOptions,
  baseFloorsOptions,
  baseBuiltOptions,
  baseHouseTypeOptions,
  baseBuiltTypeOptions
} from '@/data/options'

import { base as info } from '@/data/infos/panels'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const toggleServices = (type) => {
  Object.keys(model.value.services).forEach((service) => {
    if (model.value.services[service].enabled) {
      model.value.services[service].enabled = model.value.services[service].forTypes.includes(type)
    }
  })

  // reset built year
  model.value.base.built = ''
}
</script>

<template>
  <div>
    <Panel title="Konfiguration" :info="info">
      <FormGroup label="Um welche Immobilie handelt es sich?">
        <Radio
          name="type"
          v-model="model.base.type"
          @update:model-value="toggleServices"
          :options="baseTypeOptions"
        />
      </FormGroup>

      <FormGroup label="Wie lautet Ihre Postleitzahl?">
        <div class="w-full sm:max-w-[40%]">
          <Number
            v-model="model.base.postal_code"
            type="text"
            name="postal_code"
            placeholder="22337"
          />
        </div>
      </FormGroup>

      <FormGroup
        v-if="model.base.type === 'house'"
        label="Wie lautet das Ursprungsbaujahr Ihres Hauses?"
      >
        <div class="w-full sm:max-w-[40%]">
          <Number v-model="model.base.built" placeholder="z.B. 1975" />
        </div>
      </FormGroup>

      <template v-if="model.base.type === 'apartment'">
        <FormGroup label="Wie groß ist Ihre Wohnung?">
          <Range v-model="model.base.size" name="size" :min="0" :max="400" />
        </FormGroup>

        <FormGroup label="Auf welcher Etage befindet sich Ihre Wohnung?">
          <Radio v-model="model.base.floor" name="floor" :options="baseFloorOptions" />
        </FormGroup>

        <FormGroup label="Wann wurde Ihre Wohnung erbaut?">
          <Radio v-model="model.base.built" name="built" :options="baseBuiltOptions" />
        </FormGroup>
      </template>

      <template v-else>
        <FormGroup label="Wie groß ist die Wohnfläche Ihres Hauses?">
          <Range v-model="model.base.usage" :min="0" :max="400" />
        </FormGroup>

        <FormGroup label="Wie groß ist die Nutzfläche Ihres Hauses?">
          <Range v-model="model.base.cellar" :min="0" :max="100" />
        </FormGroup>

        <FormGroup label="Welche Stockwerke hat Ihr Haus?">
          <Checkbox v-model="model.base.floors" :options="baseFloorsOptions" />
        </FormGroup>

        <FormGroup label="Um welche Hausart handelt es sich bei Ihrem Haus?">
          <Radio v-model="model.base.house_type" :options="baseHouseTypeOptions" />
        </FormGroup>

        <FormGroup label="In welcher Bauart wurde Ihr Haus errichtet?">
          <Radio v-model="model.base.built_type" :options="baseBuiltTypeOptions" />
        </FormGroup>
      </template>
    </Panel>
  </div>
</template>
