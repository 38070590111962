<script setup>
import { computed } from 'vue'

import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Radio from '@/components/Inputs/Radio.vue'
import AmountInput from '../Inputs/AmountInput.vue'
import OptionsAmountSelectionAlt from '../Inputs/OptionsAmountSelectionAlt.vue'
import ConfigPanel from './Windows/ConfigPanel.vue'

import {
  windowTypeOptions,
  windowMaterialOptions,
  windowSillsOptions,
  windowTypesOptions
} from '@/data/options/windows'
import infos from '@/data/infos/services/windows'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const onAmountChange = ({ value, type }) => {
  if (value === undefined) {
    return
  }

  const currents = model.value.change.configs.filter((c) => c.type === type)
  const amountToAddOrRemove = value - currents.length

  if (amountToAddOrRemove > 0) {
    for (let i = 0; i < amountToAddOrRemove; i++) {
      model.value.change.configs.push({
        type,
        width: 0,
        height: 0,
        built: ''
      })
    }
  } else {
    // remove the amount of same type
    for (let i = 0; i < Math.abs(amountToAddOrRemove); i++) {
      const index = model.value.change.configs.findIndex((c) => c.type === type)
      model.value.change.configs.splice(index, 1)
    }
  }

  sortConfigs()
}

const sortConfigs = () => {
  return model.value.change.configs.sort((a, b) => {
    return (
      windowTypesOptions.findIndex((o) => o.value === a.type) -
      windowTypesOptions.findIndex((o) => o.value === b.type)
    )
  })
}
</script>

<template>
  <div>
    <FormGroup
      info-title="Fenster erneuern / aufbereiten"
      :info="infos.renew"
      label="Sollen Fenster erneuert oder aufbereitet werden?"
      name="change"
    >
      <template #right>
        <Switch v-model="model.change.enabled" />
      </template>
      <template v-if="model.change.enabled">
        <Radio class="mt-4" name="type" v-model="model.change.type" :options="windowTypeOptions" />
      </template>
    </FormGroup>

    <template v-if="model.change.enabled && model.change.type === 'renew'">
      <FormGroup label="In welcher Ausführung sollen Ihre Fenster sein?" name="change">
        <Radio
          class="mt-4"
          name="material"
          v-model="model.change.material"
          :options="windowMaterialOptions"
        />
      </FormGroup>

      <FormGroup label="Wie viele Fenster sollen insgesamt erneuert werden?" name="amounts">
        <OptionsAmountSelectionAlt
          class="mb-10 mt-5"
          @change="onAmountChange"
          v-model="model.change.amounts"
          :options="windowTypesOptions"
        />

        <ConfigPanel v-model="model.change.configs" :service="model" :form="form" />
      </FormGroup>

      <!--
      <FormGroup
        info-title="Fensterleibungen"
        :info="infos.renewReveals"
        label="Sollen Fensterleibungen nachgearbeitet werden?"
        name="renewReveals"
      >
        <template #right>
          <Switch v-model="model.renewReveals.enabled" />
        </template>
      </FormGroup>
      -->

      <FormGroup
        info-title="Fensterbänke"
        :info="infos.renewSills"
        label="Sollen die Fensterbänke auch erneuert werden?"
        name="renewSills"
      >
        <template #right>
          <Switch v-model="model.renewSills.enabled" />
        </template>

        <Radio
          v-if="model.renewSills.enabled"
          class="mt-4"
          name="type"
          v-model="model.renewSills.type"
          :options="windowSillsOptions"
        />
      </FormGroup>
    </template>

    <template v-if="model.change.enabled && model.change.type === 'improve'">
      <FormGroup label="Sollen bestehende Holzfenster lackiert werden?" name="lack">
        <template #right>
          <Switch v-model="model.lack.enabled" />
        </template>

        <template v-if="model.lack.enabled">
          <Radio class="mt-4" name="type" v-model="model.lack.type" :options="windowSillsOptions" />

          <FormGroup label="Wie viele Fenster sollen lackiert werden?" class="mt-4">
            <template #right>
              <AmountInput v-model="model.lack.amount" class="w-16 px-2" />
            </template>
          </FormGroup>
        </template>
      </FormGroup>
    </template>
  </div>
</template>
