<script setup>
import { computed, nextTick } from 'vue'

import RoofCalculator from '@/components/Elements/RoofCalculator.vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Radio from '@/components/Inputs/Radio.vue'
import AmountInput from '../Inputs/AmountInput.vue'
import Headline from '../Elements/Headline.vue'

import {
  roofTypeOptions,
  roofInsulationLayer,
  roofFormsOptions,
  roofLayerOptions
} from '@/data/options/roof'
import ImageOptionsSelection from '../Inputs/ImageOptionsSelection.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const calcPult = (a, b, alphaDegrees) => {
  // Umwandlung des Winkelgrads in Radianten
  var alphaRadians = (alphaDegrees * Math.PI) / 180

  // Berechnung von a * (b / cos(alpha))
  var result = a * (b / Math.cos(alphaRadians))

  return result
}

function calcPointed(a, b, h) {
  // Erster Term unter der Quadratwurzel
  let term1 = Math.sqrt((b / 2) * (b / 2) + h)

  // Zweiter Term unter der Quadratwurzel
  let term2 = Math.sqrt((a / 2) * (a / 2) + h)

  // Berechnung von A
  let A = a * (term1 + h + b * term2 + h)

  return A
}

function calcSaddle(a, b, alphaDegrees) {
  return b * (a / Math.cos(alphaDegrees))
}

function calcHip(a, b, alpha, beta) {
  function calculateAT(b, alpha, beta) {
    alpha = (alpha * Math.PI) / 180 // Umwandlung von Grad in Radianten
    beta = (beta * Math.PI) / 180 // Umwandlung von Grad in Radianten

    let part1 = (b + (b - Math.tan(beta) / Math.tan(alpha))) / 2
    let part2 = (Math.tan(beta) * b) / (2 * Math.sin(beta))

    return part1 * part2
  }

  function calculateAD(a, beta, alpha) {
    beta = (beta * Math.PI) / 180 // Umwandlung von Grad in Radianten
    alpha = (alpha * Math.PI) / 180 // Umwandlung von Grad in Radianten

    return (a * a * Math.tan(beta)) / (4 * Math.sin(alpha))
  }

  function calculateTotalArea(a, b, alpha, beta) {
    let AT = calculateAT(b, alpha, beta)
    let AD = calculateAD(a, beta, alpha)
    return 2 * (AT + AD)
  }

  return calculateTotalArea(a, b, alpha, beta)
}

const calcRoofArea = (values, type) => {
  nextTick(() => {
    // Flachdach
    if (model.value.renew.form === 'flat') {
      model.value.renew.area = model.value.renew['length'] * model.value.renew.width
    }

    // Pultdach
    if (model.value.renew.form === 'shed') {
      model.value.renew.area = calcPult(
        model.value.renew['length'],
        model.value.renew.width,
        model.value.renew.angle
      )
    }

    // Zeltdach / Spitzdach
    if (model.value.renew.form === 'pointed') {
      model.value.renew.area = calcPointed(
        model.value.renew['length'],
        model.value.renew.width,
        model.value.renew.height
      )
    }

    // Satteldach
    if (model.value.renew.form === 'saddle') {
      model.value.renew.area = calcSaddle(
        model.value.renew.width,
        model.value.renew['length'],
        model.value.renew.angle
      )
    }

    // Walmdach
    if (model.value.renew.form === 'hip') {
      model.value.renew.area = calcHip(
        model.value.renew.width,
        model.value.renew['length'],
        model.value.renew.angle,
        model.value.renew.angle2
      )
    }
  })
}

const newRoofLayerOptions = computed(() => {
  if (model.value.renew.form === 'flat') {
    return roofLayerOptions.filter((option) => ['bitumen', 'film'].includes(option.value))
  }

  if (model.value.renew.form !== 'flat') {
    return roofLayerOptions.filter((option) => !['bitumen', 'film'].includes(option.value))
  }

  return roofLayerOptions
})
</script>

<template>
  <div>
    <FormGroup label="Soll das Dach erneuert oder gedämmt werden?" name="change">
      <template #right>
        <Switch v-model="model.change.enabled" />
      </template>
      <template v-if="model.change.enabled">
        <Radio class="mt-4" name="type" v-model="model.change.type" :options="roofTypeOptions" />
      </template>
    </FormGroup>

    <template v-if="model.change.type === 'renew'">
      <FormGroup label="Um welche Dachform handelt es sich?" name="renew">
        <ImageOptionsSelection
          class="mt-4"
          @update:modelValue="(v) => calcRoofArea(v, 'form')"
          name="form"
          v-model="model.renew.form"
          :options="roofFormsOptions"
        />
      </FormGroup>

      <Headline title="Dachfläche"></Headline>

      <FormGroup
        v-if="!['flat', 'pointed', 'hip'].includes(model.renew.form)"
        class="!mb-0"
        label="Neigungswinkel des Dachs"
        name="renew"
      >
        <template #right>
          <div class="flex items-center">
            <AmountInput
              @update:modelValue="(v) => calcRoofArea(v, 'angle')"
              name="size"
              v-model="model.renew.angle"
              class="w-16 px-2"
            />
            <span class="ml-2 block w-4 dark:text-white">°</span>
          </div>
        </template>
      </FormGroup>

      <FormGroup
        v-if="model.renew.form === 'hip'"
        class="!mb-0"
        label="Neigungswinkel der dreieckigen Dachfläche"
        name="renew"
      >
        <template #right>
          <div class="flex items-center">
            <AmountInput
              @update:modelValue="(v) => calcRoofArea(v, 'angle')"
              name="size"
              v-model="model.renew.angle"
              class="w-16 px-2"
            />
            <span class="ml-2 block w-4 dark:text-white">°</span>
          </div>
        </template>
      </FormGroup>

      <FormGroup
        v-if="model.renew.form === 'hip'"
        label="Neigungswinkel der trapezförmigen Dachfläche"
        name="renew"
      >
        <template #right>
          <div class="flex items-center">
            <AmountInput
              @update:modelValue="(v) => calcRoofArea(v, 'angle2')"
              name="size"
              v-model="model.renew.angle2"
              class="w-16 px-2"
            />
            <span class="ml-2 block w-4 dark:text-white">°</span>
          </div>
        </template>
      </FormGroup>

      <FormGroup
        v-if="!['flat', 'shed', 'saddle', 'hip'].includes(model.renew.form)"
        label="Höhe des Dachs"
        name="renew"
      >
        <template #right>
          <div class="flex items-center">
            <AmountInput
              @update:modelValue="(v) => calcRoofArea(v, 'height')"
              name="size"
              v-model="model.renew.height"
              class="w-16 px-2"
            />
            <span class="ml-2 block w-4 dark:text-white">m</span>
          </div>
        </template>
      </FormGroup>

      <RoofCalculator
        v-model="model.renew"
        class="mb-4"
        @update:modelValue="(v) => calcRoofArea(v, 'calculator')"
      />

      <FormGroup class="!mb-0" label="Länge Ihres Hauses" name="change">
        <template #right>
          <div>
            <AmountInput
              @update:modelValue="(v) => calcRoofArea(v, 'length')"
              v-model="model.renew['length']"
              class="w-16 px-2"
            />
            <span class="ml-2 dark:text-white">in m</span>
          </div>
        </template>
      </FormGroup>

      <FormGroup class="!mb-0" label="Breite Ihres Hauses" name="change">
        <template #right>
          <div>
            <AmountInput
              @update:modelValue="(v) => calcRoofArea(v, 'width')"
              v-model="model.renew.width"
              class="w-16 px-2"
            />
            <span class="ml-2 dark:text-white">in m</span>
          </div>
        </template>
      </FormGroup>

      <FormGroup
        class=""
        label="Dachfläche <small>(wird automatisch berechnet)</small>"
        name="change"
      >
        <template #right>
          <div>
            <AmountInput v-model="model.renew.area" class="w-16 px-2" />
            <span class="ml-2 dark:text-white">in m<sup>2</sup></span>
          </div>
        </template>
      </FormGroup>

      <FormGroup label="Welchen Belag hat Ihr Dach aktuell?" name="renew">
        <Radio
          class="mt-4"
          name="form"
          v-model="model.renew.currentPaving"
          :options="roofLayerOptions"
        />
      </FormGroup>

      <FormGroup label="Welchen neuen Belag wünschen Sie sich?" name="renew">
        <Radio
          class="mt-4"
          name="form"
          v-model="model.renew.newPaving"
          :options="newRoofLayerOptions"
        />
      </FormGroup>

      <template v-if="model.renew.form == 'flat'">
        <FormGroup label="Wie viele Oberlichter haben Sie?" class="!mb-0" name="lights">
          <template #right>
            <AmountInput name="size" v-model="model.renew.lights.current" class="w-16 px-2" />
          </template>
        </FormGroup>

        <FormGroup label="Wie viele neue Oberlichter wollen Sie?" name="lights">
          <template #right>
            <AmountInput name="size" v-model="model.renew.lights.new" class="w-16 px-2" />
          </template>
        </FormGroup>
      </template>
      <FormGroup label="Wie viele Dachfenster haben Sie?" class="!mb-0" name="windows">
        <template #right>
          <AmountInput name="size" v-model="model.renew.windows.current" class="w-16 px-2" />
        </template>
      </FormGroup>

      <FormGroup label="Wie viele neue Dachfenster wollen Sie?" name="windows">
        <template #right>
          <AmountInput name="size" v-model="model.renew.windows.new" class="w-16 px-2" />
        </template>
      </FormGroup>

      <FormGroup label="Wie viele Dachgauben haben Sie?" class="!mb-0" name="dormers">
        <template #right>
          <AmountInput name="size" v-model="model.renew.dormers.current" class="w-16 px-2" />
        </template>
      </FormGroup>

      <FormGroup label="Wie viele neue Dachgauben wollen Sie?" name="dormers">
        <template #right>
          <AmountInput name="size" v-model="model.renew.dormers.new" class="w-16 px-2" />
        </template>
      </FormGroup>

      <FormGroup label="Sollen Dachrinnen erneuert werden?" name="gutters">
        <template #right>
          <Switch v-model="model.renew.gutters.enabled" />
        </template>
      </FormGroup>

      <FormGroup label="Sollen Fallrohre erneuert werden?" name="downspouts">
        <template #right>
          <Switch v-model="model.renew.downspouts.enabled" />
        </template>
      </FormGroup>
    </template>
    <template v-if="model.change.type === 'insulation'">
      <RoofCalculator v-model="model.renew.size" class="mb-4" />

      <FormGroup class="!mb-0" label="Geben Sie die qm Dachfläche an:" name="change">
        <template #right>
          <div>
            <AmountInput name="size" v-model="model.insulation.size" class="w-16 px-2" />
            <span class="ml-2 dark:text-white">in m<sup>2</sup></span>
          </div>
        </template>
      </FormGroup>

      <FormGroup label="Soll die Geschossdecke gedämmt werden?" name="floorCeilingInsulation">
        <template #right>
          <Switch v-model="model.insulation.floorCeilingInsulation.enabled" />
        </template>

        <FormGroup
          v-if="model.insulation.floorCeilingInsulation.enabled"
          label="Geben Sie die qm Dachbodenfläche an:"
          class="!mb-0 mt-4"
        >
          <template #right>
            <div>
              <AmountInput
                name="size"
                v-model="model.insulation.floorCeilingInsulation.size"
                class="w-16 px-2"
              />
              <span class="ml-2 dark:text-white">in m<sup>2</sup></span>
            </div>
          </template>
        </FormGroup>

        <FormGroup
          v-if="model.insulation.floorCeilingInsulation.enabled"
          label="Wie dick soll die Dämmschicht sein?"
        >
          <Radio
            class="mt-4"
            name="layer"
            v-model="model.insulation.floorCeilingInsulation.layer"
            :options="roofInsulationLayer"
          />
        </FormGroup>
      </FormGroup>
    </template>
  </div>
</template>
