<script setup>
import { computed } from 'vue'
import Switch from '@/components/Inputs/Switch.vue'
import FormGroup from '@/components/Inputs/FormGroup.vue'
import Checkbox from '../Inputs/Checkbox.vue'
import Quality from '../Inputs/Quality.vue'

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  form: Object,
  modelValue: Object
})

const model = computed({
  get: () => props.modelValue,
  set: (v) => emit('update:modelValue', v)
})

const features = [
  { label: 'Videoüberwachung (funk)', value: 'video' },
  { label: 'Alarmanlage (funk)', value: 'alarm' }
]
</script>

<template>
  <div>
    <FormGroup label="Sollen Sicherheitstechniken installiert werden?" name="securityFeatures">
      <template #right>
        <Switch v-model="model.securityFeatures.enabled" />
      </template>
      <template v-if="model.securityFeatures.enabled">
        <Checkbox
          class="mt-4"
          name="features"
          v-model="model.securityFeatures.features"
          :options="features"
        />
        <Quality class="mt-10" v-model="model.securityFeatures.quality" />
      </template>
    </FormGroup>
  </div>
</template>
