export const scrollToSelector = (selector) => {
  const el = document.querySelector(selector)

  if (!el) {
    console.log('Element not found', selector)
    return
  }

  setTimeout(() => {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }, 300)
}

export const scrollToElement = (selector) => {
  const ez = document.querySelector('mah-einzelrechner')

  if (ez) {
    const el = ez.shadowRoot.querySelector(selector)

    // Function to get the position of an element relative to the document
    const getElementPosition = (el) => {
      const rect = el.getBoundingClientRect()
      return {
        top: rect.top + window.scrollY,
        left: rect.left + window.scrollX
      }
    }

    // Get the position of the target element
    const position = getElementPosition(el)

    // Scroll to the position of the target element
    window.scrollTo({ top: position.top, left: position.left, behavior: 'smooth' })

    return
  }

  scrollToSelector(selector)
}

export default scrollToSelector
